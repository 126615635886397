import * as React from 'react';
import { Switch } from 'antd';
import { Field, FieldRenderProps } from 'react-final-form';
import { SwitchProps } from 'antd/lib/switch';
import { FormItemProps } from 'antd/lib/form';
import FormItem from '@common/components/Form/FormItem';
import styles from './FormSwitch.module.less';

interface IProps extends SwitchProps {
  name: string;
  formItemProps: FormItemProps;
}

const FormSwitch = ({ name, formItemProps, onChange: $onChange, ...props }: IProps): JSX.Element => {
  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => (
        <FormItem name={name} {...formItemProps} meta={meta} className={styles.FormItem}>
          <Switch
            {...input}
            checked={input.value || false}
            onChange={(checked, event) => {
              input.onChange(checked);
              $onChange && $onChange(checked, event);
            }}
            {...props}
          />
        </FormItem>
      )}
    </Field>
  );
};

export { FormSwitch };
