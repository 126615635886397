import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';
import { WarehouseListModule } from '@routes/Warehouse/redux/modules/WarehouseList';
import { warehouseListAction } from '@routes/Warehouse/redux/modules/WarehouseList/WarehouseListActions';

const WarehouseAssortment = LoadableComponent(() => import('@routes/Warehouse/WarehouseAssortment'));
const WarehousePz = LoadableComponent(() => import('@routes/Warehouse/WarehousePz'));
const WarehouseWz = LoadableComponent(() => import('@routes/Warehouse/WarehouseWz'));
const WarehouseRw = LoadableComponent(() => import('@routes/Warehouse/WarehouseRw'));
const WarehouseMm = LoadableComponent(() => import('@routes/Warehouse/WarehouseMm'));
const WarehousePw = LoadableComponent(() => import('@routes/Warehouse/WarehousePw'));
const WarehouseStocktaking = LoadableComponent(() => import('@routes/Warehouse/WarehouseStocktaking'));

interface IProps {
  listAction: Function;
}

class Warehouse extends React.Component<IProps> {
  componentDidMount(): void {
    this.loadData();
  }

  loadData = () => {
    const { listAction } = this.props;

    listAction({
      params: {
        per_page: -1
      }
    });
  };

  render() {
    return (
      <Switch>
        <Route path={Paths.WarehouseAssortment} component={WarehouseAssortment} />
        <Route path={Paths.WarehousePz} component={WarehousePz} />
        <Route path={Paths.WarehouseWz} component={WarehouseWz} />
        <Route path={Paths.WarehouseRw} component={WarehouseRw} />
        <Route path={Paths.WarehouseMm} component={WarehouseMm} />
        <Route path={Paths.WarehousePw} component={WarehousePw} />
        <Route path={Paths.WarehouseStocktaking} component={WarehouseStocktaking} />
        <Redirect from={Paths.Warehouse} to={Paths.WarehousePz} />
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      listAction: warehouseListAction
    },
    dispatch
  )
});

const WarehouseCompose = compose(withRouter, connect(null, mapDispatchToProps))(Warehouse) as React.ComponentType;

const WarehouseModuleLoader = () => (
  <DynamicModuleLoader modules={[WarehouseListModule]}>
    <WarehouseCompose />
  </DynamicModuleLoader>
);

export default WarehouseModuleLoader;
