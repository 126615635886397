import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import IntlMessages from '@common/translations';
import DataTable, {
  DataTableColumnSortOrders,
  IDataTableColumn,
  IDataTableCreateButtonComponent,
  IDataTableMenuItem
} from '@common/components/DataTable';
import CardBox from '@common/containers/CardBox';
import {
  vehicleMakeDeleteAction,
  vehicleMakeListAction
} from '@routes/Settings/Vehicle/redux/modules/VehicleMake/VehicleMakeActions';
import { ConfirmModal, getDate } from '@common/helpers';
import VehicleMakeCreate from '@routes/Settings/Vehicle/VehicleMake/VehicleMakeCreate';
import VehicleMakeUpdate from '@routes/Settings/Vehicle/VehicleMake/VehicleMakeUpdate';
import { IHeadersState } from '@common/interfaces';
import { IVehicleMakeModel } from '@common/models/VehicleMakeModel';
import { IVehicleMakeModuleState } from '@routes/Settings/Vehicle/redux/modules/VehicleMake';
import PageHeading from '@common/containers/PageHeading';

export const VehicleMakeListPageTitle = 'routes.settings.vehicle.make.list.pageTitle';

interface IProps {
  listAction: Function;
  deleteAction: Function;
  data: IVehicleMakeModel[];
  headers: IHeadersState;
  isLoaded: boolean;
  isLoading: boolean;
  intl: IntlShape;
}

interface IState {
  showUpdateModal: boolean;
  updateModalId: number | null;
  updateModalOnCloseAction: Function;
  updateModalOnSuccessAction: Function;
}

class VehicleMakeList extends React.Component<IProps, IState> {
  state = {
    showUpdateModal: false,
    updateModalId: null,
    updateModalOnCloseAction: () => {},
    updateModalOnSuccessAction: () => {}
  };

  tableColumns: IDataTableColumn<IVehicleMakeModel>[] = [
    { dataIndex: 'name', title: <IntlMessages id="routes.settings.vehicle.make.form.name" /> },
    {
      dataIndex: 'date_created',
      width: 180,
      align: 'right',
      title: <IntlMessages id="date_created" />,
      render: value => getDate({ date: value })
    }
  ];

  tableItemActions: IDataTableMenuItem<IVehicleMakeModel>[] = [
    {
      key: 'update',
      onPress: (item, fetchData) => {
        this.setState({
          showUpdateModal: true,
          updateModalId: item.id,
          updateModalOnCloseAction: () => {
            this.setState({
              showUpdateModal: false,
              updateModalId: null
            });
          },
          updateModalOnSuccessAction: async () => {
            fetchData();
          }
        });
      }
    },
    {
      key: 'delete',
      onPress: (item, fetchData) => {
        const { deleteAction } = this.props;

        ConfirmModal({
          title: item.name,
          onOk: async () => {
            await deleteAction({ id: item.id });
            fetchData();
          }
        });
      }
    }
  ];

  tableCreateButton = {
    component: ({ fetchData, onCloseAction }: IDataTableCreateButtonComponent) => (
      <VehicleMakeCreate
        onCloseAction={onCloseAction}
        onSuccessAction={() => {
          fetchData({ reset: true });
        }}
      />
    )
  };

  render() {
    const { listAction, data, headers, isLoaded, isLoading, intl } = this.props;
    const { updateModalId, updateModalOnCloseAction, updateModalOnSuccessAction, showUpdateModal } = this.state;

    return (
      <div>
        <PageHeading
          title={intl.formatMessage({ id: 'routes.settings.vehicle.pageTitle' })}
          subTitle={intl.formatMessage({ id: VehicleMakeListPageTitle })}
        />
        <CardBox>
          <DataTable<IVehicleMakeModel>
            data={data}
            headers={headers}
            isLoading={isLoading}
            isLoaded={isLoaded}
            columns={this.tableColumns}
            itemActions={this.tableItemActions}
            createButton={this.tableCreateButton}
            fetch={{ resource: listAction, order: DataTableColumnSortOrders.desc, orderBy: 'date_created' }}
          />
        </CardBox>

        {showUpdateModal && (
          <VehicleMakeUpdate
            id={updateModalId}
            onCloseAction={updateModalOnCloseAction}
            onSuccessAction={updateModalOnSuccessAction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ vehicleMake }: IVehicleMakeModuleState) => ({
  isLoaded: vehicleMake.list.isLoaded,
  isLoading: vehicleMake.list.isLoading,
  data: vehicleMake.list.data,
  headers: vehicleMake.list.headers
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators({ listAction: vehicleMakeListAction, deleteAction: vehicleMakeDeleteAction }, dispatch)
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(VehicleMakeList) as React.ComponentType;
