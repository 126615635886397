import * as React from 'react';
import IntlMessages from '@common/translations';
import FormInput from '@common/components/Form/FormInput';
import FormTextArea from '@common/components/Form/FormTextArea';
import { FormSwitch } from '@common/components/Form';
import { IFormProps } from '@common/components/Form/Form/Form';
import { IInventoryWarehouseFormValues } from '@common/models/InventoryWarehouseModel';
import { WarehouseListFormSchema } from '@routes/Settings/Warehouse/WarehouseList/components/WarehouseListForm/WarehouseListFormSchema';
import FormDrawer from '@common/helpers/FormDrawer';

interface IProps<T> {
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

const WarehouseListForm = <T extends IInventoryWarehouseFormValues>({
  title,
  closeModal,
  formProps
}: IProps<T>): JSX.Element => (
  <FormDrawer<T>
    title={title}
    onClose={closeModal}
    width={500}
    formProps={{
      ...formProps,
      schema: WarehouseListFormSchema
    }}
  >
    <div>
      <FormInput
        name="name"
        formItemProps={{
          label: <IntlMessages id="routes.settings.warehouse.list.form.name" />,
          required: true
        }}
        autoFocus
      />

      <FormInput
        name="code"
        formItemProps={{
          label: <IntlMessages id="routes.settings.warehouse.list.form.code" />
        }}
      />

      <FormTextArea
        name="address"
        formItemProps={{
          label: <IntlMessages id="routes.settings.warehouse.list.form.address" />
        }}
        autoSize={{
          minRows: 3
        }}
      />

      <FormTextArea
        name="description"
        formItemProps={{
          label: <IntlMessages id="routes.settings.warehouse.list.form.description" />
        }}
        autoSize={{
          minRows: 6
        }}
      />

      <FormSwitch
        name="is_default"
        formItemProps={{
          label: <IntlMessages id="routes.settings.warehouse.list.form.is_default" />
        }}
      />
    </div>
  </FormDrawer>
);

export default WarehouseListForm;
