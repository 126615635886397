import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import ContractorModel, { IContractorModelDTO } from '@common/models/ContractorModel';
import {
  CONTRACTOR_CREATE_FAILURE,
  CONTRACTOR_CREATE_REQUEST,
  CONTRACTOR_CREATE_SUCCESS,
  CONTRACTOR_DELETE_FAILURE,
  CONTRACTOR_DELETE_REQUEST,
  CONTRACTOR_DELETE_SUCCESS,
  CONTRACTOR_LIST_FAILURE,
  CONTRACTOR_LIST_REQUEST,
  CONTRACTOR_LIST_SUCCESS,
  CONTRACTOR_UPDATE_FAILURE,
  CONTRACTOR_UPDATE_REQUEST,
  CONTRACTOR_UPDATE_SUCCESS,
  CONTRACTOR_VIEW_FAILURE,
  CONTRACTOR_VIEW_REQUEST,
  CONTRACTOR_VIEW_SUCCESS
} from '@routes/Management/Contractor/redux/modules/Contractor/ContractorTypes';
import { saveAsFile } from '@common/helpers/Others';
import { IReduxActionList } from '@common/interfaces/ReduxActionInterface';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const contractorListAction = (requestData: AxiosRequestConfig, params: IReduxActionList) => async (
  dispatch: Dispatch
) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    if (requestData.headers && requestData.headers.Accept) {
      const response = await ContractorModel.listFetch({
        ...requestData,
        responseType: 'blob',
        cancelToken: source.token
      });

      return saveAsFile({ response, fileType: requestData.headers.Accept, fileName: params.exportFileName });
    }

    dispatch({
      type: CONTRACTOR_LIST_REQUEST
    });

    const response = await ContractorModel.listFetch({
      ...requestData,
      cancelToken: source.token
    });

    dispatch({
      type: CONTRACTOR_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: CONTRACTOR_LIST_FAILURE
      });
    }
  }
};

export const contractorViewAction = ({ id, reset = false }: { id: number; reset: boolean }) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({
      type: CONTRACTOR_VIEW_REQUEST
    });

    if (reset) {
      return dispatch({
        type: CONTRACTOR_VIEW_SUCCESS,
        payload: null
      });
    }

    const response = await ContractorModel.viewFetch({ id });

    dispatch({
      type: CONTRACTOR_VIEW_SUCCESS,
      payload: response.data
    });
  } catch (err) {
    dispatch({
      type: CONTRACTOR_VIEW_FAILURE
    });

    throw err.response.data;
  }

  return null;
};

export const contractorCreateAction = (data: IContractorModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CONTRACTOR_CREATE_REQUEST
    });

    const response = await ContractorModel.createFetch(data);

    dispatch({
      type: CONTRACTOR_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: CONTRACTOR_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const contractorUpdateLoadDataAction = ({ id }: { id: number }) => () => ContractorModel.viewFetch({ id });

export const contractorUpdateAction = (data: IContractorModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CONTRACTOR_UPDATE_REQUEST
    });

    const response = await ContractorModel.updateFetch(data);

    dispatch({
      type: CONTRACTOR_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: CONTRACTOR_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const contractorDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CONTRACTOR_DELETE_REQUEST
    });

    const response = await ContractorModel.deleteFetch({ id });

    dispatch({
      type: CONTRACTOR_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: CONTRACTOR_DELETE_FAILURE
    });
  }
};

export const contractorGusSearchAction = ({ nip }: { nip: string }) => () => {
  if (source) {
    source.cancel();
  }

  source = CancelToken.source();

  return ContractorModel.gusSearchFetch({
    nip,
    cancelToken: source.token
  });
};
