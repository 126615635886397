import moment from 'moment';
import { getDateFormat, getDateTimeFormat } from './Config';

const getDate = ({ date, mode = 'dateTime' }: { date: moment.MomentInput; mode?: string }) => {
  let format = '';
  let tmp = date;

  if (!moment.isMoment(tmp)) {
    tmp = moment(tmp);
  }

  switch (mode) {
    case 'date':
      format = getDateFormat() || 'L';

      break;

    case 'dateTime':
      format = getDateTimeFormat();

      break;

    case 'fullDateTime':
      format = 'LLL';

      break;

    case 'time':
      format = 'LT';

      break;

    case 'calendar':
      return tmp.calendar();

    default:
      format = mode;
  }

  return tmp.format(format);
};

const getDateIso = ({ date = new Date(), sourceFormat = 'MM/DD/YYYY' } = {}) => {
  let tmp: moment.MomentInput = date;
  if (!moment.isMoment(tmp)) {
    tmp = moment(date, sourceFormat);
  }

  return tmp.toISOString();
};

function getRangeOfDates({
  start,
  end,
  key = 'day',
  format = 'YYYY-MM-DD',
  arr = [start.format(format)]
}: {
  start: moment.Moment;
  end: moment.Moment;
  key: moment.unitOfTime.DurationConstructor;
  format?: string;
  arr: string[];
}): any {
  if (start.isAfter(end)) {
    return arr;
  }

  const next = start.add(1, key).startOf(key);

  if (next.isAfter(end, key)) {
    return arr;
  }

  return getRangeOfDates({
    start: next,
    end,
    key,
    arr: [...arr, next.format(format)]
  });
}

export { getDate, getDateIso, getRangeOfDates };
