import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import WorkerUserModel, { IWorkerUserModelDTO } from '@common/models/WorkerUserModel';
import { PROFILE_SUCCESS } from '@common/redux/modules/Profile/ProfileTypes';
import {
  WORKER_LIST_REQUEST,
  WORKER_LIST_SUCCESS,
  WORKER_LIST_FAILURE,
  WORKER_CREATE_REQUEST,
  WORKER_CREATE_SUCCESS,
  WORKER_CREATE_FAILURE,
  WORKER_DELETE_REQUEST,
  WORKER_DELETE_SUCCESS,
  WORKER_DELETE_FAILURE,
  WORKER_UPDATE_REQUEST,
  WORKER_UPDATE_SUCCESS,
  WORKER_UPDATE_FAILURE
} from '@routes/Administration/Worker/redux/modules/Worker/WorkerTypes';
import { IReduxActionList } from '@common/interfaces/ReduxActionInterface';
import { saveAsFile } from '@common/helpers/Others';
import { IProfileModuleState } from '@common/redux/modules/Profile';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const workerListAction = (requestData: AxiosRequestConfig, params: IReduxActionList) => async (
  dispatch: Dispatch
) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    if (requestData.headers && requestData.headers.Accept) {
      const response = await WorkerUserModel.listFetch({
        ...requestData,
        responseType: 'blob',
        cancelToken: source.token
      });

      return saveAsFile({ response, fileType: requestData.headers.Accept, fileName: params.exportFileName });
    }

    dispatch({
      type: WORKER_LIST_REQUEST
    });

    const response = await WorkerUserModel.listFetch({
      ...requestData,
      cancelToken: source.token
    });

    dispatch({
      type: WORKER_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: WORKER_LIST_FAILURE
      });
    }
  }
};

export const workerCreateAction = (data: IWorkerUserModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: WORKER_CREATE_REQUEST
    });

    const response = await WorkerUserModel.createFetch(data);

    dispatch({
      type: WORKER_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: WORKER_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const workerUpdateLoadDataAction = ({ id }: { id: number }) => () => WorkerUserModel.viewFetch({ id });

export const workerUpdateAction = (data: IWorkerUserModelDTO) => async (
  dispatch: Dispatch,
  getState: () => IProfileModuleState
) => {
  try {
    dispatch({
      type: WORKER_UPDATE_REQUEST
    });

    const response = await WorkerUserModel.updateFetch(data);

    dispatch({
      type: WORKER_UPDATE_SUCCESS,
      payload: response.data
    });

    const { profile } = getState();

    if (profile.data.id === response.data.id) {
      dispatch({
        type: PROFILE_SUCCESS,
        payload: response.data
      });
    }

    return response.data;
  } catch (err) {
    dispatch({
      type: WORKER_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const workerDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: WORKER_DELETE_REQUEST
    });

    const response = await WorkerUserModel.deleteFetch({ id });

    dispatch({
      type: WORKER_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: WORKER_DELETE_FAILURE
    });
  }
};
