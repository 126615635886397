export const WAREHOUSE_LIST_LIST_REQUEST = 'WAREHOUSE_LIST_LIST_REQUEST';
export const WAREHOUSE_LIST_LIST_SUCCESS = 'WAREHOUSE_LIST_LIST_SUCCESS';
export const WAREHOUSE_LIST_LIST_FAILURE = 'WAREHOUSE_LIST_LIST_FAILURE';
export const WAREHOUSE_LIST_CREATE_REQUEST = 'WAREHOUSE_LIST_CREATE_REQUEST';
export const WAREHOUSE_LIST_CREATE_SUCCESS = 'WAREHOUSE_LIST_CREATE_SUCCESS';
export const WAREHOUSE_LIST_CREATE_FAILURE = 'WAREHOUSE_LIST_CREATE_FAILURE';
export const WAREHOUSE_LIST_UPDATE_REQUEST = 'WAREHOUSE_LIST_UPDATE_REQUEST';
export const WAREHOUSE_LIST_UPDATE_SUCCESS = 'WAREHOUSE_LIST_UPDATE_SUCCESS';
export const WAREHOUSE_LIST_UPDATE_FAILURE = 'WAREHOUSE_LIST_UPDATE_FAILURE';
export const WAREHOUSE_LIST_DELETE_REQUEST = 'WAREHOUSE_LIST_DELETE_REQUEST';
export const WAREHOUSE_LIST_DELETE_SUCCESS = 'WAREHOUSE_LIST_DELETE_SUCCESS';
export const WAREHOUSE_LIST_DELETE_FAILURE = 'WAREHOUSE_LIST_DELETE_FAILURE';
