import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';
import SettingsNavigation from '@routes/Settings/components/SettingsNavigation';
import styles from './Settings.module.less';

const SettingsSystem = LoadableComponent(() => import('@routes/Settings/System'));
const SettingsVehicle = LoadableComponent(() => import('@routes/Settings/Vehicle'));
const SettingsWarehouse = LoadableComponent(() => import('@routes/Settings/Warehouse'));

class Settings extends React.Component {
  render() {
    return (
      <Layout className={styles.Container}>
        <SettingsNavigation />
        <Layout.Content className={styles.Content}>
          <Switch>
            <Route path={Paths.SettingsSystem} component={SettingsSystem} />
            <Route path={Paths.SettingsWarehouse} component={SettingsWarehouse} />
            <Route path={Paths.SettingsVehicle} component={SettingsVehicle} />
            <Redirect from={Paths.Settings} to={Paths.SettingsWarehouse} />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}

export default compose(
  withRouter,
  connect()
)(Settings) as React.ComponentType;
