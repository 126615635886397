import * as React from 'react';
import { compose } from 'redux';
import { injectIntl, IntlShape } from 'react-intl';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import FormModal from '@common/helpers/FormModal';
import { IFormSelectItemProps } from '@common/components/Form/FormSelect/FormSelect';
import FormSelect from '@common/components/Form/FormSelect';

interface IDataTableExportFormValues {
  format: string;
}

interface IProps extends IBaseFormComponentProps<IDataTableExportFormValues> {
  intl: IntlShape;
}

interface IState {
  initialValues: IDataTableExportFormValues;
}

class DataTableExport extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    initialValues: {
      format: 'text/csv'
    }
  };

  saveAsItems: IFormSelectItemProps[] = [
    { label: 'CSV', value: 'text/csv' },
    { label: 'XML', value: 'application/xml' },
    { label: 'YAML', value: 'application/yaml' },
    { label: 'XLSX', value: 'application/xlsx' }
  ];

  onSubmit = (values: IDataTableExportFormValues): void => {
    const { onSaveAction } = this.props;

    onSaveAction(values, {});
  };

  render() {
    const { closeModal, intl } = this.props;
    const { initialValues } = this.state;

    return (
      <FormModal
        title={intl.formatMessage({ id: 'common.components.dataTable.export' })}
        onClose={closeModal}
        width={400}
        formProps={{
          onSubmit: this.onSubmit,
          initialValues
        }}
      >
        <FormSelect
          name="format"
          items={this.saveAsItems}
          allowPrompt={false}
          formItemProps={{
            label: intl.formatMessage({ id: 'common.components.dataTable.export.format' })
          }}
        />
      </FormModal>
    );
  }
}

export default compose<any>(injectIntl, baseFormComponent)(DataTableExport);
