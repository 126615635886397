import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import {
  contractorGusSearchAction,
  contractorUpdateAction,
  contractorUpdateLoadDataAction
} from '@routes/Management/Contractor/redux/modules/Contractor/ContractorActions';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import ContractorForm from '@routes/Management/Contractor/components/ContractorForm';
import { IContactType, ICountry, IVatCountry } from '@common/interfaces';
import { IInitModuleState } from '@common/redux/modules/Init';
import ContractorModel, { IContractorFormValues } from '@common/models/ContractorModel';

const ContractorUpdatePageTitle = 'routes.crm.contractor.update.pageTitle';

interface IProps extends IBaseFormComponentProps<IContractorFormValues> {
  contractorId: number;
  gusSearchAction: Function;
  loadDataAction: Function;
  saveAction: Function;
  countries: ICountry[];
  vatCountries: IVatCountry[];
  contactKinds: IContactType[];
  intl: IntlShape;
}

interface IState {
  isLoaded: boolean;
  name: string;
  initialValues?: IContractorFormValues;
}

class ContractorUpdate extends React.Component<IProps & IInjectedProps, IState> {
  state = {
    isLoaded: false,
    name: '',
    initialValues: undefined
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (): Promise<void> => {
    const { contractorId, loadDataAction } = this.props;

    try {
      const response = await loadDataAction({ id: contractorId });
      const model = ContractorModel.build(response.data);

      this.setState({
        isLoaded: true,
        name: model.name,
        initialValues: {
          ...model
        }
      });
    } catch (err) {
      throw err.response.data;
    }
  };

  onSubmit = async (values: IContractorFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return onSaveAction(ContractorModel.serialize(values), { saveAction });
  };

  render() {
    const { gusSearchAction, countries, contactKinds, vatCountries, closeModal, intl } = this.props;

    const { isLoaded, name, initialValues } = this.state;

    if (!isLoaded) {
      return null;
    }

    return (
      <ContractorForm
        title={intl.formatMessage({ id: ContractorUpdatePageTitle }, { name })}
        gusSearchAction={gusSearchAction}
        vatCountries={vatCountries}
        countries={countries}
        contactKinds={contactKinds}
        formProps={{
          onSubmit: this.onSubmit,
          initialValues
        }}
        closeModal={closeModal}
      />
    );
  }
}

const mapStateToProps = ({ init }: IInitModuleState) => ({
  countries: init.data.countries,
  vatCountries: init.data.vatCountries,
  contactKinds: init.data.contactKinds
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: contractorUpdateAction,
      loadDataAction: contractorUpdateLoadDataAction,
      gusSearchAction: contractorGusSearchAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  baseFormComponent
)(ContractorUpdate);
