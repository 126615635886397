import React, { ReactElement } from 'react';
import { Empty } from 'antd';
import { EmptyProps } from 'antd/es/empty';
import styles from './EmptyData.module.less';

const EmptyData = (props: EmptyProps): ReactElement => {
  return <Empty className={styles.Empty} {...props} />;
};

export default EmptyData;
