import produce from 'immer';
import { IReducerAction } from '@common/interfaces';
import { DEFAULT_WAREHOUSE_SUCCESS } from '@common/redux/modules/DefaultWarehouse/DefaultWarehouseTypes';
import InventoryWarehouseModel, {
  IInventoryWarehouseModelDTO,
  IInventoryWarehouseModel
} from '@common/models/InventoryWarehouseModel';

export interface IDefaultWarehouseState {
  data: IInventoryWarehouseModel;
}

const initialState = {
  data: {}
};

export default function(
  state = initialState,
  action: IReducerAction<IInventoryWarehouseModelDTO>
): IDefaultWarehouseState | {} {
  return produce(state, draft => {
    switch (action.type) {
      case DEFAULT_WAREHOUSE_SUCCESS: {
        draft.data = InventoryWarehouseModel.build(action.payload);

        break;
      }
    }
  });
}
