import * as _ from 'lodash';
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Col, Row, Tag } from 'antd';
import { injectIntl, IntlShape } from 'react-intl';
import ContractorModel, { IContractorModel } from '@common/models/ContractorModel';
import Modal from '@common/helpers/Modal';
import { IInitModuleState } from '@common/redux/modules/Init';
import { IContactType } from '@common/interfaces';
import Button from '@common/components/Button';
import styles from './ContractorPreview.module.less';

interface IProps {
  contractor: IContractorModel;
  OpenModalButtonComponent?: Function;
  intl: IntlShape;
  countries: IContactType[];
}

interface IState {
  isLoading: boolean;
  open: boolean;
  item: IContractorModel | null;
  country: IContactType | null;
}

class ContractorPreview extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    isLoading: false,
    open: false,
    item: null,
    country: null
  };

  openModal = async () => {
    const { contractor, countries } = this.props;

    await this.setState({
      isLoading: true
    });

    try {
      const response = await ContractorModel.viewFetch({ id: contractor.id });

      this.setState({
        open: true,
        isLoading: false,
        item: ContractorModel.build(response.data),
        country: _.find(countries, country => country.value === response.data.country) || null
      });
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  };

  closeModal = () => {
    this.setState({
      open: false,
      item: null
    });
  };

  renderContacts = () => {
    const { intl } = this.props;
    const { item } = this.state;

    if (item) {
      return _.map(item.contacts, (contact, index) =>
        this.renderRowInfo({
          label: intl.formatMessage({ id: `contact_${contact.kind}` }),
          text: contact.value,
          key: index
        })
      );
    }

    return null;
  };

  renderRowInfo = ({
    label,
    text,
    key,
    visible = true
  }: {
    label: string;
    text: string;
    key?: number;
    visible?: boolean;
  }) => {
    return visible && text ? (
      <Row gutter={24} className={styles.Row} key={key}>
        <Col span={12} className={styles.RowLabel}>
          {label}
        </Col>
        <Col span={12} className={styles.RowText}>
          {text}
        </Col>
      </Row>
    ) : null;
  };

  render() {
    const { OpenModalButtonComponent, intl, contractor } = this.props;
    const { open, item, isLoading, country } = this.state;

    return (
      <div>
        {OpenModalButtonComponent ? (
          OpenModalButtonComponent({ isLoading: isLoading, onClick: this.openModal })
        ) : (
          <Button
            onClick={this.openModal}
            loading={isLoading}
            size="small"
            className={cn(styles.OpenButton, { [styles.OpenButtonCompany]: contractor.is_company })}
          >
            {contractor.name}
          </Button>
        )}

        {open && item ? (
          <Modal
            maskClosable
            destroyOnClose={true}
            onCancel={this.closeModal}
            footer={null}
            title={intl.formatMessage({ id: 'routes.crm.contractor.preview.pageTitle' })}
          >
            <Row gutter={24} className={styles.Row}>
              <Col span={12} className={styles.RowLabel}>
                Typ
              </Col>
              <Col span={12} className={styles.RowText}>
                {item.is_company ? (
                  <Tag color="blue">{intl.formatMessage({ id: 'company' })}</Tag>
                ) : (
                  <Tag color="magenta">{intl.formatMessage({ id: 'private person' })}</Tag>
                )}
              </Col>
            </Row>

            {this.renderRowInfo({
              label: intl.formatMessage({ id: 'routes.crm.contractor.preview.companyName' }),
              text: item.name,
              visible: item.is_company
            })}
            {this.renderRowInfo({
              label: intl.formatMessage({ id: 'routes.crm.contractor.preview.prefixNip' }),
              text: item.getNip(true),
              visible: item.is_company
            })}
            {this.renderRowInfo({
              label: intl.formatMessage({ id: 'routes.crm.contractor.preview.name' }),
              text: `${item.first_name} ${item.last_name}`,
              visible: typeof item.first_name === 'string' || typeof item.last_name === 'string'
            })}
            {this.renderRowInfo({
              label: intl.formatMessage({ id: 'routes.crm.contractor.preview.address' }),
              text: `${item.postal_code ? `${item.postal_code}, ` : ''}${item.city}`,
              visible: typeof item.postal_code === 'string' || typeof item.city === 'string'
            })}
            {this.renderRowInfo({
              label: intl.formatMessage({ id: 'routes.crm.contractor.preview.address2' }),
              text: `${item.street}${item.building_no ? ' ,' + item.building_no : ''}${
                item.local_no ? '/' + item.local_no : ''
              }`,
              visible: typeof item.street === 'string' || typeof item.building_no === 'string'
            })}
            {this.renderRowInfo({
              label: intl.formatMessage({ id: 'routes.crm.contractor.preview.country' }),
              text: country ? country.label : ''
            })}
            {this.renderContacts()}
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ init }: IInitModuleState) => ({
  countries: init.data.countries
});

export default compose<any>(injectIntl, connect(mapStateToProps))(ContractorPreview);
