import * as Yup from 'yup';
import { ContactSchema } from '@common/schemas/ContactSchema';

export const ContractorFormSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    company_name: Yup.string()
      .when('is_company', {
        is: true,
        then: Yup.string().required()
      })
      .nullable(),
    nip: Yup.string()
      .when('is_company', {
        is: true,
        then: Yup.string().required()
      })
      .nullable(),
    first_name: Yup.string()
      .when('is_company', {
        is: false,
        then: Yup.string().required()
      })
      .nullable(),
    last_name: Yup.string()
      .when('is_company', {
        is: false,
        then: Yup.string().required()
      })
      .nullable(),
    street: Yup.string()
      .when('is_company', {
        is: true,
        then: Yup.string()
      })
      .nullable(),
    postal_code: Yup.string()
      .when('is_company', {
        is: true,
        then: Yup.string().required()
      })
      .nullable(),
    city: Yup.string()
      .when('is_company', {
        is: true,
        then: Yup.string().required()
      })
      .nullable(),
    country: Yup.string()
      .when('is_company', {
        is: true,
        then: Yup.string().required()
      })
      .nullable(),
    is_recipient: Yup.bool(),
    is_supplier: Yup.bool(),
    contacts: Yup.array().of(ContactSchema())
  });
