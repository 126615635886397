import * as React from 'react';
import * as _ from 'lodash';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { IntlShape } from 'react-intl';
import { Paths } from '@common/constants';
import { SystemFiscalPrinterListPageTitle } from '@routes/Settings/System/SystemFiscalPrinter/SystemFiscalPrinterList';
import { WarehouseListListPageTitle } from '@routes/Settings/Warehouse/WarehouseList/WarehouseListList';
import { WarehouseCategoryListPageTitle } from '@routes/Settings/Warehouse/WarehouseCategory/WarehouseCategoryList';
import { WarehouseProducerListPageTitle } from '@routes/Settings/Warehouse/WarehouseProducer/WarehouseProducerList';
import { VehicleSerieListPageTitle } from '@routes/Settings/Vehicle/VehicleSerie/VehicleSerieList';
import { VehicleEngineListPageTitle } from '@routes/Settings/Vehicle/VehicleEngine/VehicleEngineList';
import { VehicleGenerationListPageTitle } from '@routes/Settings/Vehicle/VehicleGeneration/VehicleGenerationList';
import { VehicleModelListPageTitle } from '@routes/Settings/Vehicle/VehicleModel/VehicleModelList';
import { VehicleMakeListPageTitle } from '@routes/Settings/Vehicle/VehicleMake/VehicleMakeList';
import styles from './SettingsNavigationMenu.module.less';

interface INavigationMenuItem {
  label: string;
  icon?: React.FC;
  items?: INavigationMenuItem[];
  divider?: boolean;
  path?: string;
}

const _items: INavigationMenuItem[] = [
  {
    label: 'sidebar.settings.system',
    items: [
      {
        label: SystemFiscalPrinterListPageTitle,
        path: Paths.SettingsSystemFiscalPrinter
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.settings.warehouse',
    items: [
      {
        label: WarehouseListListPageTitle,
        path: Paths.SettingsWarehouseList
      },
      {
        label: WarehouseCategoryListPageTitle,
        path: Paths.SettingsWarehouseCategory
      },
      {
        label: WarehouseProducerListPageTitle,
        path: Paths.SettingsWarehouseProducer
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.settings.vehicle',
    items: [
      {
        label: VehicleMakeListPageTitle,
        path: Paths.SettingsVehicleMake
      },
      {
        label: VehicleModelListPageTitle,
        path: Paths.SettingsVehicleModel
      },
      {
        label: VehicleGenerationListPageTitle,
        path: Paths.SettingsVehicleGeneration
      },
      {
        label: VehicleSerieListPageTitle,
        path: Paths.SettingsVehicleSerie
      },
      {
        label: VehicleEngineListPageTitle,
        path: Paths.SettingsVehicleEngine
      }
    ]
  },
  {
    label: '',
    divider: true
  }
];

interface IProps {
  collapsed: boolean;
  pathname: string;
  intl: IntlShape;
}

class SettingsNavigationMenu extends React.PureComponent<IProps> {
  _searchSelectedKeys = () => {
    const { pathname } = this.props;
    const result: string[] = [];

    for (let i = 0, len = _items.length; i < len; i += 1) {
      const item = _items[i];

      if (Array.isArray(item.items)) {
        for (let j = 0, jLen = item.items.length; j < jLen; j += 1) {
          const subItem = item.items[j];

          if (subItem.path && _.includes(pathname, subItem.path)) {
            result.push(subItem.path);
          }
        }
      }
    }

    return result;
  };

  _renderMenuItems = (items: INavigationMenuItem[] = _items): React.ReactNode => {
    const { collapsed, intl } = this.props;

    return _.map(items, (item, index) => {
      return Array.isArray(item.items) ? (
        <Menu.ItemGroup
          className={styles.MenuItemGroup}
          key={item.label}
          title={!collapsed ? intl.formatMessage({ id: item.label }) : ''}
        >
          {this._renderMenuItems(item.items)}
        </Menu.ItemGroup>
      ) : item.divider ? (
        <Menu.Divider key={index} className={styles.Divider} />
      ) : (
        this._renderMenuItem(item)
      );
    });
  };

  _renderMenuItem = (item: INavigationMenuItem): React.ReactNode => {
    const { intl } = this.props;

    return (
      <Menu.Item className={styles.MenuItem} key={item.path}>
        <Link to={item.path || ''}>
          <span>
            <span className={styles.MenuItemLabel}>{intl.formatMessage({ id: item.label })}</span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    return (
      <Menu
        className={styles.Menu}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['/']}
        selectedKeys={this._searchSelectedKeys()}
      >
        {this._renderMenuItems()}
      </Menu>
    );
  }
}

export default SettingsNavigationMenu;
