import BaseModel, { IBaseModelDTO, IBaseModel } from '@common/models/BaseModel';
import VehicleSerieModel, { IVehicleSerieModelDTO, IVehicleSerieModel } from '@common/models/VehicleSerieModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const vehicleEngineUrl = '/vehicles/engines';

export interface IVehicleEngineFormValues {
  readonly id?: number;
  name: string;
  serie?: IVehicleSerieModel;
  serie_id: number | null;
  hp?: number | null;
}

export interface IVehicleEngineModelDTO extends IBaseModelDTO {
  name: string;
  serie_id: number;
  hp?: number;
  serie: IVehicleSerieModelDTO;
}

export interface IVehicleEngineModel extends IBaseModel {
  name: string;
  serie_id: number;
  hp?: number;
  serie: IVehicleSerieModel;
  serie__name: string;
}

export class VehicleEngineModel extends BaseModel implements IVehicleEngineModel {
  name: string;
  hp?: number;
  serie_id: number;
  serie: IVehicleSerieModel;
  get serie__name(): string {
    return this.serie && this.serie.name;
  }

  constructor({ id, name, serie_id, hp, date_created, serie }: IVehicleEngineModelDTO) {
    super({ id, date_created });

    this.name = name;
    this.serie_id = serie_id;
    this.hp = hp;
    this.serie = VehicleSerieModel.build(serie);
  }

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: vehicleEngineUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleEngineModel.buildFetchUrl(vehicleEngineUrl, id), method: 'GET' });

  static createFetch = (data: IVehicleEngineModelDTO) => Resource({ url: vehicleEngineUrl, method: 'POST', data });

  static updateFetch = (data: IVehicleEngineModelDTO) =>
    Resource({ url: VehicleEngineModel.buildFetchUrl(vehicleEngineUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleEngineModel.buildFetchUrl(vehicleEngineUrl, id), method: 'DELETE' });
}

export default VehicleEngineModel;
