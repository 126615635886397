import * as React from 'react';
import { Icon, Typography } from 'antd';
import styles from './VehicleTemplate.module.less';
import { IFormAutocompleteSelectedTemplate } from '@common/components/Form/FormAutocomplete';
import VehicleUpdate from '@routes/Workshop/Vehicle/VehicleUpdate';
import InjectMassage from '@common/translations';

const { Text } = Typography;

const FormAutocompleteVehicleTemplate = ({
  item,
  showEditForm,
  setEditForm,
  onEditFormSuccess
}: IFormAutocompleteSelectedTemplate<any>) => {
  return (
    <div className={styles.Container}>
      <div className={styles.InfoContainer}>
        <div className={styles.Info}>
          <div>
            <Text type="secondary" ellipsis>
              <InjectMassage id="routes.vehicle.form.reg_no" />: {item.reg_no}
            </Text>
          </div>
        </div>

        <Icon type="edit" className={styles.EditButton} onClick={() => setEditForm(true)} />
        {showEditForm ? (
          <VehicleUpdate
            vehicleId={item.id}
            onCloseAction={() => setEditForm(false)}
            onSuccessAction={onEditFormSuccess}
            disabledContractor
          />
        ) : null}
      </div>
    </div>
  );
};

export default FormAutocompleteVehicleTemplate;
