import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';

const ManagementInvoice = LoadableComponent(() => import('@routes/Document/Invoice'));
const ManagementContractor = LoadableComponent(() => import('@routes/Management/Contractor'));

class Management extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={Paths.ManagementInvoice} component={ManagementInvoice} />
        <Route path={Paths.ManagementContractor} component={ManagementContractor} />
        <Redirect from={Paths.Management} to={Paths.ManagementInvoice} />
      </Switch>
    );
  }
}

export default compose(
  withRouter,
  connect()
)(Management) as React.ComponentType;
