import produce from 'immer';

import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  CHECK_AUTHENTICATED_FAILURE,
  CHECK_AUTHENTICATED_REQUEST,
  CHECK_AUTHENTICATED_SUCCESS,
  AUTH_LOCK_REQUEST,
  AUTH_LOCK_SUCCESS,
  AUTH_LOCK_FAILURE
} from '@common/redux/modules/Auth/AuthTypes';

import { IReducerAction } from '@common/interfaces';

export interface IAuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  isLock: boolean;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  isLoading: false,
  isLoaded: false,
  isLock: false
};

export default function(state: IAuthState = initialState, action: IReducerAction<{}>): IAuthState {
  return produce(state, draft => {
    switch (action.type) {
      case CHECK_AUTHENTICATED_REQUEST: {
        draft.isAuthenticated = false;
        draft.isLoading = true;
        draft.isLoaded = false;
        draft.isLock = false;

        break;
      }

      case CHECK_AUTHENTICATED_SUCCESS: {
        draft.isAuthenticated = true;
        draft.isLoading = false;
        draft.isLoaded = true;

        break;
      }

      case CHECK_AUTHENTICATED_FAILURE: {
        draft.isAuthenticated = false;
        draft.isLoading = false;
        draft.isLoaded = true;

        break;
      }

      case AUTH_LOGIN_REQUEST: {
        draft.isLoading = true;

        break;
      }

      case AUTH_LOGIN_SUCCESS: {
        draft.isAuthenticated = true;
        draft.isLoading = false;
        draft.isLock = false;

        break;
      }

      case AUTH_LOGIN_FAILURE: {
        draft.isLoading = false;

        break;
      }

      case AUTH_LOGOUT_REQUEST: {
        draft.isLoading = true;

        break;
      }

      case AUTH_LOGOUT_SUCCESS: {
        draft.isAuthenticated = false;
        draft.isLoading = false;

        break;
      }

      case AUTH_LOGOUT_FAILURE: {
        draft.isLoading = false;

        break;
      }

      case AUTH_LOCK_REQUEST: {
        draft.isLoading = true;

        break;
      }

      case AUTH_LOCK_SUCCESS: {
        draft.isAuthenticated = false;
        draft.isLoading = false;
        draft.isLock = true;

        break;
      }

      case AUTH_LOCK_FAILURE: {
        draft.isLoading = false;

        break;
      }
    }
  });
}
