import * as React from 'react';
import { ObjectSchema } from 'yup';
import { Form } from 'antd';
import { FormProps as AntFormProps } from 'antd/es/form';
import { Form as FinalForm, FormProps, FormRenderProps } from 'react-final-form';
import { validate } from '@common/helpers';

export interface IFormRenderProps<T> extends FormRenderProps<T> {}

export interface IFormProps<T> extends FormProps<T> {
  schema?: () => ObjectSchema;
  formProps?: AntFormProps;
}

const _Form = <T extends {}>({ schema, formProps = {}, ...props }: IFormProps<T>): JSX.Element => {
  return (
    <FinalForm
      subscription={{ submitting: true, pristine: true }}
      {...props}
      validate={schema ? (values: T) => validate<T>(values, schema) : undefined}
      render={(renderProps: FormRenderProps<T>) => {
        return (
          <Form onSubmit={renderProps.handleSubmit} {...formProps}>
            {typeof props.children === 'function' ? props.children(renderProps) : props.children}
          </Form>
        );
      }}
    />
  );
};

export default _Form;
