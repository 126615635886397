import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import IntlMessages from '@common/translations';
import {
  vehicleSerieUpdateAction,
  vehicleSerieUpdateLoadDataAction
} from '@routes/Settings/Vehicle/redux/modules/VehicleSerie/VehicleSerieActions';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import VehicleSerieForm from '@routes/Settings/Vehicle/VehicleSerie/components/VehicleSerieForm';
import VehicleSerieModel, { IVehicleSerieFormValues } from '@common/models/VehicleSerieModel';

const VehicleSerieUpdatePageTitle = 'routes.settings.vehicle.serie.update.pageTitle';

interface IProps extends IBaseFormComponentProps<IVehicleSerieFormValues> {
  id: number;
  loadDataAction: Function;
  saveAction: Function;
  disabledGeneration?: boolean;
  intl: IntlShape;
}

interface IState {
  isLoaded: boolean;
  name: string;
  initialValues?: IVehicleSerieFormValues;
}

class VehicleSerieUpdate extends React.Component<IProps & IInjectedProps> {
  state: IState = {
    isLoaded: false,
    name: ''
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (): Promise<void> => {
    const { id, loadDataAction } = this.props;

    try {
      const response = await loadDataAction({ id });
      const model = VehicleSerieModel.build(response.data);

      this.setState({
        isLoaded: true,
        initialValues: model
      });
    } catch (err) {
      console.log('err', err);
      throw err.response.data;
    }
  };

  onSubmit = async (values: IVehicleSerieFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal, disabledGeneration } = this.props;
    const { isLoaded, name, initialValues } = this.state;

    if (!isLoaded) {
      return null;
    }

    return (
      <VehicleSerieForm
        disabledGeneration={disabledGeneration}
        title={<IntlMessages id={VehicleSerieUpdatePageTitle} values={{ name }} />}
        formProps={{
          onSubmit: this.onSubmit,
          initialValues
        }}
        closeModal={closeModal}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      loadDataAction: vehicleSerieUpdateLoadDataAction,
      saveAction: vehicleSerieUpdateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(VehicleSerieUpdate);
