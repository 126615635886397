import { SET_API_URL, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from '@common/redux/modules/Config/ConfigTypes';

export function toggleCollapsedNav(isNavCollapsed: boolean): {} {
  return { type: TOGGLE_COLLAPSED_NAV, payload: isNavCollapsed };
}

export function updateWindowWidth(width: number): {} {
  return { type: WINDOW_WIDTH, payload: width };
}

export function configSetApiUrlAction(url: string): {} {
  return { type: SET_API_URL, payload: `${url}/api` };
}
