import * as React from 'react';
import * as _ from 'lodash';
import { Row, Col } from 'antd';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import FormTextArea from '@common/components/Form/FormTextArea';
import FormInputNumber from '@common/components/Form/FormInputNumber';
import FormCascader from '@common/components/Form/FormCascader';
import { FormCascaderOption } from '@common/components/Form/FormCascader/FormCascader';
import Button from '@common/components/Button';
import FiscalPrinterModel, { IFiscalPrinterFormValues } from '@common/models/FiscalPrinterModel';
import { IBaseFiscalPrinter } from '@common/helpers/FiscalPrinter/BaseFiscalPrinter';
import Icon from '@common/components/Icon';
import styles from './SystemFiscalPrinterForm.module.less';
import Alert from '@common/components/Alert';
import { isElectron } from '@common/helpers/Others';
import { IntlShape, injectIntl } from 'react-intl';
import { IFormProps } from '@common/components/Form/Form/Form';
import FormDrawer from '@common/helpers/FormDrawer';
import { SystemFiscalPrinterFormSchema } from '@routes/Settings/System/SystemFiscalPrinter/components/SystemFiscalPrinterForm/SystemFiscalPrinterFormSchema';

const _isElectron = isElectron();

interface IProps<T> {
  fiscalPrinterProducers: FormCascaderOption[];
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
  intl: IntlShape;
}

let printerConnectionTimeout: any;
let printer: IBaseFiscalPrinter | null;

const SystemFiscalPrinterForm = <T extends IFiscalPrinterFormValues>({
  fiscalPrinterProducers,
  title,
  closeModal,
  formProps,
  intl
}: IProps<T>) => {
  const [connectionStatusLoading, setConnectionStatusLoading] = React.useState<boolean>(false);
  const [connectionStatus, setConnectionStatus] = React.useState<'online' | 'offline' | null>(null);

  React.useEffect(() => {
    return () => {
      if (printerConnectionTimeout) {
        clearTimeout(printerConnectionTimeout);
      }

      if (printer) {
        printer.disconnect();
        printer = null;
      }
    };
  }, []);

  return (
    <FormDrawer<T>
      title={title}
      onClose={closeModal}
      width={500}
      formProps={{
        ...formProps,
        subscription: { submitting: true, pristine: true, values: true },
        schema: () => SystemFiscalPrinterFormSchema(intl)
      }}
    >
      {({ form, values }) => {
        const checkConnectionStatus = async (): Promise<void> => {
          if (!_isElectron) {
            return;
          }

          if (printerConnectionTimeout) {
            clearTimeout(printerConnectionTimeout);
          }

          setConnectionStatus(null);

          if (printer) {
            printer.disconnect();
            printer = null;
          }

          setConnectionStatusLoading(true);

          if (values.port && values.producerModel.length) {
            printer = FiscalPrinterModel.getPrinterDevice({
              producer: values.producerModel[0],
              ip_address: values.ip_address,
              port: values.port
            });

            if (printer) {
              const client = await printer.connect();

              client.on('ready', () => {
                setTimeout(() => {
                  setConnectionStatusLoading(false);
                  setConnectionStatus('online');
                }, 1000);

                if (printerConnectionTimeout) {
                  clearTimeout(printerConnectionTimeout);
                }

                if (printer) {
                  printer.disconnect();
                  printer = null;
                }
              });

              printerConnectionTimeout = setTimeout(() => {
                setConnectionStatusLoading(false);

                if (connectionStatus !== 'online') {
                  setConnectionStatus('offline');
                }

                if (printer) {
                  printer.disconnect();
                  printer = null;
                }
              }, 3000);
            }
          } else {
            setConnectionStatusLoading(false);
          }
        };

        return (
          <React.Fragment>
            {!_isElectron ? (
              <Alert message={intl.formatMessage({ id: 'fiscal_printer_electron_required' })} type="warning" />
            ) : null}

            <FormInput
              name="name"
              formItemProps={{
                required: true,
                label: <IntlMessages id="routes.settings.system.fiscalPrinter.form.name" />
              }}
              disabled={connectionStatusLoading}
              autoFocus
            />

            <FormCascader
              name="producerModel"
              formItemProps={{
                required: true,
                label: <IntlMessages id="routes.settings.system.fiscalPrinter.form.device" />
              }}
              options={fiscalPrinterProducers}
              fieldNames={{
                label: 'label',
                value: 'key',
                children: 'models'
              }}
              onChange={value => {
                setConnectionStatus(null);
                const findProducer = _.find(fiscalPrinterProducers, { key: value[0] });

                if (findProducer?.models) {
                  const findModel = _.find(findProducer.models, { key: value[1] });

                  form.change('port', findModel.defaults.port);
                }
              }}
              disabled={connectionStatusLoading}
            />

            <Row gutter={24}>
              <Col span={14}>
                <FormInput
                  name="ip_address"
                  formItemProps={{
                    required: true,
                    label: <IntlMessages id="routes.settings.system.fiscalPrinter.form.ip_address" />
                  }}
                  onChange={() => {
                    if (connectionStatus) {
                      setConnectionStatus(null);
                    }
                  }}
                  disabled={connectionStatusLoading}
                />
              </Col>

              <Col span={10}>
                <FormInputNumber
                  name="port"
                  formItemProps={{
                    required: true,
                    label: <IntlMessages id="routes.settings.system.fiscalPrinter.form.port" />
                  }}
                  onChange={() => {
                    if (connectionStatus) {
                      setConnectionStatus(null);
                    }
                  }}
                  disabled={connectionStatusLoading}
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Button
                  size="small"
                  disabled={
                    !_isElectron ||
                    !values.producerModel ||
                    !values.producerModel.length ||
                    !values.port ||
                    !values.ip_address
                  }
                  loading={connectionStatusLoading}
                  type="default"
                  onClick={checkConnectionStatus}
                >
                  <IntlMessages id="routes.settings.system.fiscalPrinter.form.checkConnection" />
                </Button>
              </Col>
              <Col span={12}>
                {connectionStatus === 'online' ? (
                  <div className={styles.OnlineStatus}>
                    <Icon type="check-circle" className={styles.StatusIcon} />
                    <IntlMessages id="routes.settings.system.fiscalPrinter.onlineStatus" />
                  </div>
                ) : null}

                {connectionStatus === 'offline' ? (
                  <div className={styles.OfflineStatus}>
                    <Icon type="exclamation-circle" className={styles.StatusIcon} />
                    <IntlMessages id="routes.settings.system.fiscalPrinter.offlineStatus" />
                  </div>
                ) : null}
              </Col>
            </Row>

            <FormTextArea
              name="description"
              formItemProps={{
                label: <IntlMessages id="routes.settings.warehouse.producer.form.description" />
              }}
              autoSize={{
                minRows: 6
              }}
              disabled={connectionStatusLoading}
            />
          </React.Fragment>
        );
      }}
    </FormDrawer>
  );
};

export default injectIntl(SystemFiscalPrinterForm);
