import { Paths } from '@common/constants';

export interface INavigationMenuItem {
  label: string;
  icon?: string;
  items?: INavigationMenuItem[];
  divider?: boolean;
  path?: string;
}

const NavigationMenuItems: INavigationMenuItem[] = [
  {
    label: 'sidebar.workshop',
    icon: 'car',
    path: '',
    items: [
      {
        label: 'sidebar.workshop.orders',
        path: Paths.WorkshopOrder
      },
      {
        label: 'sidebar.workshop.vehicles',
        path: Paths.WorkshopVehicle
      },
      {
        label: 'sidebar.workshop.services',
        path: Paths.WorkshopService
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.sale',
    icon: 'file-protect',
    path: '',
    items: [
      {
        label: 'sidebar.sale.invoice',
        path: Paths.DocumentInvoice
      },
      {
        label: 'sidebar.sale.receipt',
        path: Paths.DocumentReceipt
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.warehouse',
    icon: 'block',
    path: '',
    items: [
      {
        label: 'sidebar.warehouse.goods',
        path: Paths.WarehouseAssortment
      },
      {
        label: 'sidebar.warehouse.pz',
        path: Paths.WarehousePz
      },
      {
        label: 'sidebar.warehouse.wz',
        path: Paths.WarehouseWz
      },
      {
        label: 'sidebar.warehouse.pw',
        path: Paths.WarehousePw
      },
      {
        label: 'sidebar.warehouse.rw',
        path: Paths.WarehouseRw
      },
      {
        label: 'sidebar.warehouse.mm',
        path: Paths.WarehouseMm
      },
      {
        label: 'sidebar.warehouse.stocktaking',
        path: Paths.WarehouseStocktaking
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.management',
    icon: 'project',
    path: '',
    items: [
      {
        label: 'sidebar.management.contractors',
        path: Paths.ManagementContractor
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.report',
    icon: 'snippets',
    path: '',
    items: [
      {
        label: 'sidebar.report.delivery',
        path: Paths.ReportDelivery
      },
      {
        label: 'sidebar.report.employeeWork',
        path: Paths.ReportWorker
      },
      {
        label: 'sidebar.report.saleSummary',
        path: Paths.ReportSaleSummary
      },
      {
        label: 'sidebar.report.saleDocuments',
        path: Paths.ReportSaleDocuments
      },
      {
        label: 'sidebar.report.jpkVat',
        path: Paths.ReportJpkVat
      },
      {
        label: 'sidebar.report.jpkMag',
        path: Paths.ReportJpkMag
      }
    ]
  },
  {
    label: '',
    divider: true
  },
  {
    label: 'sidebar.administration',
    icon: 'setting',
    path: '',
    items: [
      {
        label: 'sidebar.administration.workers',
        path: Paths.AdministrationWorker
      },
      {
        label: 'sidebar.settings',
        path: Paths.Settings
      }
    ]
  }
];

export default NavigationMenuItems;
