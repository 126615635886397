export const WAREHOUSE_CATEGORY_LIST_REQUEST = 'WAREHOUSE_CATEGORY_LIST_REQUEST';
export const WAREHOUSE_CATEGORY_LIST_SUCCESS = 'WAREHOUSE_CATEGORY_LIST_SUCCESS';
export const WAREHOUSE_CATEGORY_LIST_FAILURE = 'WAREHOUSE_CATEGORY_LIST_FAILURE';
export const WAREHOUSE_CATEGORY_CREATE_REQUEST = 'WAREHOUSE_CATEGORY_CREATE_REQUEST';
export const WAREHOUSE_CATEGORY_CREATE_SUCCESS = 'WAREHOUSE_CATEGORY_CREATE_SUCCESS';
export const WAREHOUSE_CATEGORY_CREATE_FAILURE = 'WAREHOUSE_CATEGORY_CREATE_FAILURE';
export const WAREHOUSE_CATEGORY_UPDATE_REQUEST = 'WAREHOUSE_CATEGORY_UPDATE_REQUEST';
export const WAREHOUSE_CATEGORY_UPDATE_SUCCESS = 'WAREHOUSE_CATEGORY_UPDATE_SUCCESS';
export const WAREHOUSE_CATEGORY_UPDATE_FAILURE = 'WAREHOUSE_CATEGORY_UPDATE_FAILURE';
export const WAREHOUSE_CATEGORY_DELETE_REQUEST = 'WAREHOUSE_CATEGORY_DELETE_REQUEST';
export const WAREHOUSE_CATEGORY_DELETE_SUCCESS = 'WAREHOUSE_CATEGORY_DELETE_SUCCESS';
export const WAREHOUSE_CATEGORY_DELETE_FAILURE = 'WAREHOUSE_CATEGORY_DELETE_FAILURE';
