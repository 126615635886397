import * as React from 'react';
import { Typography } from 'antd';
import styles from './VehicleModelOptionTemplate.module.less';
import InjectMassage from '@common/translations';
import { IFormAutocompleteItem } from '@common/components/Form/FormAutocomplete';
import { IVehicleModelDTO } from '@common/models/VehicleModel';

const { Text } = Typography;

const VehicleModelOptionTemplate = <T extends IFormAutocompleteItem<IVehicleModelDTO>>(item: T): React.ReactElement => {
  return (
    <div>
      <div className={styles.Name}>
        <Text ellipsis>{item.text}</Text>
      </div>
      {item.reg_no ? (
        <div className={styles.Info}>
          <Text ellipsis>
            <InjectMassage id="routes.vehicle.form.reg_no" />: {item.reg_no}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

export default VehicleModelOptionTemplate;
