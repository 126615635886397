import * as React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'antd';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import styles from './PageHeading.module.less';

export interface IBreadcrumbsItem {
  breadcrumbName: string;
  path: string;
}

function itemRender(route: IBreadcrumbsItem, params: any, routes: Route[]): JSX.Element {
  const last = routes.indexOf(route) === routes.length - 1;

  return last ? <span>{route.breadcrumbName}</span> : <Link to={route.path}>{route.breadcrumbName}</Link>;
}

interface IPageHeadingProps {
  title?: string;
  subTitle?: string;
  routes?: IBreadcrumbsItem[];
  RightColumnComponent?: JSX.Element;
}

const PageHeading = ({ title, subTitle, routes }: IPageHeadingProps): React.ReactElement => {
  if (title) {
    return (
      <div className={styles.Container}>
        <PageHeader
          title={title}
          subTitle={subTitle}
          className={styles.PageHeader}
        />
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <Breadcrumb separator="/" itemRender={itemRender} routes={routes} />
    </div>
  );
};

export default PageHeading;
