import produce from 'immer';
import { IReducerAction } from '@common/interfaces';
import {
  DRAWER_TYPE,
  SET_API_URL,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH
} from '@common/redux/modules/Config/ConfigTypes';
import { FIXED_DRAWER } from '@common/constants/ActionTypes';
import { getDateFormat, getLocale, getTimezone } from '@common/helpers';

export interface IConfigState {
  navCollapsed: boolean;
  drawerType: string;
  width: number;
  date: {
    timezone: string;
    format: string;
  };
  locale: {
    locale: string;
  };
  apiUrl: string;
}

const initialState: IConfigState = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  width: window.innerWidth,
  date: {
    timezone: getTimezone(),
    format: getDateFormat()
  },
  locale: {
    locale: getLocale()
  },
  apiUrl: ''
};

export default function(state: IConfigState = initialState, action: IReducerAction<any>): IConfigState {
  return produce(state, draft => {
    switch (action.type) {
      case '@@router/LOCATION_CHANGE': {
        draft.navCollapsed = false;

        break;
      }

      case TOGGLE_COLLAPSED_NAV: {
        draft.navCollapsed = action.payload.isNavCollapsed;

        break;
      }

      case DRAWER_TYPE: {
        draft.drawerType = action.payload.drawerType;

        break;
      }
      case WINDOW_WIDTH: {
        draft.width = action.payload.width;

        break;
      }
      case SWITCH_LANGUAGE: {
        draft.locale = action.payload;

        break;
      }
      case SET_API_URL: {
        draft.apiUrl = action.payload;

        break;
      }
    }
  });
}
