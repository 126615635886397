export const CONTRACTOR_LIST_REQUEST = 'CONTRACTOR_LIST_REQUEST';
export const CONTRACTOR_LIST_SUCCESS = 'CONTRACTOR_LIST_SUCCESS';
export const CONTRACTOR_LIST_FAILURE = 'CONTRACTOR_LIST_FAILURE';
export const CONTRACTOR_CREATE_REQUEST = 'CONTRACTOR_CREATE_REQUEST';
export const CONTRACTOR_CREATE_SUCCESS = 'CONTRACTOR_CREATE_SUCCESS';
export const CONTRACTOR_CREATE_FAILURE = 'CONTRACTOR_CREATE_FAILURE';
export const CONTRACTOR_UPDATE_REQUEST = 'CONTRACTOR_UPDATE_REQUEST';
export const CONTRACTOR_UPDATE_SUCCESS = 'CONTRACTOR_UPDATE_SUCCESS';
export const CONTRACTOR_UPDATE_FAILURE = 'CONTRACTOR_UPDATE_FAILURE';
export const CONTRACTOR_DELETE_REQUEST = 'CONTRACTOR_DELETE_REQUEST';
export const CONTRACTOR_DELETE_SUCCESS = 'CONTRACTOR_DELETE_SUCCESS';
export const CONTRACTOR_DELETE_FAILURE = 'CONTRACTOR_DELETE_FAILURE';
export const CONTRACTOR_VIEW_REQUEST = 'CONTRACTOR_VIEW_REQUEST';
export const CONTRACTOR_VIEW_SUCCESS = 'CONTRACTOR_VIEW_SUCCESS';
export const CONTRACTOR_VIEW_FAILURE = 'CONTRACTOR_VIEW_FAILURE';
