import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { vehicleMakeCreateAction } from '@routes/Settings/Vehicle/redux/modules/VehicleMake/VehicleMakeActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import VehicleModelForm from '@routes/Settings/Vehicle/VehicleMake/components/VehicleMakeForm';
import { IVehicleMakeFormValues } from '@common/models/VehicleMakeModel';

const VehicleMakeCreatePageTitle = 'routes.settings.vehicle.make.create.pageTitle';

interface IProps extends IBaseFormComponentProps<IVehicleMakeFormValues> {
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  initialValues: IVehicleMakeFormValues;
}

class VehicleMakeCreate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    initialValues: {
      name: ''
    }
  };

  onSubmit = async (values: IVehicleMakeFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { initialValues } = this.state;
    return (
      <VehicleModelForm
        title={<IntlMessages id={VehicleMakeCreatePageTitle} />}
        closeModal={closeModal}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: vehicleMakeCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(VehicleMakeCreate);
