import * as React from 'react';
import FormAutocomplete, { IFormAutocompleteItem } from '@common/components/Form/FormAutocomplete';
import { VehicleMakeModel, IVehicleMakeModelDTO } from '@common/models/VehicleMakeModel';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import FormDrawer from '@common/helpers/FormDrawer';
import { IVehicleModelFormValues } from '@common/models/VehicleModelModel';
import { IFormProps } from '@common/components/Form/Form/Form';
import { VehicleModelFormSchema } from '@routes/Settings/Vehicle/VehicleModel/components/VehicleModelForm/VehicleModelFormSchema';

interface IProps<T> {
  disabledMake?: boolean;
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

class VehicleModelForm<T extends IVehicleModelFormValues> extends React.PureComponent<IProps<T>> {
  makeIdOptions = {
    resource: VehicleMakeModel.listFetch,
    parseItem: (item: IVehicleMakeModelDTO): IFormAutocompleteItem<IVehicleMakeModelDTO> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  render() {
    const { title, closeModal, formProps, disabledMake } = this.props;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={500}
        formProps={{
          ...formProps,
          schema: VehicleModelFormSchema
        }}
      >
        <div>
          <FormAutocomplete
            name="make_id"
            formItemProps={{
              label: <IntlMessages id="routes.settings.vehicle.model.form.make_id" />,
              required: true
            }}
            selectedItem={formProps.initialValues?.make}
            autocompleteProps={{
              disabled: disabledMake || false
            }}
            fetchOptions={this.makeIdOptions}
          />
          <FormInput
            name="name"
            formItemProps={{
              label: <IntlMessages id="routes.settings.vehicle.model.form.name" />,
              required: true
            }}
            type="text"
          />
        </div>
      </FormDrawer>
    );
  }
}

export default VehicleModelForm;
