import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';

const Order = LoadableComponent(() => import('@routes/Workshop/Order'));
const Vehicle = LoadableComponent(() => import('@routes/Workshop/Vehicle'));
const Service = LoadableComponent(() => import('@routes/Workshop/Service'));

const Workshop = () => {
  return (
    <Switch>
      <Route path={Paths.WorkshopOrder} component={Order} />
      <Route path={Paths.WorkshopVehicle} component={Vehicle} />
      <Route path={Paths.WorkshopService} component={Service} />
      <Redirect exact from={Paths.Workshop} to={Paths.WorkshopOrder} />
    </Switch>
  );
};

export default Workshop;
