import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import VehicleSerieModel, { IVehicleSerieModelDTO } from '@common/models/VehicleSerieModel';
import {
  VEHICLE_SERIE_LIST_REQUEST,
  VEHICLE_SERIE_LIST_SUCCESS,
  VEHICLE_SERIE_LIST_FAILURE,
  VEHICLE_SERIE_CREATE_REQUEST,
  VEHICLE_SERIE_CREATE_SUCCESS,
  VEHICLE_SERIE_CREATE_FAILURE,
  VEHICLE_SERIE_DELETE_REQUEST,
  VEHICLE_SERIE_DELETE_SUCCESS,
  VEHICLE_SERIE_DELETE_FAILURE,
  VEHICLE_SERIE_UPDATE_REQUEST,
  VEHICLE_SERIE_UPDATE_SUCCESS,
  VEHICLE_SERIE_UPDATE_FAILURE
} from '@routes/Settings/Vehicle/redux/modules/VehicleSerie/VehicleSerieTypes';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const vehicleSerieListAction = (params = {}) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: VEHICLE_SERIE_LIST_REQUEST
    });

    const response = await VehicleSerieModel.listFetch({
      ...params,
      cancelToken: source.token
    });

    dispatch({
      type: VEHICLE_SERIE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: VEHICLE_SERIE_LIST_FAILURE
      });
    }
  }
};

export const vehicleSerieCreateAction = (data: IVehicleSerieModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_SERIE_CREATE_REQUEST
    });

    const response = await VehicleSerieModel.createFetch(data);

    dispatch({
      type: VEHICLE_SERIE_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_SERIE_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleSerieUpdateLoadDataAction = ({ id }: { id: number }) => () => VehicleSerieModel.viewFetch({ id });

export const vehicleSerieUpdateAction = (data: IVehicleSerieModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_SERIE_UPDATE_REQUEST
    });

    const response = await VehicleSerieModel.updateFetch(data);

    dispatch({
      type: VEHICLE_SERIE_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_SERIE_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleSerieDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_SERIE_DELETE_REQUEST
    });

    const response = await VehicleSerieModel.deleteFetch({ id });

    dispatch({
      type: VEHICLE_SERIE_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_SERIE_DELETE_FAILURE
    });
  }
};
