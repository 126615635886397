import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import {
  vehicleMakeUpdateLoadDataAction,
  vehicleMakeUpdateAction
} from '@routes/Settings/Vehicle/redux/modules/VehicleMake/VehicleMakeActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import VehicleModelForm from '@routes/Settings/Vehicle/VehicleMake/components/VehicleMakeForm';
import VehicleMakeModel, { IVehicleMakeFormValues } from '@common/models/VehicleMakeModel';

const VehicleMakeUpdatePageTitle = 'routes.settings.vehicle.make.update.pageTitle';

interface IProps extends IBaseFormComponentProps<IVehicleMakeFormValues> {
  id: number;
  loadDataAction: Function;
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  isLoaded: boolean;
  name: string;
  initialValues?: IVehicleMakeFormValues;
}

class VehicleMakeUpdate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    isLoaded: false,
    name: ''
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (): Promise<void> => {
    const { id, loadDataAction } = this.props;

    try {
      const response = await loadDataAction({ id });
      const model = VehicleMakeModel.build(response.data);

      this.setState({
        isLoaded: true,
        initialValues: model
      });
    } catch (err) {
      console.log('err', err);
      throw err.response.data;
    }
  };

  onSubmit = async (values: IVehicleMakeFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { isLoaded, name, initialValues } = this.state;

    if (!isLoaded) {
      return null;
    }

    return (
      <VehicleModelForm
        title={<IntlMessages id={VehicleMakeUpdatePageTitle} values={{ name }} />}
        formProps={{
          onSubmit: this.onSubmit,
          initialValues
        }}
        closeModal={closeModal}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: vehicleMakeUpdateAction,
      loadDataAction: vehicleMakeUpdateLoadDataAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(VehicleMakeUpdate);
