import * as React from 'react';
import { Modal } from 'antd';
import Form from '@common/components/Form/Form';
import Icon from '@common/components/Icon';
import { ReactComponent as TimesSvg } from '@assets/fonts/times.svg';

import styles from './FormModal.module.less';
import Button from '@common/components/Button';
import IntlMessages from '@common/translations';
import { IFormProps, IFormRenderProps } from '@common/components/Form/Form/Form';

const timesIcon: React.FC = TimesSvg;

interface IFormModalProps<T> {
  children: ((props: IFormRenderProps<T>) => JSX.Element) | JSX.Element;
  title: string | JSX.Element;
  onClose: Function;
  contentClassName?: string;
  width?: number;
  submitButtonIcon?: string;
  submitButtonText?: string;
  submitDisabled?: boolean;
  disableBackdropClick?: boolean;
  formProps: IFormProps<T>;
}

class FormModal<T> extends React.Component<IFormModalProps<T>> {
  static defaultProps = {
    fullScreen: false,
    fullWidth: true,
    disableBackdropClick: true
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  _renderTitle = (): JSX.Element => {
    const { title } = this.props;

    return (
      <React.Fragment>
        {title}
        <Icon component={timesIcon} onClick={this.handleClose} />
      </React.Fragment>
    );
  };

  render() {
    const {
      width,
      children,
      submitDisabled,
      submitButtonText,
      submitButtonIcon,
      disableBackdropClick,
      formProps
    } = this.props;

    return (
      <Modal
        className={styles.Container}
        title={this._renderTitle()}
        visible
        onCancel={this.handleClose}
        maskClosable={!disableBackdropClick}
        width={width}
        footer={null}
        destroyOnClose={true}
      >
        <Form<T> {...formProps}>
          {(props: IFormRenderProps<T>) => (
            <React.Fragment>
              {typeof children === 'function' ? children(props) : children}
              <div className={styles.Footer}>
                <Button
                  loading={props.submitting}
                  disabled={submitDisabled}
                  htmlType="submit"
                  icon={submitButtonIcon || 'check'}
                >
                  {submitButtonText || <IntlMessages id="common.helpers.formModal.saveButton" />}
                </Button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </Modal>
    );
  }
}

export default FormModal;
