import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import VehicleModelModel, { IVehicleModelModelDTO } from '@common/models/VehicleModelModel';
import {
  VEHICLE_MODEL_LIST_REQUEST,
  VEHICLE_MODEL_LIST_SUCCESS,
  VEHICLE_MODEL_LIST_FAILURE,
  VEHICLE_MODEL_CREATE_REQUEST,
  VEHICLE_MODEL_CREATE_SUCCESS,
  VEHICLE_MODEL_CREATE_FAILURE,
  VEHICLE_MODEL_DELETE_REQUEST,
  VEHICLE_MODEL_DELETE_SUCCESS,
  VEHICLE_MODEL_DELETE_FAILURE,
  VEHICLE_MODEL_UPDATE_REQUEST,
  VEHICLE_MODEL_UPDATE_SUCCESS,
  VEHICLE_MODEL_UPDATE_FAILURE
} from '@routes/Settings/Vehicle/redux/modules/VehicleModel/VehicleModelTypes';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const vehicleModelListAction = (params = {}) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: VEHICLE_MODEL_LIST_REQUEST
    });

    const response = await VehicleModelModel.listFetch({
      ...params,
      cancelToken: source.token
    });

    dispatch({
      type: VEHICLE_MODEL_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: VEHICLE_MODEL_LIST_FAILURE
      });
    }
  }
};

export const vehicleModelCreateAction = (data: IVehicleModelModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_CREATE_REQUEST
    });

    const response = await VehicleModelModel.createFetch(data);

    dispatch({
      type: VEHICLE_MODEL_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_MODEL_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleModelUpdateLoadDataAction = ({ id }: { id: number }) => () => VehicleModelModel.viewFetch({ id });

export const vehicleModelUpdateAction = (data: IVehicleModelModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_UPDATE_REQUEST
    });

    const response = await VehicleModelModel.updateFetch(data);

    dispatch({
      type: VEHICLE_MODEL_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_MODEL_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleModelDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_DELETE_REQUEST
    });

    const response = await VehicleModelModel.deleteFetch({ id });

    dispatch({
      type: VEHICLE_MODEL_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MODEL_DELETE_FAILURE
    });
  }
};
