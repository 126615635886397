import * as React from 'react';
import * as _ from 'lodash';
import { Subtract } from 'utility-types';

export interface IBaseFormComponentProps<T> {
  onSaveAction: Function;
  closeModal: Function;
  openModal: Function;
  extraData?: { [key: string]: any };
}

export interface IInjectedProps {
  closeModal: Function;
  openModal: Function;
  onSaveAction: Function;
}

export const baseFormComponent = <P extends IInjectedProps>(Wrapped: React.ComponentType<P>) => {
  type HocProps = Subtract<P, IInjectedProps> & {
    onSuccessAction?: (data: any) => void;
    onCloseAction?: () => void;
    extraData?: { [key: string]: any };
  };

  return class SomeHOC extends React.Component<HocProps> {
    onSaveAction = async (
      values: any,
      { saveAction, closeAfterSubmit = true }: { saveAction: Function; closeAfterSubmit: boolean | undefined }
    ) => {
      try {
        const data: any = {};
        const { onSuccessAction } = this.props;

        _.forEach(values, (v, i) => {
          if (_.isPlainObject(v)) {
            data[i] = v.key;
          } else {
            data[i] = v;
          }
        });

        let response;

        if (typeof saveAction === 'function') {
          response = await saveAction(data);
        }

        if (closeAfterSubmit) {
          this.closeModal();
        }

        if (typeof onSuccessAction === 'function') {
          await onSuccessAction({ response, values });
        }
        return response;
      } catch (e) {
        if (_.isObject(e)) {
          const errors: { [key: string]: string } = {};

          _.each(e, (value, key) => {
            errors[key] = _.map(value).join(', ');
          });

          return errors;
        }
      }
    };

    closeModal = () => {
      const { onCloseAction } = this.props;

      if (typeof onCloseAction === 'function') {
        onCloseAction();
      }
    };

    render() {
      return <Wrapped {...(this.props as P)} closeModal={this.closeModal} onSaveAction={this.onSaveAction} />;
    }
  };
};
