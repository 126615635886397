import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import {
  systemFiscalPrinterUpdateLoadDataAction,
  systemFiscalPrinterUpdateAction
} from '@routes/Settings/System/redux/modules/SystemFiscalPrinter/SystemFiscalPrinterActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import SystemFiscalPrinterForm from '@routes/Settings/System/SystemFiscalPrinter/components/SystemFiscalPrinterForm';
import FiscalPrinterModel from '@common/models/FiscalPrinterModel';
import { IFiscalPrinterFormValues } from '@common/models/FiscalPrinterModel';
import { FormCascaderOption } from '@common/components/Form/FormCascader/FormCascader';

const SystemFiscalPrinterPageTitle = 'routes.settings.system.fiscalPrinter.update.pageTitle';

interface IProps extends IBaseFormComponentProps<IFiscalPrinterFormValues> {
  id: number;
  loadDataAction: Function;
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  isLoaded: boolean;
  name: string;
  fiscalPrinterProducers: FormCascaderOption[];
  initialValues?: IFiscalPrinterFormValues;
}

class SystemFiscalPrinterUpdate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    isLoaded: false,
    name: '',
    fiscalPrinterProducers: []
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (): Promise<void> => {
    const { id, loadDataAction } = this.props;

    try {
      const response = await Promise.all([loadDataAction({ id }), FiscalPrinterModel.producerListFetch()]);
      const model = FiscalPrinterModel.build(response[0].data);

      this.setState({
        isLoaded: true,
        fiscalPrinterProducers: response[1].data,
        initialValues: model
      });
    } catch (err) {
      console.log('err', err);
      throw err.response.data;
    }
  };

  onSubmit = async (values: IFiscalPrinterFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(FiscalPrinterModel.serialize(values), { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { isLoaded, name, fiscalPrinterProducers, initialValues } = this.state;

    if (!isLoaded) {
      return null;
    }

    return (
      <SystemFiscalPrinterForm
        title={<IntlMessages id={SystemFiscalPrinterPageTitle} values={{ name }} />}
        fiscalPrinterProducers={fiscalPrinterProducers}
        formProps={{
          onSubmit: this.onSubmit,
          initialValues
        }}
        closeModal={closeModal}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: systemFiscalPrinterUpdateAction,
      loadDataAction: systemFiscalPrinterUpdateLoadDataAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  connect(null, mapDispatchToProps),
  baseFormComponent,
  injectIntl
)(SystemFiscalPrinterUpdate);
