import * as React from 'react';
import { SketchPicker } from 'react-color';
import { Field, FieldRenderProps } from 'react-final-form';
import { FormItemProps } from 'antd/es/form';
import { InputProps } from 'antd/es/input';
import Button from '@common/components/Button';
import Popover from '@common/components/Popover';
import FormItem from '@common/components/Form/FormItem/FormItem';
import Input from '@common/components/Input';
import styles from './FormColorPicker.module.less';

interface IProps extends InputProps {
  name: string;
  formItemProps?: FormItemProps;
}

const FormColorPicker = ({
  name,
  formItemProps = {},
  onChange: $onChange,
  onBlur: $onBlur,
  ...props
}: IProps): JSX.Element => {
  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => {
        return (
          <FormItem name={name} {...formItemProps} meta={meta}>
            <Input
              name={name}
              value={input.value}
              readOnly
              onChange={event => {
                input.onChange(event);
                $onChange && $onChange(event);
              }}
              onBlur={event => {
                input.onBlur(event);
                $onBlur && $onBlur(event);
              }}
              addonAfter={
                <Popover
                  overlayClassName={styles.Container}
                  content={
                    <SketchPicker
                      color={input.value || undefined}
                      onChangeComplete={(color: { hex: string }) => {
                        input.onChange(color.hex);
                      }}
                    />
                  }
                >
                  <Button className={styles.AddonAfterButton} size="small" type="link">
                    <div style={{ width: 20, height: 20, backgroundColor: input.value }} />
                  </Button>
                </Popover>
              }
              {...props}
            />
          </FormItem>
        );
      }}
    </Field>
  );
};

export default FormColorPicker;
