import { isElectron } from '@common/helpers/Others';

let net: any;

if (isElectron()) {
  // @ts-ignore
  net = window.nodeRequire('net');
}

export interface IBaseFiscalPrinterDataPosition {
  name: string;
  amount: number;
  price_unit_gross: number;
  rate?: string;
  unit?: string;
}

export interface IBaseFiscalPrinterData {
  positions: IBaseFiscalPrinterDataPosition[];
  sumPrice: number;
  systemNumber: string;
  nip?: string;
}

export interface IBaseFiscalPrinter {
  port: number;
  ip_address: string;

  server: any;
  client: any;
  connect(): Promise<any>;
  disconnect(): void;
}

class BaseFiscalPrinter implements IBaseFiscalPrinter {
  server: any = new net.Socket({ writable: true, readable: true });
  client: any;

  constructor(public port: number, public ip_address: string) {}

  async connect(): Promise<any> {
    if (!this.client) {
      this.client = await this.server.connect(this.port, this.ip_address);

      return this.client;
    }

    return this.client;
  }

  async disconnect(): Promise<any> {
    if (this.client) {
      await this.client.end();
      this.client = null;
    }

    return true;
  }
}

export default BaseFiscalPrinter;
