import _ from 'lodash';
import axios from 'axios';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import * as base64 from 'base-64';
import 'moment/locale/pl';
import { Params } from '@common/constants/Params';
import { Notify, ValidationNotify } from '@common/helpers/Notify';
import { getBearer } from '@common/helpers/HttpResource';
import { loadLocalStorage } from '@common/services/LocalStorageService';
import { isElectron } from '@common/helpers/Others';

let locale = Params.defaultLocale;
let dateTimezone = Params.defaultDateTimezone;
let dateFormat = Params.defaultDateFormat;
const dateTimeFormat = Params.defaultDateTimeFormat;
const monthFormat = Params.defaultMonthFormat;
const datePickerMask = Params.defaultDatePickerMask;
const yearPickerMask = Params.defaultYearPickerMask;
const defaultCurrency = Params.defaultCurrency;
const amountDisplayType = Params.amountDisplayType;
let pendingRefreshToken = false;
let serverUrl: string | null = null;

/* axios oauth config */
export const authFetch = axios.create({
  headers: {
    Authorization: `Basic ${base64.encode(`${Params.authClientId}:${Params.authClientSecret}`)}`
  }
});

const setServerAddress = () => {
  serverUrl =
    process.env.NODE_ENV === 'production' ? (isElectron() ? 'https://kuc.warigo.pl' : '') : 'http://localhost:3000';

  axios.defaults.baseURL = `${serverUrl}${Params.apiPrefix}`;
  authFetch.defaults.baseURL = `${serverUrl}${Params.authPrefix}`;
};

const getServerAddress = () => serverUrl;

const setLocale = () => {
  Yup.setLocale({
    mixed: {
      required: 'common.helpers.formValidator.required',
      notType: params => {
        const { type } = params;

        if (type === 'date') {
          return 'common.helpers.formValidator.date';
        } else if (type === 'number') {
          return 'common.helpers.formValidator.number';
        }

        return '';
      }
    }
  });

  locale = 'pl';
  moment.locale(locale);
};

const getLocale = () => locale;

const setTimezone = (data: string) => {
  dateTimezone = data;
  moment.tz.setDefault(dateTimezone);
};

const getTimezone = () => dateTimezone;

const setDateFormat = (data: string) => {
  dateFormat = data;
};

const getDateFormat = () => dateFormat;

const getMonthFormat = () => monthFormat;

const getDateTimeFormat = () => dateTimeFormat;

const getDatePickerMask = () => datePickerMask;

const getYearPickerMask = () => yearPickerMask;

const getCurrency = () => defaultCurrency;

const getAmountDisplayType = (): string => amountDisplayType;

const isGrossAmountDisplayType = (): boolean => amountDisplayType === 'gross';

const init = ({ refreshTokenAction, logoutAction }: { refreshTokenAction(): void; logoutAction(): void }) => {
  moment.locale(getLocale());
  moment.tz.setDefault(getTimezone());

  axios.defaults.headers.common['Accept-Language'] = getLocale();

  const checkPendingStatus = async () =>
    new Promise(resolve => {
      if (pendingRefreshToken) {
        setTimeout(() => resolve(checkPendingStatus()), 1000);
      } else {
        resolve();
      }
    });

  axios.interceptors.response.use(
    response => response,
    async error => {
      const errorResponse = error.response;

      console.log(errorResponse);

      if (errorResponse) {
        if (
          errorResponse.status === 401 &&
          errorResponse.data.error === 'invalid_token' &&
          errorResponse.config &&
          !errorResponse.config.__isRetryRequest
        ) {
          errorResponse.config.__isRetryRequest = true;
          if (pendingRefreshToken) {
            await checkPendingStatus();
          } else {
            pendingRefreshToken = true;
            await refreshTokenAction();
            pendingRefreshToken = false;
          }

          const authStorage = loadLocalStorage('auth', true);

          if (!authStorage) {
            throw new Error();
          }

          const { access_token } = authStorage;

          errorResponse.config.headers.Authorization = getBearer(access_token);

          return axios.request(errorResponse.config);
        } else if (errorResponse.data.error === 'invalid_grant') {
          logoutAction();
        } else if (errorResponse.status === 400) {
          console.log(errorResponse);
          if (_.isPlainObject(errorResponse.data) && errorResponse.data.non_field_errors) {
            ValidationNotify(null, errorResponse.data);
          }
        } else if (errorResponse.status === 423) {
        } else {
          ValidationNotify(null, errorResponse.data);
        }
      }

      return Promise.reject(error);
    }
  );

  authFetch.interceptors.response.use(
    response => response,
    error => {
      console.log(error);
      if (error.response && typeof error.response.data === 'object') {
        const { data } = error.response;

        if (data.error && data.error_description) {
          Notify(null, data.error_description);
        } else {
          ValidationNotify(null, data);
        }
      }

      return Promise.reject(error);
    }
  );
};

export {
  getServerAddress,
  setServerAddress,
  setLocale,
  getLocale,
  setTimezone,
  getTimezone,
  setDateFormat,
  getDateFormat,
  getMonthFormat,
  getDateTimeFormat,
  getDatePickerMask,
  getYearPickerMask,
  getCurrency,
  getAmountDisplayType,
  isGrossAmountDisplayType,
  init
};
