import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import VehicleEngineModel, { IVehicleEngineModelDTO } from '@common/models/VehicleEngineModel';
import {
  VEHICLE_ENGINE_LIST_REQUEST,
  VEHICLE_ENGINE_LIST_SUCCESS,
  VEHICLE_ENGINE_LIST_FAILURE,
  VEHICLE_ENGINE_CREATE_REQUEST,
  VEHICLE_ENGINE_CREATE_SUCCESS,
  VEHICLE_ENGINE_CREATE_FAILURE,
  VEHICLE_ENGINE_DELETE_REQUEST,
  VEHICLE_ENGINE_DELETE_SUCCESS,
  VEHICLE_ENGINE_DELETE_FAILURE,
  VEHICLE_ENGINE_UPDATE_REQUEST,
  VEHICLE_ENGINE_UPDATE_SUCCESS,
  VEHICLE_ENGINE_UPDATE_FAILURE
} from '@routes/Settings/Vehicle/redux/modules/VehicleEngine/VehicleEngineTypes';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const vehicleEngineListAction = (params = {}) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: VEHICLE_ENGINE_LIST_REQUEST
    });

    const response = await VehicleEngineModel.listFetch({
      ...params,
      cancelToken: source.token
    });

    dispatch({
      type: VEHICLE_ENGINE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: VEHICLE_ENGINE_LIST_FAILURE
      });
    }
  }
};

export const vehicleEngineCreateAction = (data: IVehicleEngineModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_ENGINE_CREATE_REQUEST
    });

    const response = await VehicleEngineModel.createFetch(data);

    dispatch({
      type: VEHICLE_ENGINE_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_ENGINE_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleEngineUpdateLoadDataAction = ({ id }: { id: number }) => async () =>
  VehicleEngineModel.viewFetch({ id });

export const vehicleEngineUpdateAction = (data: IVehicleEngineModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_ENGINE_UPDATE_REQUEST
    });

    const response = await VehicleEngineModel.updateFetch(data);

    dispatch({
      type: VEHICLE_ENGINE_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_ENGINE_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleEngineDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_ENGINE_DELETE_REQUEST
    });

    const response = await VehicleEngineModel.deleteFetch({ id });

    dispatch({
      type: VEHICLE_ENGINE_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_ENGINE_DELETE_FAILURE
    });
  }
};
