import * as _ from 'lodash';

export interface IBaseModelDTO {
  readonly id: number;
  readonly date_created?: string;
}

export interface IBaseModel {
  readonly id: number;
  readonly date_created?: string;
}

export class BaseModel implements IBaseModel {
  readonly id: number;
  readonly date_created?: string;

  constructor({ id, date_created }: IBaseModelDTO) {
    this.id = id;
    this.date_created = date_created;
  }

  static build<T, C>(this: new (data: C) => T, data: C): T {
    return new this(data);
  }

  static load<T, C>(this: new (data: C) => T, items: C[]): T[] {
    let result: T[] = [];

    if (!Array.isArray(items)) {
      return [];
    }

    for (let i = 0, len = items.length; i < len; i += 1) {
      const item = items[i];

      result = [...result, new this(item)];
    }

    return result;
  }

  static buildFetchUrl(url: string, id?: number | string, params: { key: string; value: any }[] = []) {
    let parsedUrl = url;

    _.forEach(params, ({ key, value }) => {
      parsedUrl = parsedUrl.replace(key, value);
    });

    return id ? `${parsedUrl}/${id}` : parsedUrl;
  }
}

export default BaseModel;
