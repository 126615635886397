import * as React from 'react';
import { InputProps } from 'antd/lib/input';
import { FormItemProps } from 'antd/lib/form';
import { Field, FieldRenderProps } from 'react-final-form';
import Input from '@common/components/Input';
import FormItem from '@common/components/Form/FormItem/FormItem';
import styles from '@common/components/Form/FormInput/FormInput.module.less';

interface IProps extends InputProps {
  name: string;
  formItemProps: FormItemProps;
  mask: string;
  fullWidth?: boolean;
}

const FormInputMask = ({
  name,
  formItemProps = {},
  onChange: $onChange,
  onBlur: $onBlur,
  ...props
}: IProps): JSX.Element => {
  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => (
        <FormItem name={name} {...formItemProps} meta={meta}>
          <Input
            className={styles.Input}
            {...input}
            name={name}
            onChange={event => {
              input.onChange(event);
              $onChange && $onChange(event);
            }}
            onBlur={event => {
              input.onBlur(event);
              $onBlur && $onBlur(event);
            }}
            {...props}
          />
        </FormItem>
      )}
    </Field>
  );
};

export default FormInputMask;
