import * as React from 'react';
import { Card, Col, Divider, Row, Typography } from 'antd';
import PageHeading from '@common/containers/PageHeading';
import styles from './ReportList.module.less';
import { Link } from 'react-router-dom';
import { Paths } from '@common/constants';
import { injectIntl, IntlShape } from 'react-intl';

const { Title } = Typography;

interface IProps {
  intl: IntlShape;
}

const ReportList = ({ intl }: IProps): JSX.Element => {
  return (
    <React.Fragment>
      <PageHeading title={intl.formatMessage({ id: 'routes.report.pageTitle' })} />
      <div className={styles.Container}>
        <div className={styles.Section}>
          <Title level={4} className={styles.SectionTitle}>
            Raporty magazynowe
          </Title>
          <Row gutter={24}>
            <Col md={12} lg={8} xl={6}>
              <Card className={styles.Card}>Raport dostaw</Card>
            </Col>

            <Col md={12} lg={8} xl={6}>
              <Link to={Paths.ReportJpkMag}>
                <Card className={styles.Card}>JPK - Magazyn</Card>
              </Link>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className={styles.Section}>
          <Title level={4} className={styles.SectionTitle}>
            Raporty finansowe
          </Title>
          <Row gutter={24}>
            <Col md={12} lg={8} xl={6}>
              <Card className={styles.Card}>Raport sprzedaży</Card>
            </Col>
            <Col md={12} lg={8} xl={6}>
              <Link to={Paths.ReportJpkVat}>
                <Card className={styles.Card}>JPK - VAT</Card>
              </Link>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className={styles.Section}>
          <Title level={4} className={styles.SectionTitle}>
            Pozostałe
          </Title>
          <Row gutter={24}>
            <Col md={12} lg={8} xl={6}>
              <Link to={Paths.ReportWorker}>
                <Card className={styles.Card}>Raport pracy pracowników</Card>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(ReportList);
