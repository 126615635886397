import * as React from 'react';
import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

interface IPopoverProps extends PopoverProps {}

const Popover = (props: IPopoverProps): JSX.Element => {
  return <AntPopover trigger="click" {...props} />;
};

export default Popover;
