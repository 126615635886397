import * as React from 'react';
import { Typography } from 'antd';
import styles from './ContractorOptionTemplate.module.less';
import InjectMassage from '@common/translations';
import { IFormAutocompleteItem } from '@common/components/Form/FormAutocomplete';
import { IContractorModelDTO } from '@common/models/ContractorModel';

const { Text } = Typography;

const FormAutocompleteContractorOptionTemplate = <T extends IFormAutocompleteItem<IContractorModelDTO>>(item: T): React.ReactElement => {
  return (
    <div>
      <div className={styles.Name}>
        <Text ellipsis>{item.name}</Text>
      </div>
      <div className={styles.Info}>
        {item.street && item.building_no ? (
          <div>
            <Text type="secondary" ellipsis>
              {item.street} {item.building_no}
              {item.local_no && `/${item.local_no}`}
            </Text>
          </div>
        ) : null}
        {item.postal_code ? (
          <div>
            <Text type="secondary" ellipsis>
              {item.postal_code} {item.city}
              {item.country && ','} {item.country}
            </Text>
          </div>
        ) : null}
        {item.is_company ? (
          <div className={styles.Info}>
            <Text type="secondary" ellipsis>
              <InjectMassage id="routes.crm.contractor.form.nip" />: {item.is_vat_eu && `${item.nip_prefix} `}
              {item.nip}
            </Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormAutocompleteContractorOptionTemplate;
