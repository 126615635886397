import * as React from 'react';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import { IFormProps } from '@common/components/Form/Form/Form';
import { IInventoryCategoryFormValues } from '@common/models/InventoryCategoryModel';
import FormDrawer from '@common/helpers/FormDrawer';
import { WarehouseCategoryFormSchema } from '@routes/Settings/Warehouse/WarehouseCategory/components/WarehouseCategoryForm/WarehouseCategoryFormSchema';

interface IProps<T> {
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

const WarehouseCategoryForm = <T extends IInventoryCategoryFormValues>({
  title,
  closeModal,
  formProps
}: IProps<T>): JSX.Element => (
  <FormDrawer<T>
    title={title}
    onClose={closeModal}
    width={500}
    formProps={{
      ...formProps,
      schema: WarehouseCategoryFormSchema
    }}
  >
    <FormInput
      name="name"
      formItemProps={{
        label: <IntlMessages id="routes.settings.warehouse.category.form.name" />,
        required: true
      }}
      autoFocus
    />
  </FormDrawer>
);

export default WarehouseCategoryForm;
