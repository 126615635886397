import other from '../locales/pl-PL.json';
import routes from '../locales/routes';
import components from '../locales/components/pl-PL.json';
import constants from '../locales/constants/pl-PL.json';
import { ITranslationConfig } from '@common/translations/config/types';

const PlLang: ITranslationConfig = {
  messages: {
    ...routes,
    ...components,
    ...constants,
    ...other
  },
  locale: 'pl-PL'
};

export default PlLang;
