import { AxiosRequestConfig } from 'axios';
import BaseModel, { IBaseModelDTO, IBaseModel } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import WorkerUserModel, { IWorkerUserModel, IWorkerUserModelDTO } from '@common/models/WorkerUserModel';
import NovitusFiscalPrinter, { INovitusFiscalPrinter } from '@common/helpers/FiscalPrinter/NovitusFiscalPrinter';
import { isElectron } from '@common/helpers/Others';

const fiscalPrinterUrl = '/devices/fiscal-printers';

export interface IFiscalPrinterFormValues {
  readonly id?: number;
  name: string;
  ip_address: string;
  port: number | null;
  producerModel: string[];
  description?: string | null;
}

export interface IFiscalPrinterModelDTO extends IBaseModelDTO {
  name: string;
  ip_address: string;
  port: number;
  author: IWorkerUserModelDTO;
  date_created: string;
  producer: string;
  model: string;
  description?: string | null;
}

export interface IFiscalPrinterModel extends IBaseModel {
  name: string;
  ip_address: string;
  port: number;
  author: IWorkerUserModel;
  date_created: string;
  producer: string;
  model: string;
  producerModel: string[];
  description?: string | null;
}

export class FiscalPrinterModel extends BaseModel implements IFiscalPrinterModel {
  name: string;
  ip_address: string;
  port: number;
  author: IWorkerUserModel;
  date_created: string;
  producer: string;
  model: string;
  producerModel: string[];
  description?: string | null;

  constructor({
    id,
    name,
    ip_address,
    port,
    author,
    date_created,
    producer,
    model,
    description
  }: IFiscalPrinterModelDTO) {
    super({ id, date_created });

    this.name = name;
    this.ip_address = ip_address;
    this.port = port;
    this.author = WorkerUserModel.build(author);
    this.date_created = date_created;
    this.producer = producer;
    this.model = model;
    this.producerModel = [producer, model];
    this.description = description;
  }

  static getPrinterDevice({
    producer,
    port,
    ip_address
  }: {
    producer: string;
    ip_address: string;
    port: number;
  }): INovitusFiscalPrinter | null {
    if (!isElectron()) {
      return null;
    }

    switch (producer) {
      case 'novitus': {
        return new NovitusFiscalPrinter(port, ip_address);
      }

      default: {
        return null;
      }
    }
  }

  static serialize(item: IFiscalPrinterFormValues): any {
    return {
      id: item.id,
      name: item.name,
      ip_address: item.ip_address,
      port: item.port,
      description: item.description,
      producer: item.producerModel[0],
      model: item.producerModel[1]
    };
  }

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: fiscalPrinterUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: FiscalPrinterModel.buildFetchUrl(fiscalPrinterUrl, id), method: 'GET' });

  static createFetch = (data: IFiscalPrinterModelDTO) => Resource({ url: fiscalPrinterUrl, method: 'POST', data });

  static updateFetch = (data: IFiscalPrinterModelDTO) =>
    Resource({ url: FiscalPrinterModel.buildFetchUrl(fiscalPrinterUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: FiscalPrinterModel.buildFetchUrl(fiscalPrinterUrl, id), method: 'DELETE' });

  static producerListFetch = (params: AxiosRequestConfig = {}) =>
    Resource({ url: '/devices/fiscal-printer-models', method: 'GET', ...params });
}

export default FiscalPrinterModel;
