import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

const checkVatEu = ({ nip, nip_prefix }: { nip: string | null; nip_prefix: string | null }) => {
  if (nip && nip_prefix) {
    window.open(
      `http://ec.europa.eu/taxation_customs/vies/viesquer.do?ms=${nip_prefix}&iso=${nip_prefix}&vat=${nip}&BtnSubmitVat`,
      '_blank'
    );
  }
};

const isElectron = (): boolean => {
  // Renderer process
  // @ts-ignore
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }

  // Main process
  // @ts-ignore
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
};

const downloadFile = async ({
  response,
  fileName,
  fileType
}: {
  response: AxiosResponse<any>;
  fileName: string;
  fileType: string;
}): Promise<any> => {
  const newBlob = await new Blob([response.data], { type: fileType });
  FileSaver.saveAs(newBlob, fileName);
};

const saveAsFile = async ({
  response,
  fileName,
  fileType
}: {
  response: AxiosResponse<any>;
  fileName: string;
  fileType: string;
}) => {
  const newBlob = await new Blob([response.data], { type: fileType });

  if (isElectron()) {
    // @ts-ignore
    const electron = window.nodeRequire('electron');
    const tmpPath = electron.remote.app.getPath('temp');
    const fileUrl = `${tmpPath}${fileName}`;

    const reader = new FileReader();
    reader.onload = function() {
      try {
        const fs = electron.remote.require('fs');
        fs.writeFileSync(fileUrl, this.result, 'binary');
        electron.ipcRenderer.send('pdf-viewer', { fileUrl });
      } catch (error) {
        console.error('write file error', error);
      }
    };

    reader.readAsBinaryString(newBlob);
  } else {
    await downloadFile({
      response,
      fileName,
      fileType
    });
  }
};

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export { checkVatEu, downloadFile, saveAsFile, isElectron, delay };
