import * as React from 'react';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import FormDrawer from '@common/helpers/FormDrawer';
import { IVehicleMakeFormValues } from '@common/models/VehicleMakeModel';
import { IFormProps } from '@common/components/Form/Form/Form';
import { VehicleMakeFormSchema } from '@routes/Settings/Vehicle/VehicleMake/components/VehicleMakeForm/VehicleMakeFormSchema';

interface IProps<T> {
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

class VehicleMakeForm<T extends IVehicleMakeFormValues> extends React.PureComponent<IProps<T>> {
  render() {
    const { title, closeModal, formProps } = this.props;
    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={500}
        formProps={{
          ...formProps,
          schema: VehicleMakeFormSchema
        }}
      >
        <FormInput
          name="name"
          formItemProps={{
            label: <IntlMessages id="routes.settings.vehicle.make.form.name" />,
            required: true
          }}
          type="text"
        />
      </FormDrawer>
    );
  }
}

export default VehicleMakeForm;
