import * as _ from 'lodash';
import { Resource } from '@common/helpers';
import { ICountry, ICountryDTO, IUnit, IVatRate, IContactType } from '@common/interfaces';
import { IInventoryWarehouseModelDTO } from '@common/models/InventoryWarehouseModel';

const initApiUrl = '/core/init';

export interface IInitModelDTO {
  defaults: {
    vat_rate: number;
    country: string;
    currency: string;
  };
  contacts: {
    email: string;
    fax: string;
    mobile: string;
    other: string;
    phone: string;
  };
  countries: ICountryDTO[];
  vat_rates: IVatRate[];
  units: IUnit[];
  default_warehouse: IInventoryWarehouseModelDTO;
}

export interface IInitModel {
  defaults: {
    vat_rate: number;
    country: string;
    currency: string;
  };
  contactKinds: IContactType[];
  countries: ICountry[];
  vatCountries: ICountry[];
  vatRates: ({
    label: string;
    value: number;
  } & IVatRate)[];
  units: ({
    label: string;
    value: number;
  } & IUnit)[];
}

export class InitModel implements IInitModel {
  defaults: {
    vat_rate: number;
    country: string;
    currency: string;
  };
  contactKinds: IContactType[];
  countries: {
    label: string;
    value: number;
    regex: number;
  }[];
  vatCountries: {
    label: string;
    value: number;
    regex: number;
  }[];
  vatRates: ({
    label: string;
    value: number;
  } & IVatRate)[];
  units: ({
    label: string;
    value: number;
  } & IUnit)[];

  constructor({ defaults, contacts, countries, vat_rates, units }: IInitModelDTO) {
    this.defaults = defaults;
    this.countries = _.map(countries, ({ name, vat_prefix, vat_regex }: ICountryDTO) => ({
      label: name,
      value: vat_prefix,
      regex: vat_regex
    }));

    this.contactKinds = [];
    _.forEach(contacts, (value, key) => {
      this.contactKinds = [...(this.contactKinds || []), { label: value, value: key }];
    });

    this.vatCountries = _.map(countries, c => ({
      label: `${c.vat_prefix} - ${c.name}`,
      value: c.vat_prefix,
      regex: c.vat_regex
    }));

    this.units = _.map(units, c => ({
      label: c.code,
      value: c.id,
      ...c
    }));

    this.vatRates = _.map(vat_rates, c => ({
      label: c.name,
      value: c.id,
      ...c
    }));
  }

  static build(data: IInitModelDTO): IInitModel {
    return new this(data);
  }

  static fetch = () => Resource({ url: initApiUrl, method: 'GET' });
}

export default InitModel;
