import * as React from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Navigation from '@common/components/Navigation';
import { Paths } from '@common/constants';
import Workshop from '@routes/Workshop';
import Warehouse from '@routes/Warehouse';
import Management from '@routes/Management';
import Document from '@routes/Document';
import Report from '@routes/Report';
import Administration from '@routes/Administration';

import styles from './Main.module.less';
import { IConfigModuleState } from '@common/redux/modules/Config';

interface IAppProps {
  navigationCollapsed: boolean;
}

class App extends React.Component<IAppProps> {
  render() {
    const { navigationCollapsed } = this.props;

    return (
      <Layout className={styles.Container}>
        <Navigation />

        <Layout className={styles.View}>
          <Layout.Content className={cn(styles.Content, { navigationCollapsed: navigationCollapsed })}>
            <Switch>
              <Route path={Paths.Workshop} component={Workshop} />
              <Route path={Paths.Warehouse} component={Warehouse} />
              <Route path={Paths.Document} component={Document} />
              <Route path={Paths.Management} component={Management} />
              <Route path={Paths.Report} component={Report} />
              <Route path={Paths.Administration} component={Administration} />
              <Redirect exact from={Paths.Home} to={Paths.Workshop} />
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ config }: IConfigModuleState) => {
  return {
    navigationCollapsed: config.navCollapsed
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(App) as React.ComponentType;
