import * as React from 'react';
import moment from 'moment';
import { Col, DatePicker, Dropdown, Menu, Row, Table } from 'antd';
import * as _ from 'lodash';
import cn from 'classnames';
import { ColumnProps, PaginationConfig, SorterResult, SortOrder } from 'antd/es/table';
import { MenuItemProps } from 'antd/es/menu/MenuItem';
import { IHeadersState } from '@common/interfaces';
import Icon from '@common/components/Icon';
import IntlMessages from '@common/translations';
import { ISelectItemProps } from '@common/components/Select/Select';
import { DataTableMenuButton } from '@common/components/DataTable/DataTableMenuButton';
import { IDropdownMenuItem } from '@common/components/Dropdown';
import DataTableExport from '@common/components/DataTable/DataTableExport';
import { DataTableSearch } from '@common/components/DataTable/DataTableSearch';
import { getDate } from '@common/helpers';
import { IReduxActionList } from '@common/interfaces/ReduxActionInterface';
import { ReactComponent as EllipsisH } from '@assets/fonts/ellipsis-h.svg';
import styles from './DataTable.module.less';
import FormOpenButton from '@common/components/Form/FormOpenButton';
import { TableRowSelection } from 'antd/lib/table/interface';
import Button from '@common/components/Button';
import Spinner from '@common/components/Spinner';
import EmptyData from '@common/containers/EmptyData';

const ellipsisVIcon: React.FC = EllipsisH;
const { RangePicker } = DatePicker;

export const DataTableColumnSortOrders: {
  desc: 'descend';
  asc: 'ascend';
} = {
  desc: 'descend',
  asc: 'ascend'
};

export interface IDataTableColumn<T> extends ColumnProps<T> {
  dataIndex: string;
  filterByDate?: boolean;
}

export interface IDataTableMenuItem<T> extends MenuItemProps {
  key?: string;
  onPress?: (item: T, fetchData: Function) => void;
  visible?: (item: T) => boolean | boolean;
  autoHide?: boolean;
  icon?: string;
  divider?: boolean;
}

export interface IDataTableCreateButtonComponent {
  fetchData: ({ reset }: { reset: boolean }) => void;
  onCloseAction: Function;
  params?: {
    [key: string]: any;
  };
}

interface IProps<T> {
  columns: IDataTableColumn<T>[];
  data: T[];
  rowKey?: string | ((record: T, index: number) => string);
  headers: IHeadersState;
  flatList?: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  scroll?: any;
  components?: any;
  fetch: {
    resource: Function;
    params?: {};
    order: SortOrder;
    orderBy: string;
  };
  exportOptions?: {
    name: string;
  };
  createButton?: {
    component: ({ fetchData, onCloseAction, params }: IDataTableCreateButtonComponent) => JSX.Element;
    dropdownItems?: IDropdownMenuItem[];
    visible?: Function | boolean;
  };
  itemActions?: IDataTableMenuItem<T>[];
  searchField?: string;
  rowSelection?: TableRowSelection<T>;
  pagination?: PaginationConfig | false;
  fetchDataRef?: (fetchData: Function) => void;
}

interface IState<T> {
  searchQuery: string;
  pagination: PaginationConfig;
  filters?: Partial<Record<keyof T, string[]>>;
  sorter?: SorterResult<T>;
  showExport: boolean;
  showCreateComponent: boolean;
  createComponentParams?: {
    [key: string]: any;
  };
  menuItemVisible: number | null;
  menuItemLoading: string | null;
  tableHeight: number;
}

class DataTable<T extends { id: number }> extends React.Component<IProps<T>, IState<T>> {
  static defaultProps = {
    rowKey: (row: any) => row.id.toString(),
    searchField: 'query'
  };

  _isMounted = false;
  menuItems: IDataTableMenuItem<T>[];
  orderItems: ISelectItemProps[] = [];
  tableMenuItems: IDropdownMenuItem[] = [
    {
      children: <IntlMessages id="common.components.dataTable.export" />,
      visible: () => this.props.exportOptions !== undefined,
      onClick: () => {
        this._setState({
          showExport: true
        });
      }
    }
  ];

  state = {
    searchQuery: '',
    pagination: { current: 1, pageSize: 10 },
    filters: undefined,
    sorter: undefined,
    showExport: false,
    showCreateComponent: false,
    createComponentParams: undefined,
    menuItemLoading: null,
    menuItemVisible: null,
    tableHeight: 0
  };

  get isMounted(): boolean {
    return this._isMounted;
  }

  set isMounted(flag: boolean) {
    this._isMounted = flag;
  }

  defaultMenuItemsActions: { key: string; icon: string }[] = [
    {
      key: 'download',
      icon: 'cloud-download'
    },
    {
      key: 'downloadDocument',
      icon: 'download'
    },
    {
      key: 'delete',
      icon: 'delete'
    },
    {
      key: 'update',
      icon: 'edit'
    }
  ];

  constructor(props: IProps<T>) {
    super(props);

    const { columns, itemActions, fetch } = props;

    this.menuItems = [];

    if (Array.isArray(itemActions)) {
      for (let i = 0, len = itemActions.length; i < len; i += 1) {
        const action = itemActions[i];

        const findDefaultMenuItem = _.find(this.defaultMenuItemsActions, { key: action.key });
        if (findDefaultMenuItem) {
          action.icon = findDefaultMenuItem.icon;
          action.title = action.title || <IntlMessages id={`popup.${action.key}`} />;
        }

        this.menuItems.push(action);
      }

      if (itemActions.length > 0) {
        columns.push({
          title: '',
          dataIndex: 'actions',
          align: 'right',
          width: 60,
          sorter: false,
          render: (text, record) => (
            <Dropdown
              overlay={this.getItemMenu(record)}
              trigger={['click']}
              onVisibleChange={flag => this.handleItemMenuVisibleChange(flag ? record.id : null)}
              visible={this.state.menuItemVisible === record.id}
            >
              <Icon className={styles.ActionIcon} component={ellipsisVIcon} />
            </Dropdown>
          )
        });
      }
    }

    for (let i = 0, len = columns.length; i < len; i += 1) {
      const column = columns[i];

      if (column.sorter === undefined) {
        column.sorter = true;
      }

      if (column.filterByDate) {
        column.filterIcon = filtered => <Icon type="calendar" className={cn({ [styles.FilteredIcon]: filtered })} />;
        column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <div className={styles.FilteredContainer}>
              <RangePicker
                size="small"
                className={styles.FilteredDatePicker}
                defaultPickerValue={[moment().subtract(1, 'months'), moment()]}
                onChange={(dates: any) => {
                  if (setSelectedKeys) {
                    setSelectedKeys(dates);
                  }
                }}
                value={selectedKeys as any}
              />

              <div className="ant-table-filter-dropdown-btns">
                <Button
                  className="ant-table-filter-dropdown-link confirm"
                  type="link"
                  size="small"
                  onClick={() => {
                    if (confirm) {
                      confirm();
                    }
                  }}
                >
                  <IntlMessages id="common.components.dataTable.filter.okButton" />
                </Button>
                <Button
                  className="ant-table-filter-dropdown-link clear"
                  type="link"
                  size="small"
                  onClick={() => {
                    if (clearFilters) {
                      clearFilters();
                    }
                  }}
                >
                  <IntlMessages id="common.components.dataTable.filter.clearButton" />
                </Button>
              </div>
            </div>
          );
        };
      }

      if (column.dataIndex === fetch.orderBy) {
        column.defaultSortOrder = fetch.order;
      }
    }
  }

  componentDidMount() {
    const { fetchDataRef } = this.props;
    this.isMounted = true;

    this.fetchData();

    if (typeof fetchDataRef === 'function') {
      fetchDataRef(this.fetchData);
    }

    this.updateWindowDimensions();
  }

  componentWillUnmount(): void {
    this.isMounted = false;
  }

  _setState = (props: any): void => {
    if (this.isMounted) {
      this.setState(props);
    }
  };

  updateWindowDimensions = (): void => {
    let tableRows = 0;

    for (let i = 1, len = 100; i < len; i += 1) {
      const tableHeight = i * 58 + 360;

      if (tableHeight > window.innerHeight) {
        tableRows = i;
        break;
      }
    }
    console.log(tableRows);
    this._setState({
      pagination: {
        ...this.state.pagination,
        pageSize: tableRows
      }
    });
  };

  fetchData = _.debounce(
    async (
      { pagination = this.state.pagination, filters = this.state.filters, sorter = this.state.sorter, headers } = {},
      extraParams?: IReduxActionList
    ) => {
      const { fetch, searchField, columns } = this.props;

      if (fetch) {
        const { searchQuery } = this.state;
        const params: any = fetch.params || {};

        const { current, pageSize } = pagination;

        if (searchQuery && searchQuery.length > 0) {
          params[searchField || 'query'] = searchQuery;
        }

        if (sorter) {
          params.ordering = `${sorter.order === DataTableColumnSortOrders.desc ? '-' : ''}${sorter.field}`;
        } else {
          params.ordering = `${fetch.order === DataTableColumnSortOrders.desc ? '-' : ''}${fetch.orderBy}`;
        }

        if (filters) {
          _.forEach(filters, (filter, key) => {
            const findIndex = _.findIndex(columns, { dataIndex: key, filterByDate: true });
            if (findIndex > -1 && Array.isArray(filter) && filter.length > 1) {
              params[`${key}_gte`] = moment(filter[0]).toISOString();
              params[`${key}_lte`] = moment(filter[1]).toISOString();
            } else {
              params[key] = filter.length > 1 ? filter : filter[0];
            }
          });
        }

        fetch.resource(
          {
            params: {
              ...params,
              page: current,
              per_page: pageSize
            },
            headers
          },
          extraParams
        );
      }
    },
    200
  );

  getItemMenu = (record: T) => {
    const items = [];
    const filterItems = this.menuItems.filter(item => item.visible === undefined || item.visible(record));

    for (let i = 0, len = filterItems.length; i < len; i += 1) {
      const item = filterItems[i];

      items.push(item);
      if (len > i + 1) {
        items.push({
          divider: true
        });
      }
    }

    return (
      <Menu>
        {items.map((item, index) => {
          return item.divider ? (
            <Menu.Divider key={index} />
          ) : (
            <Menu.Item
              key={index}
              onClick={async () => {
                if (item.key && typeof item.onPress === 'function') {
                  this._setState({
                    menuItemLoading: item.key
                  });

                  await item.onPress(record, this.fetchData);

                  this._setState({
                    menuItemLoading: null,
                    menuItemVisible: null
                  });
                }
              }}
            >
              {item.key === this.state.menuItemLoading ? (
                <Spinner loadingClassName={styles.MenuItemSpinnerIcon} loading={true} size="small" />
              ) : item.icon ? (
                <Icon className={styles.MenuItemIcon} type={item.icon} />
              ) : null}
              {item.title}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  handleItemMenuVisibleChange = (key: number | null): void => {
    this._setState({ menuItemVisible: key });
  };

  handleTableChange = async (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof T, string[]>>,
    sorter: SorterResult<T>
  ) => {
    await this._setState({
      pagination,
      filters,
      sorter
    });

    this.fetchData({ pagination, filters, sorter });
  };

  handleSearch = async (value: string) => {
    await this._setState({
      searchQuery: value,
      pagination: { current: 1, pageSize: this.state.pagination.pageSize }
    });

    this.fetchData();
  };

  handleTableMenuSuccessModal = ({ values }: { values: { format: string } }): void | null => {
    const { exportOptions } = this.props;

    if (!exportOptions) {
      return null;
    }

    this.fetchData(
      {
        headers: {
          Accept: values.format
        }
      },
      {
        exportFileName: `${exportOptions.name} - ${getDate({
          date: new Date(),
          mode: 'date'
        })}`
      }
    );
  };

  handleTableMenuCloseModal = () => {
    this._setState({
      showExport: false
    });
  };

  handleOnClickCreateButton = (params?: { [key: string]: any }) => {
    this._setState({
      showCreateComponent: true,
      createComponentParams: params
    });
  };

  handleOnCloseCreateComponent = () => {
    this._setState({
      showCreateComponent: false,
      createComponentParams: undefined
    });
  };

  renderCreateButton = () => {
    const { createButton } = this.props;
    const { showCreateComponent, createComponentParams } = this.state;

    if (createButton) {
      const CreateButtonComponent = createButton.component;

      return (
        <Col>
          {createButton.visible !== false ? (
            <FormOpenButton onClick={this.handleOnClickCreateButton} dropdownItems={createButton?.dropdownItems} />
          ) : null}
          {showCreateComponent ? (
            <CreateButtonComponent
              fetchData={this.fetchData}
              onCloseAction={this.handleOnCloseCreateComponent}
              params={createComponentParams}
            />
          ) : null}
        </Col>
      );
    }

    return null;
  };

  render() {
    const {
      data,
      isLoading,
      isLoaded,
      pagination,
      rowKey,
      columns,
      headers,
      rowSelection,
      components,
      scroll
    } = this.props;
    const { showExport, pagination: paginationConfig } = this.state;

    return (
      <div className={styles.Container}>
        <Row className={styles.Heading} type="flex" justify="space-between" align="middle">
          <Col>
            <DataTableSearch onSearch={this.handleSearch} />
          </Col>
          <Col>
            <Row type="flex" align="middle" gutter={10}>
              {this.renderCreateButton()}
              <Col>
                <DataTableMenuButton items={this.tableMenuItems} />
                {showExport ? (
                  <DataTableExport
                    onCloseAction={this.handleTableMenuCloseModal}
                    onSuccessAction={this.handleTableMenuSuccessModal}
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          className={styles.Table}
          columns={columns}
          loading={isLoading}
          rowKey={rowKey}
          dataSource={data}
          onChange={this.handleTableChange}
          components={components}
          scroll={
            scroll || {
              x: 'max-content'
            }
          }
          pagination={
            pagination !== undefined
              ? pagination
              : {
                  pageSize: paginationConfig.pageSize,
                  total: headers.totalCount,
                  showTotal: (total, range) => (
                    <IntlMessages
                      id="common.components.dataTable.pagination.totalItems"
                      values={{ total, from: range[0], to: range[1] }}
                    />
                  )
                }
          }
          rowSelection={rowSelection}
          locale={{ emptyText: isLoaded ? <EmptyData /> : <div className={styles.Spinner} /> }}
        />
      </div>
    );
  }
}

export default DataTable;
