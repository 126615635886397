import * as Yup from 'yup';

export const WarehouseProducerFormSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    address: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    email: Yup.string().nullable(),
    website: Yup.string().nullable()
  });
