import * as React from 'react';
import { Icon } from 'antd';
import Button from '@common/components/Button';
import { ReactComponent as PlusSvg } from '@assets/fonts/plus.svg';
import InjectMassage from '@common/translations';
import Dropdown, { IDropdownMenuItem } from '@common/components/Dropdown';
import styles from './FormOpenButton.module.less';

const plusIcon: React.FC = PlusSvg;

const buildDropdownItems = ({ dropdownItems, onClick }: IFormOpenButton): IDropdownMenuItem[] => {
  return dropdownItems
    ? dropdownItems.map(item => ({
        ...item,
        children: (
          <React.Fragment>
            <Icon component={!item.icon ? plusIcon : undefined} type={item.icon} className={styles.Icon} />
            {item.children}
          </React.Fragment>
        ),
        onClick: () => onClick(item.params)
      }))
    : [];
};

export interface IFormOpenButton {
  onClick: (params?: { [key: string]: any }) => void;
  label?: string;
  isLoading?: boolean;
  dropdownItems?: IDropdownMenuItem[];
}

const FormOpenButton = ({ onClick, label, dropdownItems }: IFormOpenButton) => {
  return Array.isArray(dropdownItems) ? (
    <Dropdown items={buildDropdownItems({ dropdownItems, onClick }) || []}>
      <Button size="default">
        {label || <InjectMassage id="add" />}
        <Icon type="down" className={styles.ArrowIcon} />
      </Button>
    </Dropdown>
  ) : (
    <Button onClick={onClick} onMouseDown={onClick} size="default">
      <Icon component={plusIcon} className={styles.Icon} />
      {label || <InjectMassage id="add" />}
    </Button>
  );
};

export default FormOpenButton;
