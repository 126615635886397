export const CHECK_AUTHENTICATED_REQUEST = 'CHECK_AUTHENTICATED_REQUEST';
export const CHECK_AUTHENTICATED_SUCCESS = 'CHECK_AUTHENTICATED_SUCCESS';
export const CHECK_AUTHENTICATED_FAILURE = 'CHECK_AUTHENTICATED_FAILURE';

export const AUTH_LOGIN_REQUEST = 'LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'LOGIN_FAILURE';

export const AUTH_LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const AUTH_LOCK_REQUEST = 'AUTH_LOCK_REQUEST';
export const AUTH_LOCK_SUCCESS = 'AUTH_LOCK_SUCCESS';
export const AUTH_LOCK_FAILURE = 'AUTH_LOCK_FAILURE';
