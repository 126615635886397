import { IModule } from 'redux-dynamic-modules';
import CompanyReducer, { ICompanyState } from '@common/redux/modules/Company/CompanyReducer';

export interface ICompanyModuleState {
  company: ICompanyState;
}

const CompanyModule: IModule<ICompanyModuleState> = {
  id: 'company',
  reducerMap: {
    company: CompanyReducer
  } as any
};

export { CompanyModule };
