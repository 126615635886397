import * as React from 'react';
import { Drawer } from 'antd';
import Icon from '@common/components/Icon';
import { ReactComponent as TimesSvg } from '@assets/fonts/times.svg';
import styles from './FormDrawer.module.less';
import Button from '@common/components/Button';
import IntlMessages from '@common/translations';
import Form from '@common/components/Form/Form';
import { IFormProps, IFormRenderProps } from '@common/components/Form/Form/Form';
const timesIcon: React.FC = TimesSvg;

interface IProps<T> {
  onClose: Function;
  children: ((props: IFormRenderProps<T>) => JSX.Element) | JSX.Element;
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  formProps: IFormProps<T>;
  contentClassName?: string;
  width?: number;
  disableBackdropClick?: boolean;
  footerRender?: (() => JSX.Element) | JSX.Element;
  submitButtonIcon?: string;
  submitButtonText?: string;
  closeButtonText?: string;
  closeButtonIcon?: string;
  hideSubmitButton?: boolean;
  showCloseButton?: boolean;
}

class FormDrawer<T> extends React.Component<IProps<T>> {
  static defaultProps = {
    fullScreen: false,
    fullWidth: true,
    disableBackdropClick: true,
    width: 500
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  renderTitle = (): JSX.Element => {
    const { title, subTitle } = this.props;

    return (
      <div>
        <div>
          <span className={styles.Title}>{title}</span>
          <span className={styles.SubTitle}>{subTitle}</span>
          <Icon component={timesIcon} onClick={this.handleClose} />
        </div>
      </div>
    );
  };

  render() {
    const {
      width,
      children,
      footerRender,
      submitButtonIcon,
      submitButtonText,
      disableBackdropClick,
      formProps,
      hideSubmitButton,
      closeButtonText,
      closeButtonIcon,
      showCloseButton
    } = this.props;

    return (
      <Drawer
        className={styles.Container}
        title={this.renderTitle()}
        visible
        closable={false}
        onClose={this.handleClose}
        maskClosable={!disableBackdropClick}
        width={width}
        destroyOnClose={true}
      >
        <Form<T> subscription={{ submitting: true, pristine: true }} {...formProps}>
          {(props: IFormRenderProps<T>) => (
            <React.Fragment>
              {typeof children === 'function' ? children(props) : children}
              <div className={styles.Footer}>
                <div className={styles.FooterLeftColumn}>
                  {typeof footerRender === 'function' ? footerRender() : footerRender}
                </div>
                <div className={styles.FooterRightColumn}>
                  {showCloseButton ? (
                    <Button type="default" htmlType="button" icon={closeButtonIcon} onClick={this.handleClose}>
                      {closeButtonText || <IntlMessages id="common.helpers.formModal.closeButton" />}
                    </Button>
                  ) : null}
                  {!hideSubmitButton ? (
                    <Button
                      loading={props.submitting}
                      htmlType="submit"
                      icon={submitButtonIcon || 'check'}
                      className={styles.SubmitButton}
                    >
                      {submitButtonText || <IntlMessages id="common.helpers.formModal.saveButton" />}
                    </Button>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
      </Drawer>
    );
  }
}

export default FormDrawer;
