import * as React from 'react';
import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import Icon from '@common/components/Icon';
import { ReactComponent as TimesSvg } from '@assets/fonts/times.svg';
import styles from '@common/helpers/Modal/Modal.module.less';

const timesIcon: React.FC = TimesSvg;

interface IModal extends ModalProps {}

class Modal extends React.PureComponent<IModal> {
  static defaultProps = {
    maskClosable: false
  };

  _renderTitle = (): JSX.Element => {
    const { onCancel, title } = this.props;

    return (
      <React.Fragment>
        {title}
        <Icon component={timesIcon} onClick={onCancel} />
      </React.Fragment>
    );
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <AntModal className={styles.Container} visible {...props} title={this._renderTitle()}>
        {children}
      </AntModal>
    );
  }
}

export default Modal;
