import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { vehicleSerieCreateAction } from '@routes/Settings/Vehicle/redux/modules/VehicleSerie/VehicleSerieActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import VehicleSerieForm from '@routes/Settings/Vehicle/VehicleSerie/components/VehicleSerieForm';
import { IVehicleGenerationModelDTO } from '@common/models/VehicleGenerationModel';
import { IVehicleSerieFormValues } from '@common/models/VehicleSerieModel';

const VehicleSerieCreatePageTitle = 'routes.settings.vehicle.serie.create.pageTitle';

interface IProps extends IBaseFormComponentProps<IVehicleSerieFormValues> {
  generation: IVehicleGenerationModelDTO;
  saveAction: Function;
  disabledGeneration?: boolean;
  intl: IntlShape;
}

interface IState {
  initialValues: IVehicleSerieFormValues;
}

class VehicleSerieCreate extends React.Component<IProps & IInjectedProps> {
  static defaultProps = {
    disabledGeneration: false
  };

  state: IState = {
    initialValues: {
      name: '',
      generation_id: this.props.generation.id || null,
      generation: this.props.generation
    }
  };

  onSubmit = async (values: IVehicleSerieFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal, disabledGeneration } = this.props;
    const { initialValues } = this.state;

    return (
      <VehicleSerieForm
        disabledGeneration={disabledGeneration}
        title={<IntlMessages id={VehicleSerieCreatePageTitle} />}
        closeModal={closeModal}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: vehicleSerieCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(VehicleSerieCreate);
