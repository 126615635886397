import produce from 'immer';
import {
  WAREHOUSE_LIST_REQUEST,
  WAREHOUSE_LIST_SUCCESS,
  WAREHOUSE_LIST_FAILURE
} from '@routes/Warehouse/redux/modules/WarehouseList/WarehouseListTypes';
import { IReducerAction } from '@common/interfaces';
import InventoryWarehouseModel, {
  IInventoryWarehouseModel,
  IInventoryWarehouseModelDTO
} from '@common/models/InventoryWarehouseModel';
import { ISelectItemProps } from '@common/components/Select/Select';

export interface IWarehouseListState {
  data: IInventoryWarehouseModel[];
  selectItems: ISelectItemProps[];
  isLoaded: boolean;
  isLoading: boolean;
}

const INITIAL_STATE: IWarehouseListState = {
  data: [],
  selectItems: [],
  isLoaded: false,
  isLoading: false
};

export default function(
  state = INITIAL_STATE,
  action: IReducerAction<IInventoryWarehouseModelDTO[]>
): IWarehouseListState {
  return produce(state, draft => {
    switch (action.type) {
      case WAREHOUSE_LIST_REQUEST: {
        draft.isLoading = true;

        break;
      }

      case WAREHOUSE_LIST_SUCCESS: {
        draft.data = InventoryWarehouseModel.load(action.payload);
        draft.selectItems = action.payload.map(item => ({
          label: item.name,
          value: item.id
        }));
        draft.isLoaded = true;
        draft.isLoading = false;

        break;
      }

      case WAREHOUSE_LIST_FAILURE: {
        draft.isLoaded = true;
        draft.isLoading = false;

        break;
      }
    }
  });
}
