import * as React from 'react';
import { Menu, Dropdown as AntDropdown } from 'antd';
import styles from './Dropdown.module.less';
import { ClickParam } from 'antd/lib/menu';

export interface IDropdownMenuItem {
  title?: string;
  items?: IDropdownMenuItem[];
  children?: React.ReactNode;
  onClick?: (param: ClickParam) => void;
  divider?: boolean;
  visible?: boolean | Function;
  icon?: string;
  params?: {
    [key: string]: any;
  };
}

const getMenuItem = (index: number, item: IDropdownMenuItem) => {
  if (Array.isArray(item.items)) {
    return (
      <Menu.ItemGroup key={index} title={item.title}>
        {item.items.map((subItem: IDropdownMenuItem, subIndex: number) => (
          <Menu.Item key={subIndex} {...subItem} />
        ))}
      </Menu.ItemGroup>
    );
  } else if (item.divider) {
    return <Menu.Divider key={index} />;
  }

  return <Menu.Item key={index} {...item} />;
};

const getMenu = (items: IDropdownMenuItem[]) => (
  <Menu className={styles.Menu}>{items.map((item, index) => getMenuItem(index, item))}</Menu>
);

const parseItems = (items: IDropdownMenuItem[]): IDropdownMenuItem[] => {
  const result: IDropdownMenuItem[] = [];
  for (let i = 0, len = items.length; i < len; i += 1) {
    const item = items[i];

    if (item.visible === undefined || (typeof item.visible === 'function' ? item.visible() : item.visible)) {
      result.push(item);
    }
  }

  return result;
};

interface IDropdownProps {
  items: IDropdownMenuItem[];
  children: React.ReactNode;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
  disabled?: boolean;
}

const Dropdown = ({ items, ...props }: IDropdownProps): JSX.Element | null => {
  const parsedItems = parseItems(items);

  if (parsedItems.length > 0) {
    return <AntDropdown overlay={getMenu(parsedItems)} trigger={['click']} {...props} />;
  }

  return null;
};

export default Dropdown;
