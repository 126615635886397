import { IModule } from 'redux-dynamic-modules';
import ProfileReducer, { IProfileState } from '@common/redux/modules/Profile/ProfileReducer';

export interface IProfileModuleState {
  profile: IProfileState;
}

const ProfileModule: IModule<IProfileModuleState> = {
  id: 'profile',
  reducerMap: {
    profile: ProfileReducer
  } as any
};

export { ProfileModule };
