import * as React from 'react';
import { Icon, Typography } from 'antd';
import InjectMassage from '@common/translations';
import ContractorUpdate from '@routes/Management/Contractor/ContractorUpdate';
import styles from './ContractorTemplate.module.less';
import { IFormAutocompleteSelectedTemplate } from '@common/components/Form/FormAutocomplete';
import ContractorPreview from '@routes/Management/Contractor/components/ContractorPreview';

const { Text } = Typography;

const FormAutocompleteContractorTemplate = ({
  item,
  setEditForm,
  showEditForm,
  disabled,
  onEditFormSuccess
}: IFormAutocompleteSelectedTemplate<any>) => {
  return (
    <div className={styles.Container}>
      <div className={styles.InfoContainer}>
        <div className={styles.Info}>
          {item.street && item.building_no ? (
            <div>
              <Text type="secondary" ellipsis>
                {item.street} {item.building_no}
                {item.local_no && `/${item.local_no}`}
              </Text>
            </div>
          ) : null}
          {item.postal_code ? (
            <div>
              <Text type="secondary" ellipsis>
                {item.postal_code} {item.city}
                {item.country && ','} {item.country}
              </Text>
            </div>
          ) : null}
          {item.is_company ? (
            <div>
              <Text type="secondary" ellipsis>
                <InjectMassage id="routes.crm.contractor.form.nip" />: {item.is_vat_eu && `${item.nip_prefix} `}
                {item.nip}
              </Text>
            </div>
          ) : null}
        </div>

        {!disabled ? (
          <React.Fragment>
            <ContractorPreview
              contractor={item}
              OpenModalButtonComponent={({ onClick, isLoading }: any) => {
                return <Icon className={styles.ViewButton} onClick={onClick} type={isLoading ? 'loading' : 'eye'} />;
              }}
            />

            <Icon type="edit" className={styles.EditButton} onClick={() => setEditForm(true)} />

            {showEditForm ? (
              <ContractorUpdate
                contractorId={item.id}
                onCloseAction={() => setEditForm(false)}
                onSuccessAction={onEditFormSuccess}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default FormAutocompleteContractorTemplate;
