import { createStore, IModuleStore } from 'redux-dynamic-modules';
import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import { AuthModule } from '@common/redux/modules/Auth';
import { InitModule } from '@common/redux/modules/Init';
import { ConfigModule } from '@common/redux/modules/Config';
import { ProfileModule } from '@common/redux/modules/Profile';
import { CompanyModule } from '@common/redux/modules/Company';
import { DefaultWarehouseModule } from '@common/redux/modules/DefaultWarehouse';

function initializeStore(): IModuleStore<any> {
  return createStore(
    { extensions: [getThunkExtension()] },
    AuthModule,
    InitModule,
    ConfigModule,
    ProfileModule,
    CompanyModule,
    DefaultWarehouseModule
  );
}

export default initializeStore;
