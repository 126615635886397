import * as React from 'react';
import { ActionCreatorsMapObject, bindActionCreators, compose, Dispatch } from 'redux';
import { Row } from 'antd';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { AppLocale } from '@common/translations';
import Main from '@common/containers/Main';
import Settings from '@routes/Settings';
import { Paths } from '@common/constants/Paths';
import LoadableComponent from '@common/components/LoadableComponent';
import PrivateRoute from '@common/containers/PrivateRoute';
import AuthRoute from '@common/containers/AuthRoute';
import AuthLockRoute from '@common/containers/AuthLockRoute';
import {
  authCheckAuthenticatedAction,
  authLogoutAction,
  authRefreshTokenAction
} from '@common/redux/modules/Auth/AuthActions';
import { configSetApiUrlAction } from '@common/redux/modules/Config/ConfigActions';
import { init, setLocale, setServerAddress } from '@common/helpers/Config';
import Spinner from '@common/components/Spinner';

import styles from './App.module.less';
import { IAuthModuleState } from '@common/redux/modules/Auth';
import { IConfigModuleState } from '@common/redux/modules/Config';

interface IProps {
  locale: { locale: string };
  isAuthenticated: boolean;
  isLock: boolean;
  isLoaded: boolean;
  checkAuthenticatedAction: typeof authCheckAuthenticatedAction;
  refreshTokenAction: () => void;
  logoutAction: () => void;
}

class App extends React.Component<IProps> {
  componentDidMount() {
    const { checkAuthenticatedAction, refreshTokenAction, logoutAction } = this.props;

    init({ refreshTokenAction, logoutAction });

    setServerAddress();
    setLocale();
    checkAuthenticatedAction();
  }

  render() {
    const { locale, isAuthenticated, isLock, isLoaded } = this.props;

    const currentAppLocale = AppLocale[locale.locale];

    if (!isLoaded) {
      return (
        <Row type="flex" justify="center" align="middle" className={styles.appSpinner}>
          <Spinner size="large" />
        </Row>
      );
    }

    return (
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Switch>
          <PrivateRoute path={Paths.Home} isAuthenticated={isAuthenticated} component={Main} exact />
          <PrivateRoute path={Paths.Settings} isAuthenticated={isAuthenticated} component={Settings} />
          <AuthRoute
            path={Paths.AuthLogin}
            isAuthenticated={isAuthenticated}
            component={LoadableComponent(() => import('@routes/Auth'))}
          />
          <AuthLockRoute
            path={Paths.AuthLock}
            isAuthenticated={isAuthenticated}
            isLock={isLock}
            component={LoadableComponent(() => import('@routes/Auth'))}
          />
          <PrivateRoute path="/:foo" isAuthenticated={isAuthenticated} component={Main} />
        </Switch>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ config, auth }: IConfigModuleState & IAuthModuleState) => ({
  locale: config.locale,
  isAuthenticated: auth.isAuthenticated,
  isLoaded: auth.isLoaded,
  isLock: auth.isLock
});

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsMapObject => ({
  dispatch,
  ...bindActionCreators(
    {
      checkAuthenticatedAction: authCheckAuthenticatedAction,
      refreshTokenAction: authRefreshTokenAction,
      logoutAction: authLogoutAction,
      setApiUrlAction: configSetApiUrlAction
    },
    dispatch
  )
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App) as React.ComponentType;
