import BaseModel, { IBaseModelDTO, IBaseModel } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const inventoryProducerUrl = '/inventory/producers';

export interface IInventoryProducerFormValues {
  readonly id?: number;
  name: string;
  description?: string | null;
  address?: string | null;
  phone?: string | null;
  email?: string | null;
  website?: string | null;
}

export interface IInventoryProducerModelDTO extends IBaseModelDTO {
  name: string;
  description?: string;
  address?: string;
  phone?: string;
  website?: string;
}

export interface IInventoryProducerModel extends IBaseModel {
  name: string;
  description?: string;
  address?: string;
  phone?: string;
  website?: string;
}

export class InventoryProducerModel extends BaseModel implements IInventoryProducerModel {
  name: string;
  description?: string;
  address?: string;
  phone?: string;
  website?: string;

  constructor({ id, name, description, phone, address, website, date_created }: IInventoryProducerModelDTO) {
    super({ id, date_created });

    this.name = name;
    this.description = description;
    this.address = address;
    this.phone = phone;
    this.website = website;
  }

  static listFetch = (params: AxiosRequestConfig = {}) =>
    Resource({ url: inventoryProducerUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: InventoryProducerModel.buildFetchUrl(inventoryProducerUrl, id), method: 'GET' });

  static createFetch = (data: IInventoryProducerModelDTO) =>
    Resource({ url: inventoryProducerUrl, method: 'POST', data });

  static updateFetch = (data: IInventoryProducerModelDTO) =>
    Resource({ url: InventoryProducerModel.buildFetchUrl(inventoryProducerUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: InventoryProducerModel.buildFetchUrl(inventoryProducerUrl, id), method: 'DELETE' });
}

export default InventoryProducerModel;
