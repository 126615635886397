import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const inventoryCategoryUrl = '/inventory/categories';

export interface IInventoryCategoryFormValues {
  readonly id?: number;
  name: string;
}

export interface IInventoryCategoryModelDTO extends IBaseModelDTO {
  name: string;
}

export interface IInventoryCategoryModel extends IBaseModel {
  name: string;
}

export class InventoryCategoryModel extends BaseModel implements IInventoryCategoryModel {
  name: string;

  constructor({ id, name, date_created }: IInventoryCategoryModelDTO) {
    super({ id, date_created });

    this.name = name;
  }

  static listFetch = (params: AxiosRequestConfig = {}) =>
    Resource({ url: inventoryCategoryUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: InventoryCategoryModel.buildFetchUrl(inventoryCategoryUrl, id), method: 'GET' });

  static createFetch = (data: IInventoryCategoryModelDTO) =>
    Resource({ url: inventoryCategoryUrl, method: 'POST', data });

  static updateFetch = (data: IInventoryCategoryModelDTO) =>
    Resource({ url: InventoryCategoryModel.buildFetchUrl(inventoryCategoryUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: InventoryCategoryModel.buildFetchUrl(inventoryCategoryUrl, id), method: 'DELETE' });
}

export default InventoryCategoryModel;
