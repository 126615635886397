import axios, { AxiosRequestConfig } from 'axios';
import { IHttpGetResponseHeaders, IHeadersState } from '@common/interfaces';

export const Resource = ({ url, method = 'GET', ...params }: AxiosRequestConfig) =>
  axios({
    method,
    url,
    ...params
  });

export const parseHeaders = ({
  per_page,
  num_pages = 1,
  count,
  current_page = 1
}: IHttpGetResponseHeaders): IHeadersState => ({
  currentPage: current_page,
  perPage: per_page,
  pageCount: num_pages,
  totalCount: count
});

export const setBearer = (token?: string): void => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const getBearer = (token?: string): string | null => {
  if (token) {
    return `Bearer ${token}`;
  }

  return null;
};
