import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';
import ReportList from '@routes/Report/ReportList';

const ReportJpkMag = LoadableComponent(() => import('@routes/Report/ReportJpkMag'));
const ReportJpkVat = LoadableComponent(() => import('@routes/Report/ReportJpkVat'));
const ReportWorker = LoadableComponent(() => import('@routes/Report/ReportWorker'));
const ReportDelivery = LoadableComponent(() => import('@routes/Report/ReportDelivery'));
const ReportSaleSummary = LoadableComponent(() => import('@routes/Report/ReportSaleSummary'));
const ReportSaleDocuments = LoadableComponent(() => import('@routes/Report/ReportSaleDocuments'));

const ReportModuleLoader = () => (
  <Switch>
    <Route exact path={Paths.Report} component={ReportList} />
    <Route path={Paths.ReportJpkMag} component={ReportJpkMag} />
    <Route path={Paths.ReportJpkVat} component={ReportJpkVat} />
    <Route path={Paths.ReportWorker} component={ReportWorker} />
    <Route path={Paths.ReportDelivery} component={ReportDelivery} />
    <Route path={Paths.ReportSaleSummary} component={ReportSaleSummary} />
    <Route path={Paths.ReportSaleDocuments} component={ReportSaleDocuments} />
    <Redirect to={Paths.Report} />
  </Switch>
);

export default ReportModuleLoader;
