import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  workerUpdateLoadDataAction,
  workerUpdateAction
} from '@routes/Administration/Worker/redux/modules/Worker/WorkerActions';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import IntlMessages from '@common/translations';
import WorkerUserModel, { IWorkerUserFormValues, IWorkerUserModel } from '@common/models/WorkerUserModel';
import WorkerForm from '@routes/Administration/Worker/components/WorkerForm';
import { IProfileModuleState } from '@common/redux/modules/Profile';

const WorkerUpdatePageTitle = 'routes.management.worker.update.pageTitle';

interface IProps extends IBaseFormComponentProps<IWorkerUserFormValues> {
  id: number;
  loadDataAction: Function;
  saveAction: Function;
  isLoaded: boolean;
  profile: IWorkerUserModel;
}

interface IState {
  isLoaded: boolean;
  name: string;
  initialValues?: IWorkerUserFormValues;
  isOwnerProfile: boolean;
}

class WorkerUpdate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    isLoaded: false,
    name: '',
    isOwnerProfile: false
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (): Promise<void> => {
    const { id, loadDataAction, profile } = this.props;

    try {
      const response = await loadDataAction({ id });

      const model = WorkerUserModel.build(response.data);

      this.setState({
        isLoaded: true,
        name: model.name,
        initialValues: model,
        isOwnerProfile: profile.id === model.id
      });
    } catch (err) {
      throw err.response.data;
    }
  };

  onSubmit = async (values: IWorkerUserFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(WorkerUserModel.serialize(values), { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { name, isLoaded, isOwnerProfile, initialValues } = this.state;

    if (!isLoaded) {
      return null;
    }

    return (
      <WorkerForm
        isMyProfile={isOwnerProfile}
        isEdited
        title={<IntlMessages id={WorkerUpdatePageTitle} values={{ name }} />}
        closeModal={closeModal}
        formProps={{
          onSubmit: this.onSubmit,
          initialValues
        }}
      />
    );
  }
}

const mapStateToProps = ({ profile }: IProfileModuleState) => ({
  profile: profile.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: workerUpdateAction,
      loadDataAction: workerUpdateLoadDataAction
    },
    dispatch
  )
});

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps), baseFormComponent)(WorkerUpdate);
