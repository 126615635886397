import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const vehicleMakeUrl = '/vehicles/makes';

export interface IVehicleMakeFormValues extends Omit<IVehicleMakeModelDTO, 'id'> {
  readonly id?: number;
}

export interface IVehicleMakeModelDTO extends IBaseModelDTO {
  name: string;
}

export interface IVehicleMakeModel extends IBaseModel {
  name: string;
}

export class VehicleMakeModel extends BaseModel implements IVehicleMakeModel {
  name: string;

  constructor({ id, name, date_created }: IVehicleMakeModelDTO) {
    super({ id, date_created });

    this.name = name;
  }

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: vehicleMakeUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleMakeModel.buildFetchUrl(vehicleMakeUrl, id), method: 'GET' });

  static createFetch = (data: IVehicleMakeModelDTO) => Resource({ url: vehicleMakeUrl, method: 'POST', data });

  static updateFetch = (data: IVehicleMakeModelDTO) =>
    Resource({ url: VehicleMakeModel.buildFetchUrl(vehicleMakeUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleMakeModel.buildFetchUrl(vehicleMakeUrl, id), method: 'DELETE' });
}

export default VehicleMakeModel;
