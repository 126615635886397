import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';

const AdministrationWorker = LoadableComponent(() => import('@routes/Administration/Worker'));

class Administration extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path={Paths.AdministrationWorker} component={AdministrationWorker} />
        <Redirect from={Paths.Administration} to={Paths.AdministrationWorker} />
      </Switch>
    );
  }
}

export default Administration;
