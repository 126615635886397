import { Dispatch } from 'redux';
import { History } from 'history';
import { Paths, Params } from '@common/constants';
import { setBearer, authFetch } from '@common/helpers';
import { InitModel } from '@common/models/InitModel';
import { PROFILE_SUCCESS } from '@common/redux/modules/Profile/ProfileTypes';
import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  CHECK_AUTHENTICATED_FAILURE,
  CHECK_AUTHENTICATED_REQUEST,
  CHECK_AUTHENTICATED_SUCCESS,
  AUTH_LOCK_REQUEST,
  AUTH_LOCK_SUCCESS,
  AUTH_LOCK_FAILURE
} from '@common/redux/modules/Auth/AuthTypes';

import { INIT_SUCCESS } from '@common/redux/modules/Init/InitTypes';
import { deleteLocalStorage, loadLocalStorage, saveLocalStorage } from '@common/services/LocalStorageService';
import { COMPANY_SUCCESS } from '@common/redux/modules/Company/CompanyTypes';
import { DEFAULT_WAREHOUSE_SUCCESS } from '@common/redux/modules/DefaultWarehouse/DefaultWarehouseTypes';

export const authCheckAuthenticatedAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: CHECK_AUTHENTICATED_REQUEST
    });

    const authStorage = loadLocalStorage('auth', true);

    if (!authStorage) {
      throw new Error();
    }

    const { access_token } = authStorage;

    if (!access_token) {
      throw new Error();
    }

    await setBearer(access_token);

    const initResponse = await InitModel.fetch();

    dispatch({
      type: INIT_SUCCESS,
      payload: initResponse.data
    });

    dispatch({
      type: COMPANY_SUCCESS,
      payload: initResponse.data.company
    });

    dispatch({
      type: PROFILE_SUCCESS,
      payload: initResponse.data.profile
    });

    dispatch({
      type: DEFAULT_WAREHOUSE_SUCCESS,
      payload: initResponse.data.default_warehouse
    });

    dispatch({
      type: CHECK_AUTHENTICATED_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: CHECK_AUTHENTICATED_FAILURE
    });
  }
};

export const authLoginAction = (
  { username, password }: { username: string; password: string },
  history: History<{
    [key: string]: any;
  }>
) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: AUTH_LOGIN_REQUEST
    });

    let path = Paths.Workshop;

    if (history.location.state && history.location.state.lastPathname) {
      path = history.location.state.lastPathname;
    }

    const response = await authFetch.post('/token', {
      username,
      password,
      grant_type: 'password',
      client_id: Params.authClientId,
      client_secret: Params.authClientSecret
    });

    const { access_token } = response.data;

    saveLocalStorage('auth', response.data, true);

    await setBearer(access_token);

    const initResponse = await InitModel.fetch();

    dispatch({
      type: INIT_SUCCESS,
      payload: initResponse.data
    });

    dispatch({
      type: COMPANY_SUCCESS,
      payload: initResponse.data.company
    });

    dispatch({
      type: PROFILE_SUCCESS,
      payload: initResponse.data.profile
    });

    dispatch({
      type: DEFAULT_WAREHOUSE_SUCCESS,
      payload: initResponse.data.default_warehouse
    });

    dispatch({
      type: AUTH_LOGIN_SUCCESS
    });

    history.push(path);
  } catch (error) {
    dispatch({
      type: AUTH_LOGIN_FAILURE
    });
  }
};

export const authLogoutAction = ({ history }: { history: History }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: AUTH_LOGOUT_REQUEST
    });

    const authStorage = loadLocalStorage('auth', true);

    if (!authStorage) {
      throw new Error();
    }

    const { access_token } = authStorage;

    await authFetch.post('/revoke-token', {
      token: access_token
    });

    await deleteLocalStorage('auth');

    setBearer();

    dispatch({
      type: AUTH_LOGOUT_SUCCESS
    });

    history.push(Paths.AuthLogin);
  } catch (error) {
    dispatch({
      type: AUTH_LOGOUT_FAILURE
    });
  }
};

export const authRefreshTokenAction = () => async () => {
  const authStorage = loadLocalStorage('auth', true);

  if (!authStorage) {
    throw new Error();
  }

  const { refresh_token } = authStorage;

  const response = await authFetch.post('/token', {
    client_id: Params.authClientId,
    client_secret: Params.authClientSecret,
    grant_type: 'refresh_token',
    refresh_token
  });

  const { access_token } = response.data;

  saveLocalStorage('auth', response.data, true);

  await setBearer(access_token);
};

export const authLockAction = ({ history }: { history: History }) => async (dispatch: Dispatch) => {
  try {
    const lastPathname = history.location.pathname;

    dispatch({
      type: AUTH_LOCK_REQUEST
    });

    const authStorage = loadLocalStorage('auth', true);

    if (!authStorage) {
      throw new Error();
    }

    const { access_token } = authStorage;

    await authFetch.post('/revoke-token', {
      token: access_token
    });

    await deleteLocalStorage('auth');

    setBearer();

    dispatch({
      type: AUTH_LOCK_SUCCESS
    });

    history.push({
      pathname: Paths.AuthLock,
      state: {
        lastPathname
      }
    });
  } catch (error) {
    dispatch({
      type: AUTH_LOCK_FAILURE
    });
  }
};
