import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Paths } from '@common/constants';

interface IAuthLockRouteProps extends RouteProps {
  component: React.ComponentType;
  isAuthenticated: boolean;
  isLock: boolean;
}

const AuthLockRoute = ({ component: Component, isAuthenticated, isLock, ...rest }: IAuthLockRouteProps & RouteProps) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated === false) {
        if (isLock) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: Paths.AuthLogin }} />;
        }
      } else {
        return <Redirect to={{ pathname: Paths.Workshop }} />;
      }
    }}
  />
);

export default AuthLockRoute;
