import * as React from 'react';
import { Layout } from 'antd';
import { Provider } from 'react-redux';
import { IModuleStore } from 'redux-dynamic-modules';
import { ConfigProvider } from 'antd';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import App from '@common/containers/App';
import initializeStore from '@common/redux/initializeStore';
import { isElectron } from '@common/helpers/Others';
import pl_PL from 'antd/lib/locale-provider/pl_PL';
import 'antd/dist/antd.less';
import styles from './Root.module.less';

const Router = (isElectron() ? HashRouter : BrowserRouter) as React.ComponentType;
const themeConfig = {
  locale: pl_PL
};

class Root extends React.Component {
  private readonly store: IModuleStore<any> = initializeStore();

  render() {
    return (
      <ConfigProvider {...themeConfig}>
        <Provider store={this.store}>
          <Router>
            <Layout className={styles.Root}>
              <App />
            </Layout>
          </Router>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default Root;
