export const VEHICLE_MAKE_LIST_REQUEST = 'VEHICLE_MAKE_LIST_REQUEST';
export const VEHICLE_MAKE_LIST_SUCCESS = 'VEHICLE_MAKE_LIST_SUCCESS';
export const VEHICLE_MAKE_LIST_FAILURE = 'VEHICLE_MAKE_LIST_FAILURE';
export const VEHICLE_MAKE_CREATE_REQUEST = 'VEHICLE_MAKE_CREATE_REQUEST';
export const VEHICLE_MAKE_CREATE_SUCCESS = 'VEHICLE_MAKE_CREATE_SUCCESS';
export const VEHICLE_MAKE_CREATE_FAILURE = 'VEHICLE_MAKE_CREATE_FAILURE';
export const VEHICLE_MAKE_UPDATE_REQUEST = 'VEHICLE_MAKE_UPDATE_REQUEST';
export const VEHICLE_MAKE_UPDATE_SUCCESS = 'VEHICLE_MAKE_UPDATE_SUCCESS';
export const VEHICLE_MAKE_UPDATE_FAILURE = 'VEHICLE_MAKE_UPDATE_FAILURE';
export const VEHICLE_MAKE_DELETE_REQUEST = 'VEHICLE_MAKE_DELETE_REQUEST';
export const VEHICLE_MAKE_DELETE_SUCCESS = 'VEHICLE_MAKE_DELETE_SUCCESS';
export const VEHICLE_MAKE_DELETE_FAILURE = 'VEHICLE_MAKE_DELETE_FAILURE';
