import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { warehouseProducerCreateAction } from '@routes/Settings/Warehouse/redux/modules/WarehouseProducer/WarehouseProducerActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import WarehouseProducerForm from '@routes/Settings/Warehouse/WarehouseProducer/components/WarehouseProducerForm';
import { IInventoryProducerFormValues } from '@common/models/InventoryProducerModel';

const WarehouseProducerCreatePageTitle = 'routes.settings.warehouse.producer.create.pageTitle';

interface IProps extends IBaseFormComponentProps<IInventoryProducerFormValues> {
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  initialValues: {
    name: string;
  };
}

class WarehouseProducerCreate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    initialValues: {
      name: ''
    }
  };

  onSubmit = (values: IInventoryProducerFormValues) => {
    const { onSaveAction, saveAction } = this.props;

    onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { initialValues } = this.state;

    return (
      <WarehouseProducerForm
        title={<IntlMessages id={WarehouseProducerCreatePageTitle} />}
        closeModal={closeModal}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: warehouseProducerCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(WarehouseProducerCreate);
