import * as React from 'react';
import { Input as AntInput } from 'antd';
import cn from 'classnames';
import { InputProps } from 'antd/lib/input';
import styles from './Input.module.less';

interface IInputProps extends InputProps {
  fullWidth?: boolean;
}

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<AntInput, IInputProps>(
  ({ fullWidth = true, ...props }: IInputProps, ref): JSX.Element => {
    return (
      <AntInput ref={ref} size="large" className={cn(styles.Container, { [styles.fullWidth]: fullWidth })} {...props} />
    );
  }
);

export default Input;
