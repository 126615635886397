import * as React from 'react';
import { Col, Row, Typography } from 'antd';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { authLockAction, authLogoutAction } from '@common/redux/modules/Auth/AuthActions';
import UserAvatar from '@common/components/UserAvatar';
import { IWorkerUserModel } from '@common/models/WorkerUserModel';
import Dropdown from '@common/components/Dropdown';
import { IDropdownMenuItem } from '@common/components/Dropdown/Dropdown';
import { Paths } from '@common/constants';
import styles from './UserInfo.module.less';
import { IProfileModuleState } from '@common/redux/modules/Profile';
import WorkerUpdate from '@routes/Administration/Worker/WorkerUpdate';

const { Text } = Typography;

interface IProps {
  history: History;
  profile: IWorkerUserModel;
  logoutAction: Function;
  lockAction: Function;
  intl: IntlShape;
}

interface IUserInfoState {
  showEditProfileModal: boolean;
}

class UserInfo extends React.Component<IProps & RouteComponentProps, IUserInfoState> {
  menuItems: IDropdownMenuItem[] = [
    { onClick: () => this.handleEditProfile(), children: this.props.intl.formatMessage({ id: 'edit profile' }) },
    { divider: true },
    { onClick: () => this.handleLock(), children: this.props.intl.formatMessage({ id: 'lock' }) },
    { divider: true },
    { onClick: () => this.handleLogout(), children: this.props.intl.formatMessage({ id: 'logout' }) }
  ];

  state = {
    showEditProfileModal: false
  };

  handleEditProfile = async () => {
    await this.setState({
      showEditProfileModal: true
    });
  };

  handleCloseEditProfile = () => {
    this.setState({
      showEditProfileModal: false
    });
  };

  handleSaveEditProfile = () => {
    const { location, history } = this.props;

    if (location.pathname === Paths.AdministrationWorker) {
      history.push({ pathname: Paths.Administration });
    }
  };

  handleLock = () => {
    const { lockAction, history } = this.props;

    lockAction({ history });
  };

  handleLogout = () => {
    const { logoutAction, history } = this.props;

    logoutAction({ history });
  };

  render() {
    const { profile } = this.props;
    const { showEditProfileModal } = this.state;

    return (
      <React.Fragment>
        <Row type="flex">
          <Col className={styles.Avatar}>
            <UserAvatar item={profile} size={40} />
          </Col>
          <Col className={styles.Name}>
            <Dropdown items={this.menuItems}>
              <Text ellipsis>{profile.name}</Text>
            </Dropdown>
          </Col>
        </Row>
        {showEditProfileModal && (
          <WorkerUpdate onCloseAction={this.handleCloseEditProfile} onSuccessAction={this.handleSaveEditProfile} id={profile.id} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ profile }: IProfileModuleState) => ({
  profile: profile.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators({ logoutAction: authLogoutAction, lockAction: authLockAction }, dispatch)
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserInfo) as React.ComponentType;
