import produce from 'immer';
import { IReducerAction } from '@common/interfaces';
import WorkerUserModel, { IWorkerUserModel, IWorkerUserModelDTO } from '@common/models/WorkerUserModel';
import { PROFILE_SUCCESS } from '@common/redux/modules/Profile/ProfileTypes';

export interface IProfileState {
  data: IWorkerUserModel;
}

const initialState = {
  data: {}
};

export default function(state = initialState, action: IReducerAction<IWorkerUserModelDTO>): IProfileState | {} {
  return produce(state, draft => {
    switch (action.type) {
      case PROFILE_SUCCESS: {
        draft.data = WorkerUserModel.build(action.payload);

        break;
      }
    }
  });
}
