import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import VehicleMakeModel, { IVehicleMakeModelDTO, IVehicleMakeModel } from '@common/models/VehicleMakeModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const vehicleModelUrl = '/vehicles/models';

export interface IVehicleModelFormValues extends Omit<IVehicleModelModelDTO, 'id' | 'make_id' | 'make'> {
  readonly id?: number;
  make_id?: number | null;
  make?: IVehicleMakeModelDTO;
}

export interface IVehicleModelModelDTO extends IBaseModelDTO {
  name: string;
  make_id: number;
  make: IVehicleMakeModelDTO;
}

export interface IVehicleModelModel extends IBaseModel {
  name: string;
  make_id?: number;
  make: IVehicleMakeModel;
}

export class VehicleModelModel extends BaseModel implements IVehicleModelModel {
  name: string;
  make_id?: number;
  make: IVehicleMakeModel;

  constructor({ id, name, make_id, make, date_created }: IVehicleModelModelDTO) {
    super({ id, date_created });

    this.name = name;
    this.make_id = make_id;
    this.make = VehicleMakeModel.build(make);
  }

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: vehicleModelUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleModelModel.buildFetchUrl(vehicleModelUrl, id), method: 'GET' });

  static createFetch = (data: IVehicleModelModelDTO) => Resource({ url: vehicleModelUrl, method: 'POST', data });

  static updateFetch = (data: IVehicleModelModelDTO) =>
    Resource({ url: VehicleModelModel.buildFetchUrl(vehicleModelUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleModelModel.buildFetchUrl(vehicleModelUrl, id), method: 'DELETE' });
}

export default VehicleModelModel;
