import { IModule } from 'redux-dynamic-modules';
import DefaultWarehouseReducer, {
  IDefaultWarehouseState
} from '@common/redux/modules/DefaultWarehouse/DefaultWarehouseReducer';

export interface IDefaultWarehouseModuleState {
  defaultWarehouse: IDefaultWarehouseState;
}

const DefaultWarehouseModule: IModule<IDefaultWarehouseModuleState> = {
  id: 'defaultWarehouse',
  reducerMap: {
    defaultWarehouse: DefaultWarehouseReducer
  } as any
};

export { DefaultWarehouseModule };
