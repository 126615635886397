import { IModule } from 'redux-dynamic-modules';
import ConfigReducer, { IConfigState } from '@common/redux/modules/Config/ConfigReducer';

export interface IConfigModuleState {
  config: IConfigState;
}

const ConfigModule: IModule<IConfigModuleState> = {
  id: 'config',
  reducerMap: {
    config: ConfigReducer
  } as any
};

export { ConfigModule };
