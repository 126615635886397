export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';
export const SET_API_URL = 'SET_API_URL';
