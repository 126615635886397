import * as React from 'react';
import FormAutocomplete, { IFormAutocompleteItem } from '@common/components/Form/FormAutocomplete';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import { IVehicleGenerationModelDTO, VehicleGenerationModel } from '@common/models/VehicleGenerationModel';
import FormDrawer from '@common/helpers/FormDrawer';
import { IVehicleSerieFormValues } from '@common/models/VehicleSerieModel';
import { VehicleSerieFormSchema } from '@routes/Settings/Vehicle/VehicleSerie/components/VehicleSerieForm/VehicleSerieFormSchema';
import { IFormProps } from '@common/components/Form/Form/Form';
import { FormSpy } from 'react-final-form';

interface IProps<T> {
  disabledGeneration?: boolean;
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

class VehicleSerieForm<T extends IVehicleSerieFormValues> extends React.PureComponent<IProps<T>> {
  generationIdOptions = {
    resource: VehicleGenerationModel.listFetch,
    parseItem: (item: IVehicleGenerationModelDTO): IFormAutocompleteItem<IVehicleGenerationModelDTO> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  render() {
    const { disabledGeneration, title, closeModal, formProps } = this.props;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={500}
        formProps={{
          ...formProps,
          schema: VehicleSerieFormSchema
        }}
      >
        <div>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <FormAutocomplete
                name="generation_id"
                formItemProps={{
                  label: <IntlMessages id="routes.settings.vehicle.serie.form.generation_id" />,
                  required: true
                }}
                selectedItem={values.generation}
                autocompleteProps={{
                  disabled: disabledGeneration || false
                }}
                fetchOptions={this.generationIdOptions}
              />
            )}
          </FormSpy>
          <FormInput
            name="name"
            formItemProps={{
              label: <IntlMessages id="routes.settings.vehicle.serie.form.name" />,
              required: true
            }}
            type="text"
          />
        </div>
      </FormDrawer>
    );
  }
}

export default VehicleSerieForm;
