import * as React from 'react';
import { compose } from 'redux';
import { Layout } from 'antd';
import { injectIntl, IntlShape } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NavigationMenu from '@common/components/Navigation/NavigationMenu';
import CustomScrollbars from '@common/helpers/CustomScrollbars';
import { IConfigState } from '@common/redux/modules/Config/ConfigReducer';
import UserInfo from '@common/containers/UserInfo';
import styles from './Navigation.module.less';
import { Paths } from '@common/constants';

interface IProps {
  navigationCollapsed: boolean;
  toggleNavigation: () => void;
  intl: IntlShape;
}

interface IState {
  logo: string;
}

class Navigation extends React.PureComponent<IProps & RouteComponentProps, IState> {
  _handleToggle = (): void => {};

  state = {
    logo: require('@assets/images/grey_logo.png')
  };

  render() {
    const { location, intl, navigationCollapsed } = this.props;
    const { logo } = this.state;

    return (
      <Layout.Sider
        className={styles.Container}
        trigger={null}
        collapsible
        width={230}
        collapsed={navigationCollapsed}
        collapsedWidth={62}
      >
        <div className={styles.Heading}>
          <Link to={Paths.Home}>
            <img
              alt="Warigo"
              className={styles.HeadingLogo}
              src={logo}
              onMouseEnter={() => {
                this.setState({
                  logo: require('@assets/images/white_logo.png')
                });
              }}
              onMouseOut={() => {
                this.setState({
                  logo: require('@assets/images/grey_logo.png')
                });
              }}
            />
          </Link>
        </div>
        <CustomScrollbars>
          <NavigationMenu collapsed={navigationCollapsed} intl={intl} pathname={location.pathname} />
        </CustomScrollbars>
        <div className={styles.Footer}>
          <UserInfo />
        </div>
      </Layout.Sider>
    );
  }
}

const mapStateToProps = ({ config }: { config: IConfigState }) => {
  return {
    navigationCollapsed: config.navCollapsed
  };
};

export default compose(withRouter, injectIntl, connect(mapStateToProps))(Navigation) as React.ComponentType;
