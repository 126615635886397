import produce from 'immer';
import { IReducerAction } from '@common/interfaces';
import { INIT_SUCCESS } from '@common/redux/modules/Init/InitTypes';
import InitModel, { IInitModel, IInitModelDTO } from '@common/models/InitModel';

export interface IInitState {
  data: IInitModel;
}

const initialState = {
  data: {}
};

export default function(state = initialState, action: IReducerAction<IInitModelDTO>): IInitState | {} {
  return produce(state, draft => {
    switch (action.type) {
      case INIT_SUCCESS: {
        draft.data = InitModel.build(action.payload);

        break;
      }
    }
  });
}
