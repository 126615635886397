import * as React from 'react';
import * as _ from 'lodash';
import { AutoComplete } from 'antd';
import { SelectValue } from 'antd/es/select';
import { InputProps } from 'antd/es/input';
import axios, { CancelTokenSource } from 'axios';
import { Field, FieldRenderProps } from 'react-final-form';
import Input from '@common/components/Input';
import { AutoCompleteProps } from 'antd/es/auto-complete';
import {
  IFormAutocompleteCreateButtonComponent,
  IFormAutocompleteFetchOptions,
  IFormAutocompleteItem,
  IFormAutocompleteViews
} from '@common/components/Form/FormAutocomplete';
import FormItem, { IFormItemProps } from '@common/components/Form/FormItem/FormItem';
import ContractorOptionTemplate
  from '@common/components/Form/FormAutocomplete/templates/ContractorOptionTemplate/ContractorOptionTemplate';
import VehicleOptionTemplate
  from '@common/components/Form/FormAutocomplete/templates/VehicleModelOptionTemplate/VehicleModelOptionTemplate';
import AssortmentOptionTemplate
  from '@common/components/Form/FormAutocomplete/templates/AssortmentOptionTemplate/AssortmentOptionTemplate';
import styles from './FormAutocomplete.module.less';
import cn from 'classnames';
import ContractorTemplate
  from '@common/components/Form/FormAutocomplete/templates/ContractorTemplate/ContractorTemplate';
import VehicleTemplate from '@common/components/Form/FormAutocomplete/templates/VehicleTemplate/VehicleTemplate';
import FormAutocompleteButton from '@common/components/Form/FormAutocomplete/FormAutocompleteButton';

const { CancelToken } = axios;
const { Option } = AutoComplete;

export const FormAutocompleteViews: { contractor: 'contractor'; vehicle: 'vehicle'; assortment: 'assortment' } = {
  contractor: 'contractor',
  vehicle: 'vehicle',
  assortment: 'assortment'
};

interface IProps<T> {
  name: string;
  autocompleteProps: AutoCompleteProps;
  formItemProps: IFormItemProps;
  inputProps?: InputProps;
  onChangeValue?: (selectedItem?: IFormAutocompleteItem<T>) => void;
  fetchOptions: IFormAutocompleteFetchOptions<T>;
  optionView?: IFormAutocompleteViews;
  fullWidth?: boolean;
  AddComponent?: ({ onCloseAction, onSuccessAction }: IFormAutocompleteCreateButtonComponent) => JSX.Element;
  selectedItem?: T;
}

interface IState<T> {
  items: IFormAutocompleteItem<T>[];
  isLoading: boolean;
  isLoaded: boolean;
  showCreateComponent: boolean;
  showEditForm: boolean;
  searchValue?: string;
  selectedItem?: IFormAutocompleteItem<T>;
  isTouched: boolean;
}

class FormAutocomplete<T extends { id?: number }> extends React.Component<IProps<T>, IState<T>> {
  state: IState<T> = {
    items: [],
    isLoading: false,
    isLoaded: false,
    showCreateComponent: false,
    showEditForm: false,
    selectedItem: this.props.selectedItem ? this.props.fetchOptions.parseItem(this.props.selectedItem) : undefined,
    isTouched: false
  };

  _tmpParams: {} = (typeof this.props.fetchOptions.params === 'function' && this.props.fetchOptions.params()) || {};
  source: CancelTokenSource = CancelToken.source();

  componentDidUpdate(prevProps: IProps<T>): void {
    if (typeof prevProps.fetchOptions.params === 'function') {
      if (
        (prevProps.autocompleteProps?.disabled === true && this.props.autocompleteProps?.disabled === false) ||
        (this._tmpParams &&
          typeof this.props.fetchOptions.params === 'function' &&
          !_.isEqual(this._tmpParams, this.props.fetchOptions.params()))
      ) {
        this.setState({
          selectedItem: undefined
        });

        this.onSearch();
      }

      this._tmpParams = prevProps.fetchOptions.params();
    }

    if (
      (prevProps.selectedItem === undefined && this.props.selectedItem) ||
      (this.props.selectedItem && prevProps.selectedItem?.id !== this.props.selectedItem?.id)
    ) {
      this.setState({
        selectedItem: this.props.fetchOptions.parseItem(this.props.selectedItem)
      });
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel();
    }
  }

  fetch = async (inputValue?: string): Promise<void> => {
    try {
      this.setState({
        isLoading: true
      });

      const { fetchOptions } = this.props;

      const params = (typeof fetchOptions.params === 'function' && fetchOptions.params()) || {};

      if (typeof inputValue === 'string' && inputValue.length > 0) {
        params[fetchOptions.searchField || 'query'] = inputValue;
      }

      const response = await fetchOptions.resource({
        params: {
          ...params,
          per_page: fetchOptions.perPage || 20
        },
        cancelToken: this.source.token
      });

      this.setState({
        items: response.data.results.map(fetchOptions.parseItem),
        isLoading: false
      });
    } catch (e) {
      console.log(e);
      if (!axios.isCancel(e)) {
      }

      this.setState({
        isLoading: false
      });
    }
  };

  onSearch = async (value?: string): Promise<void> => {
    const { autocompleteProps } = this.props;

    if (autocompleteProps?.disabled) {
      return;
    }

    if (this.source) {
      this.source.cancel();
    }

    // const emptyValue = !value || value.length === 0;
    this.source = CancelToken.source();

    await this.setState({
      searchValue: value,
      isLoaded: true
    });

    this.fetch(value);
  };

  toggleCreateComponent = (flag = false) => {
    this.setState({
      showCreateComponent: flag
    });
  };

  toggleEditForm = (value: boolean): void => {
    this.setState({
      showEditForm: value
    });
  };

  renderOption = (item: IFormAutocompleteItem<T>): React.ReactNode | string => {
    const { optionView } = this.props;

    let Component: any;

    switch (optionView) {
      case FormAutocompleteViews.contractor: {
        Component = ContractorOptionTemplate;

        break;
      }

      case FormAutocompleteViews.vehicle: {
        Component = VehicleOptionTemplate;

        break;
      }

      case FormAutocompleteViews.assortment: {
        Component = AssortmentOptionTemplate;

        break;
      }
    }

    if (Component) {
      return <Component<any> {...item} />;
    }

    return item.text;
  };

  renderCreateComponent = (handleOnChange: Function): React.ReactElement | null => {
    const { AddComponent, fetchOptions } = this.props;
    const { showCreateComponent } = this.state;

    if (AddComponent && showCreateComponent) {
      return (
        <AddComponent
          onCloseAction={this.toggleCreateComponent}
          onSuccessAction={(item: T) => {
            const parsedItem = fetchOptions.parseItem(item);

            handleOnChange(parsedItem.value, parsedItem);

            this.onSearch(parsedItem.text);
          }}
        />
      );
    }

    return null;
  };

  render() {
    const {
      name,
      formItemProps,
      inputProps = {},
      onChangeValue,
      autocompleteProps: { onBlur: $onBlur, onChange: $onChange, ...autocompleteProps },
      AddComponent,
      fullWidth
    } = this.props;
    const { items, selectedItem, isTouched, showCreateComponent } = this.state;

    return (
      <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
        {({ input, meta }: FieldRenderProps<SelectValue>) => {
          const _items = showCreateComponent ? [] : [...items];

          if (!isTouched && selectedItem) {
            _items.push(selectedItem);
          }

          const dataSource = _items.map((d: IFormAutocompleteItem<T>) => {
            return (
              <Option key={d.text} value={d.value} title={d.text}>
                {this.renderOption(d)}
              </Option>
            );
          });

          if (AddComponent && !autocompleteProps?.disabled) {
            dataSource.push(
              <Option disabled key="add" className={styles.AddOption}>
                <FormAutocompleteButton
                  onClick={() => {
                    this.toggleCreateComponent(true);
                  }}
                />
              </Option>
            );
          }

          const handleOnChange = (value: SelectValue, selectedItem?: IFormAutocompleteItem<T>) => {
            input.onChange(value);

            this.setState({
              selectedItem
            });

            if (typeof onChangeValue === 'function') {
              onChangeValue(selectedItem);
            }

            $onChange && $onChange(value);
          };

          const onEditFormSuccess = ({ response }: { response: T }) => {
            const { fetchOptions } = this.props;

            const parsedItem = fetchOptions.parseItem(response);

            handleOnChange(parsedItem.value, parsedItem);

            this.onSearch(parsedItem.text);
          };

          const renderSelectedItem = () => {
            const { optionView, autocompleteProps } = this.props;
            const { selectedItem, showEditForm } = this.state;
            let Component;

            if (selectedItem) {
              switch (optionView) {
                case FormAutocompleteViews.contractor: {
                  Component = ContractorTemplate;

                  break;
                }

                case FormAutocompleteViews.vehicle: {
                  Component = VehicleTemplate;

                  break;
                }
              }

              if (Component) {
                return (
                  <Component
                    item={selectedItem}
                    disabled={autocompleteProps?.disabled}
                    showEditForm={showEditForm}
                    setEditForm={this.toggleEditForm}
                    onEditFormSuccess={onEditFormSuccess}
                  />
                );
              }
            }

            return null;
          };

          const setAutoFocus = (input: any) => {
            if (!isTouched && autocompleteProps.autoFocus && input) {
              setTimeout(() => {
                input.focus();
              });
            }
          };

          return (
            <FormItem name={name} {...formItemProps} meta={meta}>
              <AutoComplete
                size="large"
                onSearch={this.onSearch}
                dataSource={dataSource}
                onChange={(value: SelectValue) => {
                  const selectedItem = value
                    ? _.find(items, (i: IFormAutocompleteItem<T>) => i.value === value) || undefined
                    : undefined;

                  handleOnChange(value, selectedItem);
                }}
                onBlur={(event: any) => {
                  input.onBlur(event);

                  $onBlur && $onBlur(event);
                }}
                onFocus={() => {
                  if (!isTouched) {
                    this.setState({
                      isTouched: true
                    });

                    this.onSearch(selectedItem?.text);
                  }
                }}
                value={`${input.value}`}
                optionLabelProp="title"
                className={cn(styles.Autocomplete, { [styles.FullWidth]: fullWidth })}
                dropdownMenuStyle={AddComponent && !autocompleteProps?.disabled ? { marginBottom: 35 } : {}}
                ref={input => setAutoFocus(input)}
                {...autocompleteProps}
              >
                <Input size="large" placeholder="Szukaj..." {...inputProps} value={`${input.value}`} />
              </AutoComplete>

              {renderSelectedItem()}

              {this.renderCreateComponent(handleOnChange)}
            </FormItem>
          );
        }}
      </Field>
    );
  }
}

export default FormAutocomplete;
