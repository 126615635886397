import * as React from 'react';
import { Form } from 'antd';
import { FieldMetaState } from 'react-final-form';
import IntlMessages from '@common/translations';
import { FormItemProps } from 'antd/es/form';
import styles from './FormItem.module.less';

export interface IFormItemProps extends FormItemProps {
  showValidateSuccess?: boolean;
  className?: string;
}

interface IProps {
  children: React.ReactNode;
  name: string;
  meta: FieldMetaState<any>;
}

export const FormItem = ({
  name,
  children,
  showValidateSuccess = false,
  hasFeedback,
  meta,
  ...props
}: IProps & IFormItemProps): JSX.Element => {
  const { error, submitFailed, submitError } = meta;
  const hasError = (error !== undefined || submitError !== undefined) && submitFailed;
  const isValid = !hasError;

  return (
    <Form.Item
      validateStatus={hasError ? 'error' : isValid && showValidateSuccess ? 'success' : undefined}
      help={hasError && (submitError ? submitError : <IntlMessages id={error} />)}
      hasFeedback={hasFeedback && isValid !== undefined}
      className={styles.FormItem}
      {...props}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
