import * as _ from 'lodash';
import win1250 from 'windows-1250';
import BaseFiscalPrinter, {
  IBaseFiscalPrinter,
  IBaseFiscalPrinterData
} from '@common/helpers/FiscalPrinter/BaseFiscalPrinter';

const positionNameLength = 38;

export interface INovitusFiscalPrinter extends IBaseFiscalPrinter {
  printReceipt(data: IBaseFiscalPrinterData): Promise<string | void>;
  cancelReceipt(): Promise<void>;
  checkConnection(): Promise<void>;
}

class NovitusFiscalPrinter extends BaseFiscalPrinter implements INovitusFiscalPrinter {
  async printReceipt(data: IBaseFiscalPrinterData): Promise<string | void> {
    await this.connect();

    if (!this.client || !data.sumPrice || !data.positions) {
      return;
    }

    let post_string = '<pakiet>';
    post_string += '<paragon akcja="poczatek" />';

    for (let i = 0, len = data.positions.length; i < len; i += 1) {
      const position = data.positions[i];
      const name = _.truncate(position.name, {
        omission: '',
        length: positionNameLength
      });

      post_string += `<pozycja nazwa="${name}" ilosc="${position.amount}" jednostka="${position.unit}" stawka="${position.rate}" cena="${position.price_unit_gross}" akcja="sprzedaz"/>`;
    }

    post_string += `<paragon akcja="zamknij" numer_systemowy="${data.systemNumber}" nip="${data.nip}" kwota="${data.sumPrice}" />`;
    post_string += '</pakiet>';

    this.client.write(win1250.encode(post_string), 'binary');

    return post_string;
  }

  async cancelReceipt(): Promise<void> {
    await this.connect();

    if (!this.client) {
      return;
    }

    let post_string = '<pakiet>';
    post_string += '<paragon akcja="anuluj" />';
    post_string += '</pakiet>';

    this.client.write(post_string);
  }

  async wydrukNiefiskalny(): Promise<void> {
    await this.connect();

    if (!this.client) {
      return;
    }

    let post_string = '<pakiet>';
    post_string += '<wydruk_niefiskalny numer_systemowy="123" naglowek_wydruku_niefiskalnego="tak">';
    post_string +=
      '<linia typ="linia" pogrubienie="tak" negatyw="nie" wysrodkowany="tak" numer_czcionki="1" wydruk_na="kopia" atrybut_czcionki="duza">linia1</linia>';
    post_string += '<linia typ="podkreslenie"></linia>';
    post_string += '<linia>PIERŚCIEŃ ŻYćśdasdasdwqeqwe </linia>';
    post_string += '</wydruk_niefiskalny>';
    post_string += '</pakiet>';

    this.client.write(win1250.encode(post_string), 'binary');
  }

  async checkConnection(): Promise<void> {
    await this.connect();

    if (!this.client) {
      return;
    }

    let post_string = '<pakiet>';
    post_string += '<dle_pl/>';
    post_string += '</pakiet>';

    this.client.write(post_string);
  }
}

export default NovitusFiscalPrinter;
