import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { warehouseListCreateAction } from '@routes/Settings/Warehouse/redux/modules/WarehouseList/WarehouseListActions';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import SettingsWarehouseListForm from '@routes/Settings/Warehouse/WarehouseList/components/WarehouseListForm';
import { IInventoryWarehouseFormValues } from '@common/models/InventoryWarehouseModel';

const SettingsWarehouseListCreatePageTitle = 'routes.settings.warehouse.list.create.pageTitle';

interface IProps extends IBaseFormComponentProps<IInventoryWarehouseFormValues> {
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  initialValues: IInventoryWarehouseFormValues;
}

class WarehouseListCreate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    initialValues: {
      name: '',
      is_default: false
    }
  };

  onSubmit = async (values: IInventoryWarehouseFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal, intl } = this.props;
    const { initialValues } = this.state;

    return (
      <SettingsWarehouseListForm
        title={intl.formatMessage({ id: SettingsWarehouseListCreatePageTitle })}
        closeModal={closeModal}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: warehouseListCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(WarehouseListCreate);
