import * as React from 'react';
import FormInput from '@common/components/Form/FormInput';
import FormAutocomplete, {
  IFormAutocompleteItem,
  IFormAutocompleteFetchOptions
} from '@common/components/Form/FormAutocomplete';
import IntlMessages from '@common/translations';
import { IVehicleSerieModelDTO, VehicleSerieModel } from '@common/models/VehicleSerieModel';
import FormDrawer from '@common/helpers/FormDrawer';
import { IFormProps } from '@common/components/Form/Form/Form';
import { IVehicleEngineFormValues } from '@common/models/VehicleEngineModel';
import { VehicleEngineFormSchema } from '@routes/Settings/Vehicle/VehicleEngine/components/VehicleEngineForm/VehicleEngineFormSchema';
import { FormSpy } from 'react-final-form';

interface IProps<T> {
  disabledSerie?: boolean;
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

class VehicleEngineForm<T extends IVehicleEngineFormValues> extends React.PureComponent<IProps<T>> {
  serieIdOptions: IFormAutocompleteFetchOptions<IVehicleSerieModelDTO> = {
    resource: VehicleSerieModel.listFetch,
    parseItem: (item: IVehicleSerieModelDTO): IFormAutocompleteItem<IVehicleSerieModelDTO> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  render() {
    const { disabledSerie, title, closeModal, formProps } = this.props;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={500}
        formProps={{
          ...formProps,
          schema: VehicleEngineFormSchema
        }}
      >
        <div>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <FormAutocomplete
                name="serie_id"
                formItemProps={{
                  label: <IntlMessages id="routes.settings.vehicle.engine.form.serie_id" />,
                  required: true
                }}
                selectedItem={values.serie}
                autocompleteProps={{
                  disabled: disabledSerie || false
                }}
                fetchOptions={this.serieIdOptions}
              />
            )}
          </FormSpy>
          <FormInput
            name="name"
            formItemProps={{
              label: <IntlMessages id="routes.settings.vehicle.engine.form.name" />,
              required: true
            }}
            type="text"
          />
        </div>
      </FormDrawer>
    );
  }
}

export default VehicleEngineForm;
