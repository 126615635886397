import * as Yup from 'yup';

export const WorkerFormSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    username: Yup.string().required(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string(),
    color: Yup.string().required(),
    is_active: Yup.boolean().required()
  });
