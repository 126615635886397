import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { InputProps } from 'antd/lib/input/Input';
import { FormItemProps } from 'antd/lib/form';
import Input from '@common/components/Input';
import FormItem from '@common/components/Form/FormItem';
import styles from './FormInput.module.less';

interface IProps extends InputProps {
  name: string;
  formItemProps?: FormItemProps;
  fullWidth?: boolean;
}

const FormInput = ({
  name,
  formItemProps = {},
  onChange: $onChange,
  onBlur: $onBlur,
  autoFocus,
  ...props
}: IProps): JSX.Element => {
  const [isLoaded, setLoaded] = React.useState<boolean>(false);

  const setAutoFocus = (input: any) => {
    if (!isLoaded && autoFocus && input) {
      setLoaded(true);
      setTimeout(() => {
        input.focus();
      });
    }
  };

  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => {
        return (
          <FormItem name={name} {...formItemProps} meta={meta}>
            <Input
              className={styles.Input}
              {...input}
              name={name}
              onChange={event => {
                input.onChange(event);
                $onChange && $onChange(event);
              }}
              onBlur={event => {
                input.onBlur(event);
                $onBlur && $onBlur(event);
              }}
              ref={input => autoFocus && setAutoFocus(input)}
              {...props}
            />
          </FormItem>
        );
      }}
    </Field>
  );
};

export default FormInput;
