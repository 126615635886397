import * as React from 'react';
import { Row, Col } from 'antd';
import { FormSpy } from 'react-final-form';
import { FormBox, FormSwitch } from '@common/components/Form';
import FormInput from '@common/components/Form/FormInput';
import FormColorPicker from '@common/components/Form/FormColorPicker';
import UserAvatar from '@common/components/UserAvatar';
import IntlMessages from '@common/translations';
import FormDrawer from '@common/helpers/FormDrawer';
import { IFormProps } from '@common/components/Form/Form/Form';
import { IWorkerUserFormValues } from '@common/models/WorkerUserModel';
import { WorkerFormSchema } from '@routes/Administration/Worker/components/WorkerForm/WorkerFormSchema';

interface IProps<T> {
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
  isEdited?: boolean;
  isMyProfile?: boolean;
}

const WorkerForm = <T extends IWorkerUserFormValues>({
  isEdited,
  isMyProfile,
  title,
  closeModal,
  formProps
}: IProps<T>): JSX.Element => {
  return (
    <FormDrawer<T>
      title={title}
      onClose={closeModal}
      width={500}
      formProps={{
        ...formProps,
        schema: WorkerFormSchema
      }}
    >
      <Row gutter={24}>
        <Col span={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <UserAvatar
                item={{
                  first_name: values.first_name,
                  last_name: values.last_name,
                  color: values.color
                }}
                size="large"
              />
            )}
          </FormSpy>
        </Col>
        <Col span={18}>
          <FormBox>
            <FormInput
              name="first_name"
              type="text"
              autoFocus
              formItemProps={{
                required: true,
                label: <IntlMessages id="routes.management.worker.form.first_name" />
              }}
            />

            <FormInput
              name="last_name"
              type="text"
              formItemProps={{
                required: true,
                label: <IntlMessages id="routes.management.worker.form.last_name" />
              }}
            />

            <FormInput
              name="username"
              type="text"
              formItemProps={{
                required: true,
                label: <IntlMessages id="routes.management.worker.form.username" />
              }}
            />

            <FormInput
              name="email"
              type="email"
              formItemProps={{
                label: <IntlMessages id="routes.management.worker.form.email" />
              }}
            />

            <FormColorPicker
              name="color"
              formItemProps={{
                label: <IntlMessages id="routes.management.worker.form.color" />
              }}
            />

            {!isMyProfile ? (
              <FormSwitch
                name="is_active"
                formItemProps={{
                  label: <IntlMessages id="routes.management.worker.form.is_active" />
                }}
              />
            ) : null}

            <FormSpy subscription={{ values: true }}>
              {({ values }) =>
                values.is_active ? (
                  <FormInput
                    name="password"
                    formItemProps={{
                      required: !isEdited,
                      label: <IntlMessages id="routes.management.worker.form.password" />
                    }}
                    type="password"
                  />
                ) : null
              }
            </FormSpy>
          </FormBox>
        </Col>
      </Row>
    </FormDrawer>
  );
};

export default WorkerForm;
