import * as React from 'react';
import * as _ from 'lodash';
import cn from 'classnames';
import { Select } from 'antd';
import { Field, FieldRenderProps } from 'react-final-form';
import { FormItemProps } from 'antd/lib/form';
import { OptionProps, SelectProps } from 'antd/lib/select';
import IntlMessages from '@common/translations';
import styles from './FormSelect.module.less';
import FormItem from '@common/components/Form/FormItem/FormItem';
import { SelectValue } from 'antd/es/select';

const { Option } = Select;

export interface IFormSelectItemProps extends OptionProps {
  label?: string;
  value: any;
}

interface IProps extends SelectProps {
  name: string;
  items: IFormSelectItemProps[];
  allowPrompt?: boolean;
  fullWidth?: boolean;
  onChangeValue?: (value: SelectValue) => void;
  formItemProps: FormItemProps;
  hideSelectedItems?: boolean;
}

const FormSelect = ({
  name,
  formItemProps,
  onBlur: $onBlur,
  onChange: $onChange,
  items,
  onChangeValue,
  mode,
  fullWidth = true,
  allowPrompt = true,
  hideSelectedItems = false,
  ...props
}: IProps): JSX.Element => {
  let filteredOptions = items;

  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => {
        if (hideSelectedItems && mode === 'multiple' && props.labelInValue) {
          filteredOptions = _.differenceWith(items, input.value, (x, y: any) => x.value === y.key);
        }

        return (
          <FormItem name={name} {...formItemProps} meta={meta}>
            <Select
              size="large"
              {...props}
              onChange={(value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[]) => {
                input.onChange(value);

                if (onChangeValue) {
                  onChangeValue(value);
                }

                $onChange && $onChange(value, option);
              }}
              onBlur={(value: any) => {
                input.onBlur(value);
                $onBlur && $onBlur(value);
              }}
              value={input.value}
              mode={mode}
              className={cn(styles.Select, { [styles.FullWidth]: fullWidth })}
            >
              {!formItemProps.required && allowPrompt && (
                <Option value="">
                  <em>{<IntlMessages id="common.components.form.select.none" />}</em>
                </Option>
              )}
              {filteredOptions.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        );
      }}
    </Field>
  );
};

export default FormSelect;
