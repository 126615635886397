import BaseModel, { IBaseModelDTO, IBaseModel } from '@common/models/BaseModel';
import VehicleModelModel, { IVehicleModelModelDTO, IVehicleModelModel } from '@common/models/VehicleModelModel';
import { IVehicleMakeModel } from '@common/models/VehicleMakeModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const vehicleGenerationUrl = '/vehicles/generations';

export interface IVehicleGenerationFormValues {
  readonly id?: number;
  name: string;
  model?: IVehicleModelModel;
  model_id: number | null;
  year_begin?: number | null;
  year_end?: number | null;
}

export interface IVehicleGenerationModelDTO extends IBaseModelDTO {
  name: string;
  model_id: number;
  model: IVehicleModelModelDTO;
  year_begin?: number;
  year_end?: number;
}

export interface IVehicleGenerationModel extends IBaseModel {
  name: string;
  model_id: number;
  model: IVehicleModelModel;
  year_begin?: number;
  year_end?: number;
  make?: IVehicleMakeModel;
}

export class VehicleGenerationModel extends BaseModel implements IVehicleGenerationModel {
  name: string;
  model_id: number;
  model: IVehicleModelModel;
  year_begin?: number;
  year_end?: number;
  get make(): IVehicleMakeModel | undefined {
    return this.model?.make;
  }

  constructor({ id, name, model_id, year_begin, year_end, date_created, model }: IVehicleGenerationModelDTO) {
    super({ id, date_created });

    this.name = name;
    this.model_id = model_id;
    this.model = VehicleModelModel.build(model);
    this.year_begin = year_begin;
    this.year_end = year_end;
  }

  static listFetch = (params: AxiosRequestConfig = {}) =>
    Resource({ url: vehicleGenerationUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleGenerationModel.buildFetchUrl(vehicleGenerationUrl, id), method: 'GET' });

  static createFetch = (data: IVehicleGenerationModelDTO) =>
    Resource({ url: vehicleGenerationUrl, method: 'POST', data });

  static updateFetch = (data: IVehicleGenerationModelDTO) =>
    Resource({ url: VehicleGenerationModel.buildFetchUrl(vehicleGenerationUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleGenerationModel.buildFetchUrl(vehicleGenerationUrl, id), method: 'DELETE' });
}

export default VehicleGenerationModel;
