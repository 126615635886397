import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';
import VehicleGenerationModel, {
  IVehicleGenerationModel,
  IVehicleGenerationModelDTO
} from '@common/models/VehicleGenerationModel';

const vehicleSerieUrl = '/vehicles/series';

export interface IVehicleSerieFormValues extends Omit<IVehicleSerieModelDTO, 'id' | 'generation_id' | 'generation'> {
  readonly id?: number;
  generation_id?: number | null;
  generation?: IVehicleGenerationModel;
}

export interface IVehicleSerieModelDTO extends IBaseModelDTO {
  name: string;
  generation_id: number;
  generation: IVehicleGenerationModelDTO;
}

export interface IVehicleSerieModel extends IBaseModel {
  name: string;
  generation_id: number;
  generation: IVehicleGenerationModel;
}

export class VehicleSerieModel extends BaseModel implements IVehicleSerieModel {
  name: string;
  generation_id: number;
  generation: IVehicleGenerationModel;

  constructor({ id, name, generation_id, generation, date_created }: IVehicleSerieModelDTO) {
    super({ id, date_created });

    this.name = name;
    this.generation_id = generation_id;
    this.generation = VehicleGenerationModel.build(generation);
  }

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: vehicleSerieUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleSerieModel.buildFetchUrl(vehicleSerieUrl, id), method: 'GET' });

  static createFetch = (data: IVehicleSerieModelDTO) => Resource({ url: vehicleSerieUrl, method: 'POST', data });

  static updateFetch = (data: IVehicleSerieModelDTO) =>
    Resource({ url: VehicleSerieModel.buildFetchUrl(vehicleSerieUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleSerieModel.buildFetchUrl(vehicleSerieUrl, id), method: 'DELETE' });
}

export default VehicleSerieModel;
