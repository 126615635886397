export const VEHICLE_LIST_REQUEST = 'VEHICLE_LIST_REQUEST';
export const VEHICLE_LIST_SUCCESS = 'VEHICLE_LIST_SUCCESS';
export const VEHICLE_LIST_FAILURE = 'VEHICLE_LIST_FAILURE';
export const VEHICLE_CREATE_REQUEST = 'VEHICLE_CREATE_REQUEST';
export const VEHICLE_CREATE_SUCCESS = 'VEHICLE_CREATE_SUCCESS';
export const VEHICLE_CREATE_FAILURE = 'VEHICLE_CREATE_FAILURE';
export const VEHICLE_UPDATE_REQUEST = 'VEHICLE_UPDATE_REQUEST';
export const VEHICLE_UPDATE_SUCCESS = 'VEHICLE_UPDATE_SUCCESS';
export const VEHICLE_UPDATE_FAILURE = 'VEHICLE_UPDATE_FAILURE';
export const VEHICLE_DELETE_REQUEST = 'VEHICLE_DELETE_REQUEST';
export const VEHICLE_DELETE_SUCCESS = 'VEHICLE_DELETE_SUCCESS';
export const VEHICLE_DELETE_FAILURE = 'VEHICLE_DELETE_FAILURE';
export const VEHICLE_VIEW_REQUEST = 'VEHICLE_VIEW_REQUEST';
export const VEHICLE_VIEW_SUCCESS = 'VEHICLE_VIEW_SUCCESS';
export const VEHICLE_VIEW_FAILURE = 'VEHICLE_VIEW_FAILURE';
