import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import {
  WAREHOUSE_LIST_REQUEST,
  WAREHOUSE_LIST_SUCCESS,
  WAREHOUSE_LIST_FAILURE
} from '@routes/Warehouse/redux/modules/WarehouseList/WarehouseListTypes';
import InventoryWarehouseModel from '@common/models/InventoryWarehouseModel';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const warehouseListAction = (requestData: AxiosRequestConfig) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: WAREHOUSE_LIST_REQUEST
    });

    const response = await InventoryWarehouseModel.listFetch({
      ...requestData,
      cancelToken: source.token
    });

    dispatch({
      type: WAREHOUSE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: WAREHOUSE_LIST_FAILURE
      });
    }
  }
};
