import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { warehouseCategoryCreateAction } from '@routes/Settings/Warehouse/redux/modules/WarehouseCategory/WarehouseCategoryActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import SettingsWarehouseCategoryForm from '@routes/Settings/Warehouse/WarehouseCategory/components/WarehouseCategoryForm';
import { IInventoryCategoryFormValues } from '@common/models/InventoryCategoryModel';

const WarehouseCategoryCreatePageTitle = 'routes.settings.warehouse.category.create.pageTitle';

interface IProps<T> extends IBaseFormComponentProps<T> {
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  initialValues: IInventoryCategoryFormValues;
}

class WarehouseCategoryCreate<T extends IInventoryCategoryFormValues> extends React.Component<
  IProps<T> & IInjectedProps,
  IState
> {
  state: IState = {
    initialValues: {
      name: ''
    }
  };

  onSubmit = async (values: IInventoryCategoryFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { initialValues } = this.state;

    return (
      <SettingsWarehouseCategoryForm
        title={<IntlMessages id={WarehouseCategoryCreatePageTitle} />}
        closeModal={closeModal}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: warehouseCategoryCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(WarehouseCategoryCreate);
