import * as _ from 'lodash';
import { Modal, notification } from 'antd';
import { createIntl, createIntlCache } from 'react-intl';
import { ModalFuncProps } from 'antd/lib/modal';
import { AppLocale } from '@common/translations';

const cache = createIntlCache();
const intl = createIntl(AppLocale['pl'], cache);

export const Notify = (title: string | null, text: string) => {
  notification.warning({
    message: text || intl.formatMessage({ id: 'notification.warningMessage' })
  });
};

export const ValidationNotify = (title: string | null, data: {}) => {
  if (!_.isPlainObject(data)) {
    return;
  }

  let content = '';

  _.forEach(data, (value: []) => {
    for (let i = 0, len = value.length; i < len; i += 1) {
      content += `${value[i]} \n`;
    }
  });

  notification.warning({ message: title || content });
};

interface IConfirmModal extends ModalFuncProps {}

export const ConfirmModal = ({ title, content, okText, cancelText, ...props }: IConfirmModal) => {
  Modal.confirm({
    centered: true,
    title: title || intl.formatMessage({ id: 'common.helpers.confirmModal.title' }),
    content: content || intl.formatMessage({ id: 'common.helpers.confirmModal.description' }),
    okText: okText || intl.formatMessage({ id: 'common.helpers.confirmModal.confirmButton' }),
    cancelText: cancelText || intl.formatMessage({ id: 'common.helpers.confirmModal.cancelButton' }),
    ...props
  });
};
