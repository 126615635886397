import * as React from 'react';
import FormInput from '@common/components/Form/FormInput';
import { FormBox } from '@common/components/Form/FormBox';
import FormInputMask from '@common/components/Form/FormInputMask';
import FormAutocomplete, {
  IFormAutocompleteCreateButtonComponent,
  IFormAutocompleteFetchOptions,
  IFormAutocompleteItem
} from '@common/components/Form/FormAutocomplete';
import IntlMessages from '@common/translations';
import ContractorModel, { IContractorModel } from '@common/models/ContractorModel';
import { IVehicleMakeModel, VehicleMakeModel } from '@common/models/VehicleMakeModel';
import { IVehicleModelModel, IVehicleModelModelDTO, VehicleModelModel } from '@common/models/VehicleModelModel';
import {
  IVehicleGenerationModel,
  IVehicleGenerationModelDTO,
  VehicleGenerationModel
} from '@common/models/VehicleGenerationModel';
import { IVehicleSerieModel, IVehicleSerieModelDTO, VehicleSerieModel } from '@common/models/VehicleSerieModel';
import { IVehicleEngineModel, IVehicleEngineModelDTO, VehicleEngineModel } from '@common/models/VehicleEngineModel';
import VehicleModelCreate from '@routes/Settings/Vehicle/VehicleModel/VehicleModelCreate';
import VehicleGenerationCreate from '@routes/Settings/Vehicle/VehicleGeneration/VehicleGenerationCreate';
import VehicleSerieCreate from '@routes/Settings/Vehicle/VehicleSerie/VehicleSerieCreate';
import VehicleEngineCreate from '@routes/Settings/Vehicle/VehicleEngine/VehicleEngineCreate';
import { Col, Row } from 'antd';
import FormTextArea from '@common/components/Form/FormTextArea';
import { IVehicleFormValues } from '@common/models/VehicleModel';
import { IFormProps } from '@common/components/Form/Form/Form';
import FormDrawer from '@common/helpers/FormDrawer';
import { VehicleFormSchema } from '@routes/Workshop/Vehicle/components/VehicleForm/VehicleFormSchema';

interface IProps<T> {
  disabledContractor?: boolean;
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

class VehicleForm<T extends IVehicleFormValues> extends React.PureComponent<IProps<T>> {
  contractorIdOptions: IFormAutocompleteFetchOptions<IContractorModel> = {
    resource: ContractorModel.listFetch,
    params: () => ({
      is_recipient: true
    }),
    parseItem: (item: IContractorModel): IFormAutocompleteItem<IContractorModel> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  makeIdOptions: IFormAutocompleteFetchOptions<IVehicleMakeModel> = {
    resource: VehicleMakeModel.listFetch,
    parseItem: (item: IVehicleMakeModel): IFormAutocompleteItem<IVehicleMakeModel> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  modelIdOptions: IFormAutocompleteFetchOptions<IVehicleModelModel> = {
    resource: VehicleModelModel.listFetch,
    parseItem: (item: IVehicleModelModel): IFormAutocompleteItem<IVehicleModelModel> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  generationIdOptions: IFormAutocompleteFetchOptions<IVehicleGenerationModel> = {
    resource: VehicleGenerationModel.listFetch,
    parseItem: (item: IVehicleGenerationModel): IFormAutocompleteItem<IVehicleGenerationModel> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  serieIdOptions: IFormAutocompleteFetchOptions<IVehicleSerieModel> = {
    resource: VehicleSerieModel.listFetch,
    parseItem: (item: IVehicleSerieModel): IFormAutocompleteItem<IVehicleSerieModel> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  engineIdOptions: IFormAutocompleteFetchOptions<IVehicleEngineModel> = {
    resource: VehicleEngineModel.listFetch,
    parseItem: (item: IVehicleEngineModel): IFormAutocompleteItem<IVehicleEngineModel> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  render() {
    const { title, closeModal, disabledContractor, formProps } = this.props;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={700}
        formProps={{
          ...formProps,
          schema: VehicleFormSchema,
          subscription: { values: true, initialValues: true, submitting: true, pristine: true }
        }}
      >
        {({ form, values, initialValues }) => (
          <Row gutter={24}>
            <Col span={12}>
              <FormBox title={<IntlMessages id="common.components.form.basicData" />}>
                {/*<FormAutocomplete*/}
                {/*  name="contractor_id"*/}
                {/*  selectedItem={initialValues.contractor}*/}
                {/*  formItemProps={{*/}
                {/*    required: true,*/}
                {/*    label: <IntlMessages id="routes.vehicle.form.contractor_id" />*/}
                {/*  }}*/}
                {/*  autocompleteProps={{*/}
                {/*    disabled: disabledContractor*/}
                {/*  }}*/}
                {/*  optionView={FormAutocompleteViews.contractor}*/}
                {/*  fetchOptions={this.contractorIdOptions}*/}
                {/*  AddComponent={({ onCloseAction }: IFormAutocompleteCreateButtonComponent) => (*/}
                {/*    <ContractorCreate*/}
                {/*      onCloseAction={onCloseAction}*/}
                {/*      onSuccessAction={({ response }: { response: IContractorModelDTO }) => {*/}
                {/*        form.change('contractor_id', response.id);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}

                <FormInput
                  name="reg_no"
                  formItemProps={{
                    required: true,
                    label: <IntlMessages id="routes.vehicle.form.reg_no" />
                  }}
                  type="text"
                />

                <FormInputMask
                  name="year"
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.year" />
                  }}
                  type="text"
                  mask="9999"
                />

                <FormInputMask
                  name="vin"
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.vin" />
                  }}
                  type="text"
                  mask="*****************"
                />

                <FormInput
                  name="engine_no"
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.engine_no" />
                  }}
                  type="text"
                />
              </FormBox>
            </Col>
            <Col span={12}>
              <FormBox title={<IntlMessages id="common.components.form.additionalData" />}>
                {/*<FormAutocomplete*/}
                {/*  name="make_id"*/}
                {/*  selectedItem={initialValues.make}*/}
                {/*  formItemProps={{*/}
                {/*    required: true,*/}
                {/*    label: <IntlMessages id="routes.vehicle.form.make_id" />*/}
                {/*  }}*/}
                {/*  fetchOptions={this.makeIdOptions}*/}
                {/*  autocompleteProps={{}}*/}
                {/*  onChangeValue={(item) => {*/}
                {/*    form.change('model_id', undefined);*/}
                {/*    form.change('generation_id', undefined);*/}
                {/*    form.change('serie_id', undefined);*/}
                {/*    form.change('engine_id', undefined);*/}
                {/*  }}*/}
                {/*  AddComponent={({ onCloseAction, onSuccessAction }: IFormAutocompleteCreateButtonComponent) => (*/}
                {/*    <VehicleMakeCreate*/}
                {/*      onCloseAction={onCloseAction}*/}
                {/*      onSuccessAction={({ response }: { response: IVehicleMakeModelDTO }) => {*/}
                {/*        onSuccessAction(response);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}

                <FormAutocomplete
                  name="model_id"
                  selectedItem={initialValues.model}
                  formItemProps={{
                    required: true,
                    label: <IntlMessages id="routes.vehicle.form.model_id" />
                  }}
                  fetchOptions={{
                    ...this.modelIdOptions,
                    params: () => {
                      return values.make_id ? { make_id: values.make_id } : {};
                    }
                  }}
                  autocompleteProps={{
                    disabled: !values.make_id
                  }}
                  onChangeValue={(item) => {
                    form.change('model', item);
                    form.change('generation_id', undefined);
                    form.change('serie_id', undefined);
                    form.change('engine_id', undefined);
                  }}
                  AddComponent={({ onCloseAction, onSuccessAction }: IFormAutocompleteCreateButtonComponent) => {
                    return (
                      <VehicleModelCreate
                        disabledMake
                        make={values.make}
                        onCloseAction={onCloseAction}
                        onSuccessAction={({ response }: { response: IVehicleModelModelDTO }) => {
                          onSuccessAction(response);
                        }}
                      />
                    );
                  }}
                />

                <FormAutocomplete
                  name="generation_id"
                  selectedItem={initialValues.generation}
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.generation_id" />
                  }}
                  fetchOptions={{
                    ...this.generationIdOptions,
                    params: () => {
                      return values.model_id ? { model_id: values.model_id } : {};
                    }
                  }}
                  autocompleteProps={{
                    disabled: !values.model_id
                  }}
                  onChangeValue={(item) => {
                    form.change('generation', item);
                    form.change('serie_id', undefined);
                    form.change('engine_id', undefined);
                  }}
                  AddComponent={({ onCloseAction, onSuccessAction }: IFormAutocompleteCreateButtonComponent) => (
                    <VehicleGenerationCreate
                      disabledModel
                      model={values.model}
                      onCloseAction={onCloseAction}
                      onSuccessAction={({ response }: { response: IVehicleGenerationModelDTO }) => {
                        onSuccessAction(response);
                      }}
                    />
                  )}
                />

                <FormAutocomplete
                  name="serie_id"
                  selectedItem={initialValues.serie}
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.serie_id" />
                  }}
                  fetchOptions={{
                    ...this.serieIdOptions,
                    params: () => {
                      return values.generation_id ? { generation_id: values.generation_id } : {};
                    }
                  }}
                  autocompleteProps={{
                    disabled: !values.generation_id
                  }}
                  onChangeValue={(item) => {
                    form.change('serie', item);
                    form.change('engine_id', undefined);
                  }}
                  AddComponent={({ onCloseAction, onSuccessAction }: IFormAutocompleteCreateButtonComponent) => (
                    <VehicleSerieCreate
                      disabledGeneration
                      generation={values.generation}
                      onCloseAction={onCloseAction}
                      onSuccessAction={({ response }: { response: IVehicleSerieModelDTO }) => {
                        onSuccessAction(response);
                      }}
                    />
                  )}
                />

                <FormAutocomplete
                  name="engine_id"
                  selectedItem={initialValues.engine}
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.engine_id" />
                  }}
                  fetchOptions={{
                    ...this.engineIdOptions,
                    params: () => {
                      return values.serie_id ? { serie_id: values.serie_id } : {};
                    }
                  }}
                  autocompleteProps={{
                    disabled: !values.serie_id
                  }}
                  AddComponent={({ onCloseAction, onSuccessAction }: IFormAutocompleteCreateButtonComponent) => (
                    <VehicleEngineCreate
                      disabledSerie
                      serie={values.serie}
                      onCloseAction={onCloseAction}
                      onSuccessAction={({ response }: { response: IVehicleEngineModelDTO }) => {
                        onSuccessAction(response);
                      }}
                    />
                  )}
                />

                <FormTextArea
                  name="comment"
                  formItemProps={{
                    label: <IntlMessages id="routes.vehicle.form.comment" />
                  }}
                  autoSize={{
                    minRows: 3
                  }}
                />
              </FormBox>
            </Col>
          </Row>
        )}
      </FormDrawer>
    );
  }
}

export default VehicleForm;
