import * as React from 'react';
import { ReactComponent as EllipsisV } from '@assets/fonts/ellipsis-v.svg';
import Icon from '@common/components/Icon';
import styles from './DataTableMenuButton.module.less';
import Dropdown, { IDropdownMenuItem } from '@common/components/Dropdown';

const ellipsisVIcon: React.FC = EllipsisV;

const DataTableMenuButton = ({ items }: { items: IDropdownMenuItem[] }): JSX.Element => {
  return (
    <Dropdown items={items} placement="bottomRight">
      <Icon component={ellipsisVIcon} className={styles.Icon} />
    </Dropdown>
  );
};

export default DataTableMenuButton;
