import * as React from 'react';
import { Card } from 'antd';
import cn from 'classnames';

import styles from './FormBox.module.less';

interface FormBoxProps {
  title?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  extra?: () => React.ReactElement;
  disabled?: boolean;
}

const FormBox = ({ title, children, className, extra, disabled }: FormBoxProps): JSX.Element => {
  return (
    <Card
      title={title}
      className={cn(styles.Container, className, { [styles.Disabled]: disabled })}
      extra={extra && extra()}
    >
      <div>{children}</div>
    </Card>
  );
};

export default FormBox;
