import { IModule } from 'redux-dynamic-modules';
import WarehouseListReducer, {
  IWarehouseListState
} from '@routes/Warehouse/redux/modules/WarehouseList/WarehouseListReducer';

export interface IWarehouseListModuleState {
  warehouseList: IWarehouseListState;
}

export const WarehouseListModuleId = 'warehouseList';

const WarehouseListModule: IModule<IWarehouseListModuleState> = {
  id: WarehouseListModuleId,
  reducerMap: {
    [WarehouseListModuleId]: WarehouseListReducer
  } as any
};

export { WarehouseListModule };
