import * as React from 'react';

const LoadableComponent = (importComponent: any) => {
  class AsyncFunc extends React.Component {
    _isMounted = false;

    state = {
      component: null
    };

    async componentDidMount() {
      this._isMounted = true;
      const { default: Component } = await importComponent();

      if (this._isMounted) {
        this.setState({
          component: <Component {...this.props} />
        });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const { component } = this.state;
      const Component = component || <div />;
      return <React.Fragment>{Component}</React.Fragment>;
    }
  }

  return AsyncFunc;
};

export default LoadableComponent;
