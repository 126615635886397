import * as Yup from 'yup';

export const VehicleFormSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    contractor_id: Yup.number()
      .nullable()
      .required(),
    reg_no: Yup.string().required(),
    year: Yup.string().nullable(),
    vin: Yup.string().nullable(),
    engine_no: Yup.string().nullable(),
    comment: Yup.string().nullable(),
    make_id: Yup.number().nullable().required(),
    model_id: Yup.number().nullable().required(),
    generation_id: Yup.number().nullable(),
    serie_id: Yup.number().nullable(),
    engine_id: Yup.number().nullable()
  });
