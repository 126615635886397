export const Params = {
  apiPrefix: `/api`,
  authPrefix: `/api/oauth2`,
  authClientId: 'R3sTD59IMHWvcA1wbje8CzkIJzSdA3kRVF4zgvYM',
  authClientSecret:
    '8XRPm0xZD1jbiRMxVqUmdFpZw2H0un7DMkbqgyNqOrMsqXcpSai66oar7JcKKEo8ohNtuM2rQH0Koygsk138NsyAl4DSLGJXEz7VnBXesRUsDD8tIr786NYUPhzwDkGc',

  defaultLocale: 'pl',
  defaultDateTimezone: 'Europe/Warsaw',
  defaultDateFormat: 'DD-MM-YYYY',
  defaultDateTimeFormat: 'DD-MM-YYYY HH:mm',
  defaultMonthFormat: 'M/YYYY',
  defaultDatePickerMask: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  defaultYearPickerMask: [/\d/, /\d/, /\d/, /\d/],
  defaultCurrency: 'zł',
  amountDisplayType: 'net',

  formGutter: 24
};
