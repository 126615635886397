export const VEHICLE_GENERATION_LIST_REQUEST = 'VEHICLE_GENERATION_LIST_REQUEST';
export const VEHICLE_GENERATION_LIST_SUCCESS = 'VEHICLE_GENERATION_LIST_SUCCESS';
export const VEHICLE_GENERATION_LIST_FAILURE = 'VEHICLE_GENERATION_LIST_FAILURE';
export const VEHICLE_GENERATION_CREATE_REQUEST = 'VEHICLE_GENERATION_CREATE_REQUEST';
export const VEHICLE_GENERATION_CREATE_SUCCESS = 'VEHICLE_GENERATION_CREATE_SUCCESS';
export const VEHICLE_GENERATION_CREATE_FAILURE = 'VEHICLE_GENERATION_CREATE_FAILURE';
export const VEHICLE_GENERATION_UPDATE_REQUEST = 'VEHICLE_GENERATION_UPDATE_REQUEST';
export const VEHICLE_GENERATION_UPDATE_SUCCESS = 'VEHICLE_GENERATION_UPDATE_SUCCESS';
export const VEHICLE_GENERATION_UPDATE_FAILURE = 'VEHICLE_GENERATION_UPDATE_FAILURE';
export const VEHICLE_GENERATION_DELETE_REQUEST = 'VEHICLE_GENERATION_DELETE_REQUEST';
export const VEHICLE_GENERATION_DELETE_SUCCESS = 'VEHICLE_GENERATION_DELETE_SUCCESS';
export const VEHICLE_GENERATION_DELETE_FAILURE = 'VEHICLE_GENERATION_DELETE_FAILURE';
