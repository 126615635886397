import * as React from 'react';
import { Col, Row } from 'antd';
import FormAutocomplete, { IFormAutocompleteItem } from '@common/components/Form/FormAutocomplete';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import { IVehicleModelModelDTO, VehicleModelModel } from '@common/models/VehicleModelModel';
import FormDrawer from '@common/helpers/FormDrawer';
import { IFormProps } from '@common/components/Form/Form/Form';
import { IVehicleGenerationFormValues } from '@common/models/VehicleGenerationModel';
import { VehicleGenerationFormSchema } from '@routes/Settings/Vehicle/VehicleGeneration/components/VehicleGenerationForm/VehicleGenerationFormSchema';
import { FormSpy } from 'react-final-form';

interface IProps<T> {
  disabledModel?: boolean;
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

class VehicleGenerationForm<T extends IVehicleGenerationFormValues> extends React.PureComponent<IProps<T>> {
  modelIdOptions = {
    resource: VehicleModelModel.listFetch,
    parseItem: (item: IVehicleModelModelDTO): IFormAutocompleteItem<IVehicleModelModelDTO> => ({
      ...item,
      value: item.id.toString(),
      text: item.name
    })
  };

  render() {
    const { disabledModel, title, closeModal, formProps } = this.props;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={500}
        formProps={{
          ...formProps,
          schema: VehicleGenerationFormSchema
        }}
      >
        <div>
          <Row gutter={24}>
            <Col>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <FormAutocomplete
                    name="model_id"
                    formItemProps={{
                      label: <IntlMessages id="routes.settings.vehicle.generation.form.model_id" />
                    }}
                    selectedItem={values.model}
                    autocompleteProps={{
                      disabled: disabledModel || false
                    }}
                    fetchOptions={this.modelIdOptions}
                  />
                )}
              </FormSpy>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <FormInput
                name="name"
                formItemProps={{
                  label: <IntlMessages id="routes.settings.vehicle.generation.form.name" />,
                  required: true
                }}
                type="text"
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <FormInput
                name="year_begin"
                formItemProps={{
                  label: <IntlMessages id="routes.settings.vehicle.generation.form.year_begin" />
                }}
                type="number"
              />
            </Col>
            <Col span={12}>
              <FormInput
                name="year_end"
                formItemProps={{
                  label: <IntlMessages id="routes.settings.vehicle.generation.form.year_end" />
                }}
                type="number"
              />
            </Col>
          </Row>
        </div>
      </FormDrawer>
    );
  }
}

export default VehicleGenerationForm;
