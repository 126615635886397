import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';

const apiUrl = '/core/company';

export interface ICompanyModelDTO extends IBaseModelDTO {
  company_name: string;
  country: string;
  province: string;
  district: string;
  community: string;
  street: string;
  postal_code: string;
  building_no: string;
  local_no: string;
  city: string;
  post: string;
  nip: string;
  nip_prefix: string;
  regon: string;
  email: string;
  is_vat_eu: boolean;
  is_foreigner: boolean;
  currency: string;
  tax_office: string;
  invoice_number_format: string;
  invoice_number_period: string;
  order_number_format: string;
  order_number_period: string;
  pz_number_format: string;
  pz_number_period: string;
  rw_number_format: string;
  rw_number_period: string;
  wz_number_format: string;
  wz_number_period: string;
  mm_number_format: string;
  mm_number_period: string;
}

export interface ICompanyModel extends IBaseModel {
  company_name: string;
  country: string;
  province: string;
  district: string;
  community: string;
  street: string;
  postal_code: string;
  building_no: string;
  local_no: string;
  city: string;
  post: string;
  nip: string;
  nip_prefix: string;
  regon: string;
  email: string;
  is_vat_eu: boolean;
  is_foreigner: boolean;
  currency: string;
  tax_office: string;
  invoice_number_format: string;
  invoice_number_period: string;
  order_number_format: string;
  order_number_period: string;
  pz_number_format: string;
  pz_number_period: string;
  rw_number_format: string;
  rw_number_period: string;
  wz_number_format: string;
  wz_number_period: string;
  mm_number_format: string;
  mm_number_period: string;
}

class CompanyModel extends BaseModel implements ICompanyModel {
  company_name: string;
  country: string;
  province: string;
  district: string;
  community: string;
  street: string;
  postal_code: string;
  building_no: string;
  local_no: string;
  city: string;
  post: string;
  nip: string;
  nip_prefix: string;
  regon: string;
  email: string;
  is_vat_eu: boolean;
  is_foreigner: boolean;
  currency: string;
  tax_office: string;
  invoice_number_format: string;
  invoice_number_period: string;
  order_number_format: string;
  order_number_period: string;
  pz_number_format: string;
  pz_number_period: string;
  rw_number_format: string;
  rw_number_period: string;
  wz_number_format: string;
  wz_number_period: string;
  mm_number_format: string;
  mm_number_period: string;

  constructor({
    id,
    company_name,
    country,
    province,
    district,
    community,
    street,
    postal_code,
    building_no,
    local_no,
    city,
    post,
    nip,
    nip_prefix,
    regon,
    email,
    is_vat_eu,
    is_foreigner,
    currency,
    tax_office,
    invoice_number_format,
    invoice_number_period,
    order_number_format,
    order_number_period,
    pz_number_format,
    pz_number_period,
    rw_number_format,
    rw_number_period,
    wz_number_format,
    wz_number_period,
    mm_number_format,
    mm_number_period,
    date_created
  }: ICompanyModelDTO) {
    super({ id, date_created });

    this.company_name = company_name;
    this.country = country;
    this.province = province;
    this.district = district;
    this.community = community;
    this.street = street;
    this.postal_code = postal_code;
    this.building_no = building_no;
    this.local_no = local_no;
    this.city = city;
    this.post = post;
    this.nip = nip;
    this.nip_prefix = nip_prefix;
    this.regon = regon;
    this.email = email;
    this.is_vat_eu = is_vat_eu;
    this.is_foreigner = is_foreigner;
    this.currency = currency;
    this.tax_office = tax_office;
    this.invoice_number_format = invoice_number_format;
    this.invoice_number_period = invoice_number_period;
    this.order_number_format = order_number_format;
    this.order_number_period = order_number_period;
    this.pz_number_format = pz_number_format;
    this.pz_number_period = pz_number_period;
    this.rw_number_format = rw_number_format;
    this.rw_number_period = rw_number_period;
    this.wz_number_format = wz_number_format;
    this.wz_number_period = wz_number_period;
    this.mm_number_format = mm_number_format;
    this.mm_number_period = mm_number_period;
  }

  static updateFetch = (data: ICompanyModelDTO) =>
    Resource({ url: CompanyModel.buildFetchUrl(apiUrl, data.id), method: 'PUT', data });
}

export default CompanyModel;
