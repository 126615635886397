import * as React from 'react';
import FormInput from '@common/components/Form/FormInput';
import IntlMessages from '@common/translations';
import FormTextArea from '@common/components/Form/FormTextArea';
import { IInventoryProducerFormValues } from '@common/models/InventoryProducerModel';
import FormDrawer from '@common/helpers/FormDrawer';
import { WarehouseProducerFormSchema } from '@routes/Settings/Warehouse/WarehouseProducer/components/WarehouseProducerForm/WarehouseProducerFormSchema';
import { IFormProps } from '@common/components/Form/Form/Form';

interface IProps<T> {
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}
class WarehouseProducerForm<T extends IInventoryProducerFormValues> extends React.PureComponent<IProps<T>> {
  render() {
    const { title, closeModal, formProps } = this.props;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        width={500}
        formProps={{
          ...formProps,
          schema: WarehouseProducerFormSchema
        }}
      >
        <div>
          <FormInput
            name="name"
            autoFocus
            formItemProps={{
              label: <IntlMessages id="routes.settings.warehouse.producer.form.name" />,
              required: true
            }}
          />
          <FormInput
            name="phone"
            formItemProps={{
              label: <IntlMessages id="routes.settings.warehouse.producer.form.phone" />
            }}
          />
          <FormInput
            name="website"
            formItemProps={{
              label: <IntlMessages id="routes.settings.warehouse.producer.form.website" />
            }}
          />
          <FormTextArea
            name="address"
            formItemProps={{
              label: <IntlMessages id="routes.settings.warehouse.producer.form.address" />
            }}
            autoSize={{
              minRows: 2
            }}
          />
          <FormTextArea
            name="description"
            formItemProps={{
              label: <IntlMessages id="routes.settings.warehouse.producer.form.description" />
            }}
            autoSize={{
              minRows: 6
            }}
          />
        </div>
      </FormDrawer>
    );
  }
}

export default WarehouseProducerForm;
