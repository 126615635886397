import * as React from 'react';

interface ICardBoxProps {
  heading?: string;
  className?: string;
  children: React.ReactNode;
  headerOutside?: boolean;
}

const CardBox = ({ heading, children, headerOutside, className }: ICardBoxProps): JSX.Element => (
  <React.Fragment>
    {headerOutside && (
      <div className={className}>
        <h3>{heading}</h3>
      </div>
    )}

    <React.Fragment>
      {!headerOutside &&
        (heading && (
          <div className={className}>
            <h3>{heading}</h3>
          </div>
        ))}
      <React.Fragment>{children}</React.Fragment>
    </React.Fragment>
  </React.Fragment>
);

export default CardBox;
