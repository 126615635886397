import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import VehicleModel, { IVehicleModelDTO } from '@common/models/VehicleModel';
import {
  VEHICLE_CREATE_FAILURE,
  VEHICLE_CREATE_REQUEST,
  VEHICLE_CREATE_SUCCESS,
  VEHICLE_DELETE_FAILURE,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_DELETE_SUCCESS,
  VEHICLE_LIST_FAILURE,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
  VEHICLE_UPDATE_FAILURE,
  VEHICLE_UPDATE_REQUEST,
  VEHICLE_UPDATE_SUCCESS,
  VEHICLE_VIEW_FAILURE,
  VEHICLE_VIEW_REQUEST,
  VEHICLE_VIEW_SUCCESS
} from '@routes/Workshop/Vehicle/redux/modules/Vehicle/VehicleTypes';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const vehicleListAction = (params = {}) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: VEHICLE_LIST_REQUEST
    });

    const response = await VehicleModel.listFetch({
      ...params,
      cancelToken: source.token
    });

    dispatch({
      type: VEHICLE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: VEHICLE_LIST_FAILURE
      });
    }
  }
};

export const vehicleViewAction = ({ id, reset = false }: { id: number; reset: boolean }) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({
      type: VEHICLE_VIEW_REQUEST
    });

    if (reset) {
      return dispatch({
        type: VEHICLE_VIEW_SUCCESS,
        payload: null
      });
    }

    const response = await VehicleModel.viewFetch({ id });

    dispatch({
      type: VEHICLE_VIEW_SUCCESS,
      payload: response.data
    });
  } catch (err) {
    dispatch({
      type: VEHICLE_VIEW_FAILURE
    });

    throw err.response.data;
  }

  return null;
};

export const vehicleCreateAction = (data: IVehicleModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_CREATE_REQUEST
    });

    const response = await VehicleModel.createFetch(data);

    dispatch({
      type: VEHICLE_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleUpdateLoadDataAction = ({ id }: { id: number }) => () => VehicleModel.viewFetch({ id });

export const vehicleUpdateAction = (data: IVehicleModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_UPDATE_REQUEST
    });

    const response = await VehicleModel.updateFetch(data);

    dispatch({
      type: VEHICLE_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: VEHICLE_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const vehicleDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: VEHICLE_DELETE_REQUEST
    });

    const response = await VehicleModel.deleteFetch({ id });

    dispatch({
      type: VEHICLE_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_DELETE_FAILURE
    });
  }
};
