export const WAREHOUSE_PRODUCER_LIST_REQUEST = 'WAREHOUSE_PRODUCER_LIST_REQUEST';
export const WAREHOUSE_PRODUCER_LIST_SUCCESS = 'WAREHOUSE_PRODUCER_LIST_SUCCESS';
export const WAREHOUSE_PRODUCER_LIST_FAILURE = 'WAREHOUSE_PRODUCER_LIST_FAILURE';
export const WAREHOUSE_PRODUCER_CREATE_REQUEST = 'WAREHOUSE_PRODUCER_CREATE_REQUEST';
export const WAREHOUSE_PRODUCER_CREATE_SUCCESS = 'WAREHOUSE_PRODUCER_CREATE_SUCCESS';
export const WAREHOUSE_PRODUCER_CREATE_FAILURE = 'WAREHOUSE_PRODUCER_CREATE_FAILURE';
export const WAREHOUSE_PRODUCER_UPDATE_REQUEST = 'WAREHOUSE_PRODUCER_UPDATE_REQUEST';
export const WAREHOUSE_PRODUCER_UPDATE_SUCCESS = 'WAREHOUSE_PRODUCER_UPDATE_SUCCESS';
export const WAREHOUSE_PRODUCER_UPDATE_FAILURE = 'WAREHOUSE_PRODUCER_UPDATE_FAILURE';
export const WAREHOUSE_PRODUCER_DELETE_REQUEST = 'WAREHOUSE_PRODUCER_DELETE_REQUEST';
export const WAREHOUSE_PRODUCER_DELETE_SUCCESS = 'WAREHOUSE_PRODUCER_DELETE_SUCCESS';
export const WAREHOUSE_PRODUCER_DELETE_FAILURE = 'WAREHOUSE_PRODUCER_DELETE_FAILURE';
