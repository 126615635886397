import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import IntlMessages from '@common/translations';
import DataTable, {
  DataTableColumnSortOrders,
  IDataTableColumn,
  IDataTableCreateButtonComponent,
  IDataTableMenuItem
} from '@common/components/DataTable';
import CardBox from '@common/containers/CardBox';
import {
  warehouseListDeleteAction,
  warehouseListListAction
} from '@routes/Settings/Warehouse/redux/modules/WarehouseList/WarehouseListActions';
import { ConfirmModal, getDate } from '@common/helpers';
import WarehouseListCreate from '@routes/Settings/Warehouse/WarehouseList/WarehouseListCreate';
import WarehouseListUpdate from '@routes/Settings/Warehouse/WarehouseList/WarehouseListUpdate';
import { IHeadersState } from '@common/interfaces';
import { IInventoryWarehouseModel } from '@common/models/InventoryWarehouseModel';
import { IWarehouseListModuleState } from '@routes/Settings/Warehouse/redux/modules/WarehouseList';
import { Tag } from 'antd';
import PageHeading from '@common/containers/PageHeading';

export const WarehouseListListPageTitle = 'routes.settings.warehouse.list.list.pageTitle';

interface IProps {
  listAction: Function;
  deleteAction: Function;
  data: IInventoryWarehouseModel[];
  headers: IHeadersState;
  isLoaded: boolean;
  isLoading: boolean;
  intl: IntlShape;
}

interface IState {
  showUpdateModal: boolean;
  updateModalId: number | null;
  updateModalOnCloseAction: Function;
  updateModalOnSuccessAction: Function;
  confirmModalSuccessAction: Function;
  openConfirmModal: boolean;
  confirmModalTitle: string;
}

class WarehouseListList extends React.Component<IProps, IState> {
  state = {
    showUpdateModal: false,
    updateModalId: null,
    updateModalOnCloseAction: () => {},
    updateModalOnSuccessAction: () => {},
    openConfirmModal: false,
    confirmModalSuccessAction: () => {},
    confirmModalTitle: ''
  };

  tableColumns: IDataTableColumn<IInventoryWarehouseModel>[] = [
    { dataIndex: 'name', width: 300, title: <IntlMessages id="routes.settings.warehouse.list.form.name" /> },
    { dataIndex: 'code', width: 150, title: <IntlMessages id="routes.settings.warehouse.list.form.code" /> },
    { dataIndex: 'address', title: <IntlMessages id="routes.settings.warehouse.list.form.address" /> },
    {
      dataIndex: 'is_default',
      align: 'right',
      title: '',
      width: 150,
      render: value =>
        value ? (
          <Tag color="blue">
            <IntlMessages id="routes.settings.warehouse.list.form.is_default" />
          </Tag>
        ) : null
    },
    {
      dataIndex: 'date_created',
      width: 180,
      align: 'right',
      title: <IntlMessages id="date_created" />,
      render: value => getDate({ date: value })
    }
  ];

  tableItemActions: IDataTableMenuItem<IInventoryWarehouseModel>[] = [
    {
      key: 'update',
      onPress: (item, fetchData) => {
        this.setState({
          showUpdateModal: true,
          updateModalId: item.id,
          updateModalOnCloseAction: () => {
            this.setState({
              showUpdateModal: false,
              updateModalId: null
            });
          },
          updateModalOnSuccessAction: async () => {
            fetchData();
          }
        });
      }
    },
    {
      key: 'delete',
      onPress: (item, fetchData) => {
        const { deleteAction } = this.props;

        ConfirmModal({
          title: item.name,
          onOk: async () => {
            await deleteAction({ id: item.id });
            fetchData();
          }
        });
      }
    }
  ];

  tableCreateButton = {
    component: ({ fetchData, onCloseAction }: IDataTableCreateButtonComponent) => (
      <WarehouseListCreate
        onCloseAction={onCloseAction}
        onSuccessAction={() => {
          fetchData({ reset: true });
        }}
      />
    )
  };

  render() {
    const { listAction, data, headers, isLoaded, isLoading, intl } = this.props;
    const { updateModalId, updateModalOnCloseAction, updateModalOnSuccessAction, showUpdateModal } = this.state;

    return (
      <div>
        <PageHeading
          title={intl.formatMessage({ id: 'routes.settings.warehouse.pageTitle' })}
          subTitle={intl.formatMessage({ id: WarehouseListListPageTitle })}
        />

        <CardBox>
          <DataTable<IInventoryWarehouseModel>
            data={data}
            headers={headers}
            isLoading={isLoading}
            isLoaded={isLoaded}
            columns={this.tableColumns}
            itemActions={this.tableItemActions}
            createButton={this.tableCreateButton}
            fetch={{ resource: listAction, order: DataTableColumnSortOrders.desc, orderBy: 'date_created' }}
          />
        </CardBox>

        {showUpdateModal && (
          <WarehouseListUpdate
            id={updateModalId}
            onCloseAction={updateModalOnCloseAction}
            onSuccessAction={updateModalOnSuccessAction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ warehouseList }: IWarehouseListModuleState) => ({
  isLoaded: warehouseList.list.isLoaded,
  isLoading: warehouseList.list.isLoading,
  data: warehouseList.list.data,
  headers: warehouseList.list.headers
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      listAction: warehouseListListAction,
      deleteAction: warehouseListDeleteAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(WarehouseListList) as React.ComponentType;
