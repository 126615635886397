import * as React from 'react';
import cn from 'classnames';
import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';

import styles from './UserAvatar.module.less';

interface IUserAvatarProps extends AvatarProps {
  item: {
    first_name: string;
    last_name: string;
    color: string;
  };
  className?: string;
}

const UserAvatar = ({ item, size = 'small', ...props }: IUserAvatarProps) => {
  const { first_name, last_name, color } = item;

  if (!first_name && !last_name) {
    return (
      <Avatar
        className={cn(styles.Avatar, { [styles.Large]: size === 'large' })}
        icon="user"
        style={{ backgroundColor: color }}
        size={size}
        {...props}
      />
    );
  }

  return (
    <Avatar
      className={cn(styles.Avatar, { [styles.Large]: size === 'large' })}
      size={size}
      style={{ backgroundColor: color }}
      {...props}
    >
      {`${first_name ? first_name.charAt(0) : ''}${last_name ? last_name.charAt(0) : ''}`.toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;
