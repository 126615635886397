export const VEHICLE_SERIE_LIST_REQUEST = 'VEHICLE_SERIE_LIST_REQUEST';
export const VEHICLE_SERIE_LIST_SUCCESS = 'VEHICLE_SERIE_LIST_SUCCESS';
export const VEHICLE_SERIE_LIST_FAILURE = 'VEHICLE_SERIE_LIST_FAILURE';
export const VEHICLE_SERIE_CREATE_REQUEST = 'VEHICLE_SERIE_CREATE_REQUEST';
export const VEHICLE_SERIE_CREATE_SUCCESS = 'VEHICLE_SERIE_CREATE_SUCCESS';
export const VEHICLE_SERIE_CREATE_FAILURE = 'VEHICLE_SERIE_CREATE_FAILURE';
export const VEHICLE_SERIE_UPDATE_REQUEST = 'VEHICLE_SERIE_UPDATE_REQUEST';
export const VEHICLE_SERIE_UPDATE_SUCCESS = 'VEHICLE_SERIE_UPDATE_SUCCESS';
export const VEHICLE_SERIE_UPDATE_FAILURE = 'VEHICLE_SERIE_UPDATE_FAILURE';
export const VEHICLE_SERIE_DELETE_REQUEST = 'VEHICLE_SERIE_DELETE_REQUEST';
export const VEHICLE_SERIE_DELETE_SUCCESS = 'VEHICLE_SERIE_DELETE_SUCCESS';
export const VEHICLE_SERIE_DELETE_FAILURE = 'VEHICLE_SERIE_DELETE_FAILURE';
