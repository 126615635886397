import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { FormItemProps } from 'antd/lib/form';
import TextArea from '@common/components/TextArea';
import FormItem from '@common/components/Form/FormItem';
import styles from './FormTextArea.module.less';

interface IProps extends TextAreaProps {
  name: string;
  formItemProps: FormItemProps;
}

const FormTextArea = ({ name, formItemProps, onChange: $onChange, onBlur: $onBlur, ...props }: IProps): JSX.Element => {
  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => (
        <FormItem name={name} {...formItemProps} meta={meta}>
          <TextArea
            className={styles.TextArea}
            autoSize={{
              minRows: 1
            }}
            {...props}
            {...input}
            name={name}
            onChange={event => {
              input.onChange(event);
              $onChange && $onChange(event);
            }}
            onBlur={event => {
              input.onBlur(event);
              $onBlur && $onBlur(event);
            }}
          />
        </FormItem>
      )}
    </Field>
  );
};

export default FormTextArea;
