import * as React from 'react';
import { Col, Row } from 'antd';
import { FormSwitch } from '@common/components/Form/FormSwitch';
import { FormBox } from '@common/components/Form/FormBox';
import FormInputMask from '@common/components/Form/FormInputMask';
import FormSelect from '@common/components/Form/FormSelect';
import FormInput from '@common/components/Form/FormInput';
import Button from '@common/components/Button';
import IntlMessages from '@common/translations';
import { IContactType, ICountry, IVatCountry } from '@common/interfaces';
import FormTextArea from '@common/components/Form/FormTextArea';
import FormContactBox from '@common/components/Form/FormContactBox';
import { checkVatEu } from '@common/helpers/Others';
import styles from './ContractorForm.module.less';
import FormDrawer from '@common/helpers/FormDrawer';
import { ContractorFormSchema } from '@routes/Management/Contractor/components/ContractorForm/ContractorFormSchema';
import arrayMutators from 'final-form-arrays';
import { IFormProps } from '@common/components/Form/Form/Form';
import { IContractorFormValues } from '@common/models/ContractorModel';

interface IProps<T> {
  gusSearchAction: Function;
  countries: ICountry[];
  vatCountries: IVatCountry[];
  contactKinds: IContactType[];
  title: string | JSX.Element;
  closeModal: Function;
  formProps: IFormProps<T>;
}

interface IState {
  gusSearchIsLoading: boolean;
}

class ContractorForm<T extends IContractorFormValues> extends React.PureComponent<IProps<T>, IState> {
  state = {
    gusSearchIsLoading: false
  };

  render() {
    const { vatCountries, countries, formProps, closeModal, title, contactKinds } = this.props;
    const { gusSearchIsLoading } = this.state;

    return (
      <FormDrawer<T>
        title={title}
        onClose={closeModal}
        formProps={{
          schema: ContractorFormSchema,
          subscription: { submitting: true, pristine: true, values: true },
          mutators: {
            ...arrayMutators
          },
          ...formProps
        }}
        width={1300}
      >
        {({ values, form }) => {
          const { is_company, is_vat_eu, nip, nip_prefix } = values;

          const handleCheckVatEu = () => {
            checkVatEu({ nip, nip_prefix });
          };

          const downloadDataFromGus = async () => {
            const { gusSearchAction } = this.props;

            if (!nip) {
              return;
            }

            try {
              await this.setState({
                gusSearchIsLoading: true
              });

              const response = await gusSearchAction({ nip });

              const { company_name, street, postal_code, city, building_no, local_no } = response.data;

              form.change('company_name', company_name);
              form.change('street', street);
              form.change('postal_code', postal_code);
              form.change('city', city);
              form.change('building_no', building_no);
              form.change('local_no', local_no);

              this.setState({
                gusSearchIsLoading: false
              });
            } catch (e) {
              this.setState({
                gusSearchIsLoading: false
              });
            }
          };

          return (
            <Row gutter={24}>
              <Col span={7}>
                <FormBox
                  disabled={!is_company}
                  title={<IntlMessages id="common.components.form.companyData" />}
                  extra={() => (
                    <FormSwitch
                      formItemProps={{
                        label: null
                      }}
                      name="is_company"
                      size="small"
                    />
                  )}
                >
                  <FormTextArea
                    name="company_name"
                    formItemProps={{
                      required: is_company,
                      label: <IntlMessages id="routes.crm.contractor.form.company_name" />
                    }}
                    autoSize={{
                      minRows: 2,
                      maxRows: 2
                    }}
                    autoFocus={is_company}
                    disabled={!is_company}
                  />

                  <FormSwitch
                    formItemProps={{
                      label: <IntlMessages id="common.helpers.formModal.isVatUeButton" />
                    }}
                    name="is_vat_eu"
                    disabled={!is_company}
                  />
                  <Row gutter={24}>
                    {is_vat_eu && (
                      <Col span={12}>
                        <FormSelect
                          formItemProps={{
                            label: <IntlMessages id="routes.crm.contractor.form.nip_prefix" />
                          }}
                          name="nip_prefix"
                          items={vatCountries}
                          allowPrompt={false}
                          disabled={!is_company}
                        />
                      </Col>
                    )}
                    <Col span={12}>
                      <FormInput
                        name="nip"
                        formItemProps={{
                          required: is_company,
                          label: <IntlMessages id="routes.crm.contractor.form.nip" />
                        }}
                        disabled={!is_company}
                      />
                    </Col>
                    {!is_vat_eu && (
                      <Col span={12}>
                        <Button
                          className={styles.GusSearchButton}
                          size="small"
                          disabled={!is_company || !nip || nip.length < 5}
                          onClick={downloadDataFromGus}
                          loading={gusSearchIsLoading}
                        >
                          <IntlMessages id="common.helpers.formModal.gusButton" />
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {is_vat_eu && (
                    <Row type="flex" justify="space-between">
                      <Col>
                        <Button
                          size="small"
                          disabled={!is_company || !nip || nip.length < 5}
                          onClick={handleCheckVatEu}
                        >
                          <IntlMessages id="common.helpers.formModal.verifyUeButton" />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          size="small"
                          disabled={!is_company || !nip || nip.length < 5}
                          onClick={downloadDataFromGus}
                          loading={gusSearchIsLoading}
                        >
                          <IntlMessages id="common.helpers.formModal.gusButton" />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </FormBox>
                <FormBox title={<IntlMessages id="common.components.form.settings" />}>
                  <Row>
                    <Col span={12}>
                      <FormSwitch
                        name="is_recipient"
                        formItemProps={{
                          label: <IntlMessages id="routes.crm.contractor.form.is_recipient" />
                        }}
                        onChange={value => {
                          if (!value && !values.is_supplier) {
                            form.change('is_supplier', true);
                          }
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <FormSwitch
                        name="is_supplier"
                        formItemProps={{
                          label: <IntlMessages id="routes.crm.contractor.form.is_supplier" />
                        }}
                        onChange={value => {
                          if (!value && !values.is_recipient) {
                            form.change('is_recipient', true);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </FormBox>
              </Col>
              <Col span={8}>
                <FormBox title={<IntlMessages id="common.components.form.basicData" />}>
                  <FormInput
                    name="first_name"
                    formItemProps={{
                      required: !is_company,
                      label: <IntlMessages id="routes.crm.contractor.form.first_name" />
                    }}
                    type="text"
                    autoFocus={!is_company}
                  />

                  <FormInput
                    name="last_name"
                    formItemProps={{
                      required: !is_company,
                      label: <IntlMessages id="routes.crm.contractor.form.last_name" />
                    }}
                    type="text"
                  />

                  <FormInput
                    name="bank_account"
                    formItemProps={{
                      label: <IntlMessages id="routes.crm.contractor.form.bank_account" />
                    }}
                    type="text"
                  />
                </FormBox>
                <FormContactBox name="contacts" kinds={contactKinds} />
              </Col>
              <Col span={9}>
                <FormBox title={<IntlMessages id="common.components.form.addressData" />}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <FormInput
                        name="street"
                        formItemProps={{
                          label: <IntlMessages id="routes.crm.contractor.form.street" />
                        }}
                        type="text"
                      />
                    </Col>
                    <Col span={6}>
                      <FormInput
                        name="building_no"
                        formItemProps={{
                          label: <IntlMessages id="routes.crm.contractor.form.building_no" />
                        }}
                        type="text"
                      />
                    </Col>
                    <Col span={6}>
                      <FormInput
                        name="local_no"
                        formItemProps={{
                          label: <IntlMessages id="routes.crm.contractor.form.local_no" />
                        }}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={9}>
                      <FormInputMask
                        name="postal_code"
                        formItemProps={{
                          required: is_company === true,
                          label: <IntlMessages id="routes.crm.contractor.form.postal_code" />
                        }}
                        type="text"
                        mask="99-999"
                      />
                    </Col>
                    <Col span={15}>
                      <FormInput
                        name="city"
                        formItemProps={{
                          required: is_company === true,
                          label: <IntlMessages id="routes.crm.contractor.form.city" />
                        }}
                        type="text"
                      />
                    </Col>

                    <Col span={12}>
                      <FormSelect
                        formItemProps={{
                          required: true,
                          label: <IntlMessages id="routes.crm.contractor.form.country" />
                        }}
                        name="country"
                        items={countries}
                        allowPrompt={false}
                      />
                    </Col>
                  </Row>
                </FormBox>
                <FormBox title={<IntlMessages id="common.components.form.descriptiveData" />}>
                  <FormTextArea
                    name="note"
                    formItemProps={{
                      label: <IntlMessages id="routes.crm.contractor.form.note" />
                    }}
                    autoSize={{
                      minRows: 4
                    }}
                  />
                </FormBox>
              </Col>
            </Row>
          );
        }}
      </FormDrawer>
    );
  }
}

export default ContractorForm;
