import * as React from 'react';
import { compose } from 'redux';
import { Layout } from 'antd';
import { injectIntl, IntlShape } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import CustomScrollbars from '@common/helpers/CustomScrollbars';
import NavigationMenu from '@routes/Settings/components/SettingsNavigation/SettingsNavigationMenu';
import Icon from '@common/components/Icon';
import styles from './SettingsNavigation.module.less';
import { Paths } from '@common/constants';
import UserInfo from '@common/containers/UserInfo/UserInfo';

interface IProps {
  navigationCollapsed: boolean;
  toggleNavigation: () => void;
  intl: IntlShape;
}

class SettingsNavigation extends React.PureComponent<IProps & RouteComponentProps> {
  _handleToggle = (): void => {};

  render() {
    const { location, intl, navigationCollapsed } = this.props;

    return (
      <Layout.Sider
        className={styles.Container}
        trigger={null}
        collapsible
        width={230}
        collapsed={navigationCollapsed}
        collapsedWidth={62}
      >
        <div className={styles.BackButton}>
          <div className={styles.Link}>
            <Link to={Paths.WorkshopOrder}>
              <Icon className={styles.BackButtonIcon} type="left-circle" />
              {intl.formatMessage({ id: 'routes.settings.pageTitle' })}
            </Link>
          </div>
        </div>
        <CustomScrollbars>
          <NavigationMenu collapsed={navigationCollapsed} intl={intl} pathname={location.pathname} />
        </CustomScrollbars>
        <div className={styles.Footer}>
          <UserInfo />
        </div>
      </Layout.Sider>
    );
  }
}

export default compose(
  withRouter,
  injectIntl
)(SettingsNavigation) as React.ComponentType;
