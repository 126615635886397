import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Paths } from '@common/constants';

interface IPrivateRouteProps {
  component: React.ComponentType;
  isAuthenticated: boolean;
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: IPrivateRouteProps & RouteProps) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: Paths.AuthLogin, state: { from: props.location } }} />
      )
    }
  />
);

export default PrivateRoute;
