import * as React from 'react';
import * as Yup from 'yup';
import { setIn } from 'final-form';
import IntlMessages from '@common/translations';

export const required = (value: string) =>
  value || typeof value === 'number' ? undefined : <IntlMessages id="common.helpers.formValidator.required" />;

export const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min: number) => (value: string) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value: number) => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);

export const minValue = (min: number) => (value: number) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (
    <IntlMessages id="common.helpers.formValidator.email" />
  ) : (
    undefined
  );

export const tooYoung = (value: number) =>
  value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined;

export const aol = (value: string) =>
  value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?' : undefined;

export const alphaNumeric = (value: string) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const phoneNumber = (value: string) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Invalid phone number, must be 10 digits' : undefined;

export const validateNip = (value?: string) => {
  if (!value) {
    return false;
  }

  const nipWithoutDashes = value.replace(/-/g, '');
  const reg = /^[0-9]{10}$/;

  if (reg.test(nipWithoutDashes) === false) {
    return false;
  }

  const dig = nipWithoutDashes.split('');
  const control =
    (6 * parseInt(dig[0], 10) +
      5 * parseInt(dig[1], 10) +
      7 * parseInt(dig[2], 10) +
      2 * parseInt(dig[3], 10) +
      3 * parseInt(dig[4], 10) +
      4 * parseInt(dig[5], 10) +
      5 * parseInt(dig[6], 10) +
      6 * parseInt(dig[7], 10) +
      7 * parseInt(dig[8], 10)) %
    11;

  if (parseInt(dig[9], 10) === control) {
    return true;
  }

  return false;
};

export const regon = (value: string) => {
  const reg = /^[0-9]{9}$/;

  if (reg.test(value) === false) {
    return 'Invalid regon';
  }

  const dig = value.split('');
  let control =
    (8 * parseInt(dig[0], 10) +
      9 * parseInt(dig[1], 10) +
      2 * parseInt(dig[2], 10) +
      3 * parseInt(dig[3], 10) +
      4 * parseInt(dig[4], 10) +
      5 * parseInt(dig[5], 10) +
      6 * parseInt(dig[6], 10) +
      7 * parseInt(dig[7], 10)) %
    11;

  if (control === 10) {
    control = 0;
  }

  if (parseInt(dig[8], 10) === control) {
    return undefined;
  }

  return 'Invalid regon';
};

export const validate = async <T extends object>(values: T, schema: () => Yup.ObjectSchema) => {
  try {
    await schema().validate(values, { abortEarly: false });
  } catch (err) {
    return err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
  }
};
