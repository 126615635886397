import * as React from 'react';
import { Col, Row } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import { FormBox } from '@common/components/Form/FormBox';
import FormInput from '@common/components/Form/FormInput';
import FormSelect from '@common/components/Form/FormSelect';
import Icon from '@common/components/Icon';
import { ReactComponent as PlusSvg } from '@assets/fonts/plus.svg';
import { IContactType } from '@common/interfaces';
import styles from './FormContactBox.module.less';
import { injectIntl, IntlShape } from 'react-intl';

const plusIcon: React.FC = PlusSvg;

interface IProps {
  kinds?: IContactType[];
  intl: IntlShape;
  name: string;
}

export interface IFormContactBoxField {
  kind: string;
  value: string;
}

const FormContactBox = ({ intl, name, kinds }: IProps): JSX.Element => {
  return (
    <FieldArray name={name} subscription={{}}>
      {({ fields }) => {
        return (
          <FormBox
            title={intl.formatMessage({ id: 'common.components.form.contactData' })}
            extra={() => (
              <Icon
                component={plusIcon}
                onClick={() => fields.push({ kind: '', value: '' })}
                className={styles.PlusIcon}
              />
            )}
          >
            {fields.map((fieldName: string, index: number) => {
              return (
                <Row gutter={24} key={index} align="middle" justify="center">
                  <Col span={8}>
                    <FormSelect
                      name={`${fieldName}.kind`}
                      formItemProps={{
                        required: true
                      }}
                      allowPrompt={false}
                      items={kinds || []}
                      placeholder={intl.formatMessage({ id: 'common.components.formContactBox.kind' })}
                    />
                  </Col>
                  <Col span={13}>
                    <FormInput
                      name={`${fieldName}.value`}
                      formItemProps={{
                        required: true
                      }}
                      placeholder={intl.formatMessage({ id: 'common.components.formContactBox.value' })}
                    />
                  </Col>
                  <Col span={3}>
                    <Icon type="delete" onClick={() => fields.remove(index)} className={styles.DeleteIcon} />
                  </Col>
                </Row>
              );
            })}
          </FormBox>
        );
      }}
    </FieldArray>
  );
};

export default injectIntl(FormContactBox);
