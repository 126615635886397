import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { vehicleEngineCreateAction } from '@routes/Settings/Vehicle/redux/modules/VehicleEngine/VehicleEngineActions';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import IntlMessages from '@common/translations';
import VehicleEngineForm from '@routes/Settings/Vehicle/VehicleEngine/components/VehicleEngineForm';
import { IVehicleSerieModelDTO } from '@common/models/VehicleSerieModel';
import { IVehicleEngineFormValues } from '@common/models/VehicleEngineModel';

const VehicleEngineCreatePageTitle = 'routes.settings.vehicle.engine.create.pageTitle';

interface IProps extends IBaseFormComponentProps<IVehicleEngineFormValues> {
  isLoading: boolean;
  serie: IVehicleSerieModelDTO;
  saveAction: Function;
  disabledSerie?: boolean;
  intl: IntlShape;
}

interface IState {
  initialValues: IVehicleEngineFormValues;
}

class VehicleEngineCreate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    initialValues: {
      name: '',
      serie_id: this.props.serie.id || null,
      serie: this.props.serie
    }
  };

  onSubmit = async (values: IVehicleEngineFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(values, { saveAction });
  };

  render() {
    const { closeModal, disabledSerie } = this.props;
    const { initialValues } = this.state;

    return (
      <VehicleEngineForm
        title={<IntlMessages id={VehicleEngineCreatePageTitle} />}
        disabledSerie={disabledSerie || false}
        closeModal={closeModal}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: vehicleEngineCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps),
  baseFormComponent
)(VehicleEngineCreate);
