import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { plPL } from './config';
import { ITranslationConfig } from '@common/translations/config/types';

const AppLocale: { [key: string]: ITranslationConfig } = {
  pl: plPL
};

export { AppLocale };

const InjectMassage = (props: any) => <FormattedMessage {...props} />;

export default injectIntl(InjectMassage, {
  forwardRef: false
});
