export const SYSTEM_FISCAL_PRINTER_LIST_REQUEST = 'SYSTEM_FISCAL_PRINTER_LIST_REQUEST';
export const SYSTEM_FISCAL_PRINTER_LIST_SUCCESS = 'SYSTEM_FISCAL_PRINTER_LIST_SUCCESS';
export const SYSTEM_FISCAL_PRINTER_LIST_FAILURE = 'SYSTEM_FISCAL_PRINTER_LIST_FAILURE';
export const SYSTEM_FISCAL_PRINTER_CREATE_REQUEST = 'SYSTEM_FISCAL_PRINTER_CREATE_REQUEST';
export const SYSTEM_FISCAL_PRINTER_CREATE_SUCCESS = 'SYSTEM_FISCAL_PRINTER_CREATE_SUCCESS';
export const SYSTEM_FISCAL_PRINTER_CREATE_FAILURE = 'SYSTEM_FISCAL_PRINTER_CREATE_FAILURE';
export const SYSTEM_FISCAL_PRINTER_UPDATE_LOAD_DATA_REQUEST = 'SYSTEM_FISCAL_PRINTER_UPDATE_LOAD_DATA_REQUEST';
export const SYSTEM_FISCAL_PRINTER_UPDATE_LOAD_DATA_SUCCESS = 'SYSTEM_FISCAL_PRINTER_UPDATE_LOAD_DATA_SUCCESS';
export const SYSTEM_FISCAL_PRINTER_UPDATE_LOAD_DATA_FAILURE = 'SYSTEM_FISCAL_PRINTER_UPDATE_LOAD_DATA_FAILURE';
export const SYSTEM_FISCAL_PRINTER_UPDATE_REQUEST = 'SYSTEM_FISCAL_PRINTER_UPDATE_REQUEST';
export const SYSTEM_FISCAL_PRINTER_UPDATE_SUCCESS = 'SYSTEM_FISCAL_PRINTER_UPDATE_SUCCESS';
export const SYSTEM_FISCAL_PRINTER_UPDATE_FAILURE = 'SYSTEM_FISCAL_PRINTER_UPDATE_FAILURE';
export const SYSTEM_FISCAL_PRINTER_DELETE_REQUEST = 'SYSTEM_FISCAL_PRINTER_DELETE_REQUEST';
export const SYSTEM_FISCAL_PRINTER_DELETE_SUCCESS = 'SYSTEM_FISCAL_PRINTER_DELETE_SUCCESS';
export const SYSTEM_FISCAL_PRINTER_DELETE_FAILURE = 'SYSTEM_FISCAL_PRINTER_DELETE_FAILURE';
