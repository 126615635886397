import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const inventoryWarehouseUrl = '/inventory/warehouses';

export interface IInventoryWarehouseFormValues {
  name: string;
  code?: string;
  description?: string | null;
  address?: string | null;
  is_default?: boolean;
}

export interface IInventoryWarehouseModelDTO extends IBaseModelDTO {
  code?: string;
  name: string;
  description?: string;
  address?: string;
  is_default?: boolean;
}

export interface IInventoryWarehouseModel extends IBaseModel {
  code?: string;
  name: string;
  description?: string;
  address?: string;
  is_default?: boolean;
}

export class InventoryWarehouseModel extends BaseModel implements IInventoryWarehouseModel {
  code?: string;
  name: string;
  description?: string;
  address?: string;
  is_default?: boolean;

  constructor({ id, code, name, description, address, is_default, date_created }: IInventoryWarehouseModelDTO) {
    super({ id, date_created });

    this.code = code;
    this.name = name;
    this.description = description;
    this.address = address;
    this.is_default = is_default;
  }

  static listFetch = (params: AxiosRequestConfig = {}) =>
    Resource({ url: inventoryWarehouseUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: InventoryWarehouseModel.buildFetchUrl(inventoryWarehouseUrl, id), method: 'GET' });

  static createFetch = (data: IInventoryWarehouseModelDTO) =>
    Resource({ url: inventoryWarehouseUrl, method: 'POST', data });

  static updateFetch = (data: IInventoryWarehouseModelDTO) =>
    Resource({ url: InventoryWarehouseModel.buildFetchUrl(inventoryWarehouseUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: InventoryWarehouseModel.buildFetchUrl(inventoryWarehouseUrl, id), method: 'DELETE' });
}

export default InventoryWarehouseModel;
