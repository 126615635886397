import * as React from 'react';
import FormSearchInput from '@common/components/SearchInput';
import styles from './DataTableSearch.module.less';
import { injectIntl, IntlShape } from 'react-intl';

interface IProps {
  onSearch: (value: string) => void;
  intl: IntlShape;
}

const DataTableSearch = ({ onSearch, intl }: IProps) => (
  <FormSearchInput
    className={styles.HeadingSearch}
    autoFocus
    placeholder={intl.formatMessage({ id: 'common.components.dataTable.searchPlaceholder' })}
    onSearch={onSearch}
  />
);

export default injectIntl(DataTableSearch);
