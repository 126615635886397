export const VEHICLE_MODEL_LIST_REQUEST = 'VEHICLE_MODEL_LIST_REQUEST';
export const VEHICLE_MODEL_LIST_SUCCESS = 'VEHICLE_MODEL_LIST_SUCCESS';
export const VEHICLE_MODEL_LIST_FAILURE = 'VEHICLE_MODEL_LIST_FAILURE';
export const VEHICLE_MODEL_CREATE_REQUEST = 'VEHICLE_MODEL_CREATE_REQUEST';
export const VEHICLE_MODEL_CREATE_SUCCESS = 'VEHICLE_MODEL_CREATE_SUCCESS';
export const VEHICLE_MODEL_CREATE_FAILURE = 'VEHICLE_MODEL_CREATE_FAILURE';
export const VEHICLE_MODEL_UPDATE_REQUEST = 'VEHICLE_MODEL_UPDATE_REQUEST';
export const VEHICLE_MODEL_UPDATE_SUCCESS = 'VEHICLE_MODEL_UPDATE_SUCCESS';
export const VEHICLE_MODEL_UPDATE_FAILURE = 'VEHICLE_MODEL_UPDATE_FAILURE';
export const VEHICLE_MODEL_DELETE_REQUEST = 'VEHICLE_MODEL_DELETE_REQUEST';
export const VEHICLE_MODEL_DELETE_SUCCESS = 'VEHICLE_MODEL_DELETE_SUCCESS';
export const VEHICLE_MODEL_DELETE_FAILURE = 'VEHICLE_MODEL_DELETE_FAILURE';
