import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import FiscalPrinterModel, { IFiscalPrinterModelDTO } from '@common/models/FiscalPrinterModel';
import {
  SYSTEM_FISCAL_PRINTER_LIST_REQUEST,
  SYSTEM_FISCAL_PRINTER_LIST_SUCCESS,
  SYSTEM_FISCAL_PRINTER_LIST_FAILURE,
  SYSTEM_FISCAL_PRINTER_CREATE_REQUEST,
  SYSTEM_FISCAL_PRINTER_CREATE_SUCCESS,
  SYSTEM_FISCAL_PRINTER_CREATE_FAILURE,
  SYSTEM_FISCAL_PRINTER_DELETE_REQUEST,
  SYSTEM_FISCAL_PRINTER_DELETE_SUCCESS,
  SYSTEM_FISCAL_PRINTER_DELETE_FAILURE,
  SYSTEM_FISCAL_PRINTER_UPDATE_REQUEST,
  SYSTEM_FISCAL_PRINTER_UPDATE_SUCCESS,
  SYSTEM_FISCAL_PRINTER_UPDATE_FAILURE
} from '@routes/Settings/System/redux/modules/SystemFiscalPrinter/SystemFiscalPrinterTypes';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const systemFiscalPrinterListAction = (params = {}) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: SYSTEM_FISCAL_PRINTER_LIST_REQUEST
    });

    const response = await FiscalPrinterModel.listFetch({
      ...params,
      cancelToken: source.token
    });

    dispatch({
      type: SYSTEM_FISCAL_PRINTER_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: SYSTEM_FISCAL_PRINTER_LIST_FAILURE
      });
    }
  }
};

export const systemFiscalPrinterCreateAction = (data: IFiscalPrinterModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SYSTEM_FISCAL_PRINTER_CREATE_REQUEST
    });

    const response = await FiscalPrinterModel.createFetch(data);

    dispatch({
      type: SYSTEM_FISCAL_PRINTER_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: SYSTEM_FISCAL_PRINTER_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const systemFiscalPrinterUpdateLoadDataAction = ({ id }: { id: number }) => () =>
  FiscalPrinterModel.viewFetch({ id });

export const systemFiscalPrinterUpdateAction = (data: IFiscalPrinterModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SYSTEM_FISCAL_PRINTER_UPDATE_REQUEST
    });

    const response = await FiscalPrinterModel.updateFetch(data);

    dispatch({
      type: SYSTEM_FISCAL_PRINTER_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: SYSTEM_FISCAL_PRINTER_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const systemFiscalPrinterDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SYSTEM_FISCAL_PRINTER_DELETE_REQUEST
    });

    const response = await FiscalPrinterModel.deleteFetch({ id });

    dispatch({
      type: SYSTEM_FISCAL_PRINTER_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: SYSTEM_FISCAL_PRINTER_DELETE_FAILURE
    });
  }
};
