import { IModule } from 'redux-dynamic-modules';
import AuthReducer, { IAuthState } from '@common/redux/modules/Auth/AuthReducer';

export interface IAuthModuleState {
  auth: IAuthState;
}

const AuthModule: IModule<IAuthModuleState> = {
  id: 'auth',
  reducerMap: {
    auth: AuthReducer
  } as any
};

export { AuthModule };
