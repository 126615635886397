import BaseModel, { IBaseModelDTO, IBaseModel } from '@common/models/BaseModel';
import ContractorModel, { IContractorModelDTO, IContractorModel } from '@common/models/ContractorModel';
import VehicleMakeModel, { IVehicleMakeModelDTO, IVehicleMakeModel } from '@common/models/VehicleMakeModel';
import VehicleModelModel, { IVehicleModelModelDTO, IVehicleModelModel } from '@common/models/VehicleModelModel';
import VehicleGenerationModel, {
  IVehicleGenerationModelDTO,
  IVehicleGenerationModel
} from '@common/models/VehicleGenerationModel';
import VehicleSerieModel, { IVehicleSerieModelDTO, IVehicleSerieModel } from '@common/models/VehicleSerieModel';
import VehicleEngineModel, { IVehicleEngineModelDTO, IVehicleEngineModel } from '@common/models/VehicleEngineModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const vehicleUrl = '/crm/vehicles';

export interface IVehicleFormValues {
  readonly id?: number;
  contractor_id?: number | null;
  contractor?: IContractorModel;
  reg_no: string;
  year?: string | null;
  vin?: string | null;
  engine_no?: string | null;
  comment?: string | null;
  make_id?: number;
  model_id?: number;
  generation_id?: number;
  serie_id?: number;
  engine_id?: number;
  make?: IVehicleMakeModel;
  model?: IVehicleModelModel;
  generation?: IVehicleGenerationModel;
  serie?: IVehicleSerieModel;
  engine?: IVehicleEngineModel;
}

export interface IVehicleModelDTO extends IBaseModelDTO {
  contractor_id?: number;
  contractor?: IContractorModelDTO;
  reg_no: string;
  make_id?: number;
  model_id?: number;
  generation_id?: number;
  serie_id?: number;
  engine_id?: number;
  year?: string;
  vin?: string;
  engine_no?: string;
  comment?: string;
  make?: IVehicleMakeModelDTO;
  model?: IVehicleModelModelDTO;
  generation?: IVehicleGenerationModelDTO;
  serie?: IVehicleSerieModelDTO;
  engine?: IVehicleEngineModelDTO;
}

export interface IVehicleModel extends IBaseModel {
  contractor_id?: number;
  contractor?: IContractorModel;
  reg_no: string;
  make_id?: number;
  model_id?: number;
  generation_id?: number;
  serie_id?: number;
  engine_id?: number;
  year?: string;
  vin?: string;
  engine_no?: string;
  comment?: string;
  make?: IVehicleMakeModel;
  model?: IVehicleModelModel;
  generation?: IVehicleGenerationModel;
  serie?: IVehicleSerieModel;
  engine?: IVehicleEngineModel;

  name: string;
}

export class VehicleModel extends BaseModel implements IVehicleModel {
  contractor_id?: number;
  contractor?: IContractorModel;
  reg_no: string;
  make_id?: number;
  model_id?: number;
  generation_id?: number;
  serie_id?: number;
  engine_id?: number;
  year?: string;
  vin?: string;
  engine_no?: string;
  comment?: string;
  make?: IVehicleMakeModel;
  model?: IVehicleModelModel;
  generation?: IVehicleGenerationModel;
  serie?: IVehicleSerieModel;
  engine?: IVehicleEngineModel;
  get name(): string {
    let result = '';

    if (this.make) {
      result += `${this.make.name} `;
    }

    if (this.model) {
      result += `${this.model.name}`;
    }

    return result;
  }

  constructor({
    id,
    contractor_id,
    make_id,
    model_id,
    generation_id,
    serie_id,
    engine_id,
    reg_no,
    year,
    vin,
    engine_no,
    comment,
    contractor,
    make,
    model,
    generation,
    serie,
    engine,
    date_created
  }: IVehicleModelDTO) {
    super({ id, date_created });

    this.reg_no = reg_no;
    this.year = year;
    this.vin = vin;
    this.engine_no = engine_no;
    this.comment = comment;
    this.contractor = contractor ? ContractorModel.build(contractor) : undefined;
    this.contractor_id = contractor_id;

    if (make) {
      this.make = VehicleMakeModel.build(make);
    }
    this.make_id = make_id;

    if (model) {
      this.model = VehicleModelModel.build(model);
    }
    this.model_id = model_id;

    if (generation) {
      this.generation = VehicleGenerationModel.build(generation);
    }
    this.generation_id = generation_id;

    if (serie) {
      this.serie = VehicleSerieModel.build(serie);
    }
    this.serie_id = serie_id;

    if (engine) {
      this.engine = VehicleEngineModel.build(engine);
    }
    this.engine_id = engine_id;
  }

  static serialize = (item: IVehicleFormValues) => {
    return {
      id: item.id,
      contractor_id: item.contractor_id,
      reg_no: item.reg_no,
      year: item.year || null,
      vin: item.vin || null,
      engine_no: item.engine_no || null,
      comment: item.comment || null,
      make_id: item.make_id,
      model_id: item.model_id,
      generation_id: item.generation_id || null,
      serie_id: item.serie_id || null,
      engine_id: item.engine_id || null
    };
  };

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: vehicleUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleModel.buildFetchUrl(vehicleUrl, id), method: 'GET' });

  static createFetch = (data: IVehicleModelDTO) => Resource({ url: vehicleUrl, method: 'POST', data });

  static updateFetch = (data: IVehicleModelDTO) =>
    Resource({ url: VehicleModel.buildFetchUrl(vehicleUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: VehicleModel.buildFetchUrl(vehicleUrl, id), method: 'DELETE' });
}

export default VehicleModel;
