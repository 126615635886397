import * as React from 'react';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { systemFiscalPrinterCreateAction } from '@routes/Settings/System/redux/modules/SystemFiscalPrinter/SystemFiscalPrinterActions';
import IntlMessages from '@common/translations';
import { baseFormComponent, IBaseFormComponentProps, IInjectedProps } from '@common/components/BaseFormComponent';
import SystemFiscalPrinterForm from '@routes/Settings/System/SystemFiscalPrinter/components/SystemFiscalPrinterForm';
import FiscalPrinterModel, { IFiscalPrinterFormValues } from '@common/models/FiscalPrinterModel';
import { FormCascaderOption } from '@common/components/Form/FormCascader/FormCascader';

const SystemFiscalPrinterCreatePageTitle = 'routes.settings.system.fiscalPrinter.create.pageTitle';

interface IProps extends IBaseFormComponentProps<IFiscalPrinterFormValues> {
  saveAction: Function;
  intl: IntlShape;
}

interface IState {
  fiscalPrinterProducers: FormCascaderOption[];
  initialValues: IFiscalPrinterFormValues;
}

class SystemFiscalPrinterCreate extends React.Component<IProps & IInjectedProps, IState> {
  state: IState = {
    fiscalPrinterProducers: [],
    initialValues: {
      name: '',
      ip_address: '',
      port: null,
      producerModel: []
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (): Promise<void> => {
    const response = await FiscalPrinterModel.producerListFetch();

    this.setState({
      fiscalPrinterProducers: response.data
    });
  };

  onSubmit = async (values: IFiscalPrinterFormValues): Promise<void> => {
    const { onSaveAction, saveAction } = this.props;

    return await onSaveAction(FiscalPrinterModel.serialize(values), { saveAction });
  };

  render() {
    const { closeModal } = this.props;
    const { fiscalPrinterProducers, initialValues } = this.state;

    return (
      <SystemFiscalPrinterForm
        title={<IntlMessages id={SystemFiscalPrinterCreatePageTitle} />}
        closeModal={closeModal}
        fiscalPrinterProducers={fiscalPrinterProducers}
        formProps={{ onSubmit: this.onSubmit, initialValues }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveAction: systemFiscalPrinterCreateAction
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  connect(null, mapDispatchToProps),
  injectIntl,
  baseFormComponent
)(SystemFiscalPrinterCreate);
