import * as React from 'react';
import { InputNumber } from 'antd';
import { Field, FieldRenderProps } from 'react-final-form';
import { InputNumberProps } from 'antd/lib/input-number';
import { FormItemProps } from 'antd/lib/form';
import cn from 'classnames';
import styles from './FormInputNumber.module.less';
import FormItem from '@common/components/Form/FormItem/FormItem';
import * as _ from 'lodash';
import { roundNumber } from '@common/helpers/Number';

interface IProps extends InputNumberProps {
  name: string;
  formItemProps: FormItemProps;
  mode?: 'percent' | 'currency' | 'unit';
  fullWidth?: boolean;
  startEmpty?: boolean;
}

const FormInputNumber = ({
  name,
  formItemProps,
  mode,
  onBlur: $onBlur,
  onChange: $onChange,
  fullWidth = true,
  startEmpty = false,
  ...props
}: IProps) => {
  let formatter: (value: number | string | undefined) => string;
  let parser: (displayValue: string | undefined) => number | string;
  let precision: number;
  let min: number;

  switch (mode) {
    case 'currency': {
      precision = 2;
      formatter = (value: string | number | undefined) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      parser = (value: string | undefined) => `${value}`.replace(/\s/g, '');
      min = 0;

      break;
    }

    case 'percent': {
      precision = 2;
      formatter = (value: string | number | undefined) => `${value}%`;
      parser = (value: string | undefined) => `${value}`.replace('%', '');

      break;
    }

    case 'unit': {
      precision = 2;
      formatter = (value: string | number | undefined) => `${value}`;
      parser = (value: string | undefined) => `${value}`;
      min = 0;

      break;
    }

    default: {
      precision = 0;
      formatter = (value: string | number | undefined) => `${value}`;
      parser = (value: string | undefined) => `${value}`;
      min = 0;
    }
  }

  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => (
        <FormItem name={name} {...formItemProps} meta={meta}>
          <InputNumber
            size="large"
            precision={precision}
            defaultValue={0}
            decimalSeparator=","
            formatter={formatter}
            parser={parser}
            className={cn({ [styles.fullWidth]: fullWidth }, styles.Input)}
            min={min}
            {...props}
            {...input}
            onChange={event => {
              input.onChange(event);
              $onChange && $onChange(event);
            }}
            onBlur={event => {
              input.onBlur(event);
              $onBlur && $onBlur(event);
            }}
            value={_.isFinite(input.value) ? roundNumber(input.value, 2) : startEmpty ? undefined : 0}
            name={name}
          />
        </FormItem>
      )}
    </Field>
  );
};

export default FormInputNumber;
