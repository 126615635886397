import * as React from 'react';
import { Typography } from 'antd';
import styles from './AssortmentOptionTemplate.module.less';
import InjectMassage from '@common/translations';
import { IInventoryItemModelDTO } from '@common/models/InventoryItemModel';
import { IFormAutocompleteItem } from '@common/components/Form/FormAutocomplete';

const { Text } = Typography;

const FormAutocompleteAssortmentOptionTemplate = <T extends IFormAutocompleteItem<IInventoryItemModelDTO>>(
  item: T
): React.ReactElement => {
  return (
    <div>
      <div className={styles.Name}>
        <Text ellipsis>{item.name}</Text>
      </div>
      <div className={styles.Info}>
        {item.index ? (
          <div className={styles.Info}>
            <Text type="secondary" ellipsis>
              <InjectMassage id="index" />: {item.index}
            </Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormAutocompleteAssortmentOptionTemplate;
