import * as React from 'react';
import { Spin, Icon } from 'antd';
import { SpinProps } from 'antd/lib/spin';

interface ISpinnerProps extends SpinProps {
  loading?: boolean;
  loadingClassName?: string;
}

const Spinner = ({ loading, loadingClassName, ...props }: ISpinnerProps): JSX.Element => {
  const antIcon = <Icon type="loading" className={loadingClassName} spin />;

  return <Spin size="large" indicator={loading ? antIcon : undefined} {...props} />;
};

export default Spinner;
