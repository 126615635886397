import * as Yup from 'yup';

export const VehicleGenerationFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    model_id: Yup.number()
      .nullable()
      .required(),
    year_begin: Yup.number().nullable(),
    year_end: Yup.number().nullable()
  });
