import auth from '@common/translations/locales/routes/auth/pl-PL.json';
import warehouse from '@common/translations/locales/routes/warehouse/pl-PL.json';
import settings from '@common/translations/locales/routes/settings/pl-PL.json';
import document from '@common/translations/locales/routes/document/pl-PL.json';
import management from '@common/translations/locales/routes/management/pl-PL.json';
import workshop from '@common/translations/locales/routes/workshop/pl-PL.json';
import report from '@common/translations/locales/routes/report/pl-PL.json';
import crm from '@common/translations/locales/routes/crm/pl-PL.json';
import other from '@common/translations/locales/routes/pl-PL.json';

export default {
  ...auth,
  ...warehouse,
  ...settings,
  ...document,
  ...management,
  ...workshop,
  ...report,
  ...crm,
  ...other
};
