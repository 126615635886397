import * as Yup from 'yup';
import { IntlShape } from 'react-intl';

export const SystemFiscalPrinterFormSchema = (intl: IntlShape): Yup.ObjectSchema =>
  Yup.object().shape({
    name: Yup.string().required(),
    ip_address: Yup.string()
      .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
        message: intl.formatMessage({ id: 'common.helpers.formValidator.ip_address' }),
        excludeEmptyString: true
      })
      .test('ipAddress', intl.formatMessage({ id: 'common.helpers.formValidator.ip_address' }), value => {
        if (value === undefined || value.trim() === '') return true;
        return value.split('.').find((i: string) => parseInt(i) > 255) === undefined;
      })
      .required(),
    port: Yup.number()
      .nullable()
      .required(),
    producerModel: Yup.array()
      .of(Yup.string())
      .required(),
    description: Yup.string().nullable()
  });
