import produce from 'immer';
import { IReducerAction } from '@common/interfaces';
import { COMPANY_SUCCESS } from '@common/redux/modules/Company/CompanyTypes';
import CompanyModel, { ICompanyModelDTO, ICompanyModel } from '@common/models/CompanyModel';

export interface ICompanyState {
  data: ICompanyModel;
}

const initialState = {
  data: {}
};

export default function(state = initialState, action: IReducerAction<ICompanyModelDTO>): ICompanyState | {} {
  return produce(state, draft => {
    switch (action.type) {
      case COMPANY_SUCCESS: {
        draft.data = CompanyModel.build(action.payload);

        break;
      }
    }
  });
}
