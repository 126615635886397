export const Paths = {
  Home: '/',
  Dashboard: '/dashboard',

  Auth: '/auth',
  AuthLogin: '/login',
  AuthLock: '/lock',

  Workshop: '/workshop',
  WorkshopVehicle: '/workshop/vehicle',
  WorkshopOrder: '/workshop/order',
  WorkshopService: '/workshop/service',
  WorkshopCalendar: '/workshop/calendar',

  Warehouse: '/warehouse',
  WarehouseAssortment: '/warehouse/assortment',
  WarehousePz: '/warehouse/pz',
  WarehouseWz: '/warehouse/wz',
  WarehouseRw: '/warehouse/rw',
  WarehousePw: '/warehouse/pw',
  WarehouseMm: '/warehouse/mm',
  WarehouseStocktaking: '/warehouse/stocktaking',

  Document: '/document',
  DocumentInvoice: '/document/invoice',
  DocumentReceipt: '/document/receipt',

  Management: '/crm',
  ManagementInvoice: '/crm/invoice',
  ManagementContractor: '/crm/contractor',

  Administration: '/administration',
  AdministrationWorker: '/administration/worker',

  Report: '/report',
  ReportJpkMag: '/report/jpk-mag',
  ReportJpkVat: '/report/jpk-vat',
  ReportWorker: '/report/worker',
  ReportDelivery: '/report/delivery',
  ReportSaleSummary: '/report/sale-summary',
  ReportSaleDocuments: '/report/sale-documents',

  Settings: '/administration/settings',
  SettingsSystem: '/administration/settings/system',
  SettingsSystemFiscalPrinter: '/administration/settings/system/fiscal-printer',
  SettingsVehicle: '/administration/settings/vehicle',
  SettingsVehicleMake: '/administration/settings/vehicle/make',
  SettingsVehicleModel: '/administration/settings/vehicle/model',
  SettingsVehicleGeneration: '/administration/settings/vehicle/generation',
  SettingsVehicleSerie: '/administration/settings/vehicle/serie',
  SettingsVehicleEngine: '/administration/settings/vehicle/engine',
  SettingsWarehouse: '/administration/settings/warehouse',
  SettingsWarehouseList: '/administration/settings/warehouse/list',
  SettingsWarehouseCategory: '/administration/settings/warehouse/category',
  SettingsWarehouseProducer: '/administration/settings/warehouse/producer',
  SettingsWarehouseProvider: '/administration/settings/warehouse/provider',
  SettingsService: '/administration/settings/service',
  SettingsServiceCategory: '/administration/settings/service/category'
};
