import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import InventoryCategoryModel, { IInventoryCategoryModelDTO } from '@common/models/InventoryCategoryModel';
import {
  WAREHOUSE_CATEGORY_LIST_REQUEST,
  WAREHOUSE_CATEGORY_LIST_SUCCESS,
  WAREHOUSE_CATEGORY_LIST_FAILURE,
  WAREHOUSE_CATEGORY_CREATE_REQUEST,
  WAREHOUSE_CATEGORY_CREATE_SUCCESS,
  WAREHOUSE_CATEGORY_CREATE_FAILURE,
  WAREHOUSE_CATEGORY_DELETE_REQUEST,
  WAREHOUSE_CATEGORY_DELETE_SUCCESS,
  WAREHOUSE_CATEGORY_DELETE_FAILURE,
  WAREHOUSE_CATEGORY_UPDATE_REQUEST,
  WAREHOUSE_CATEGORY_UPDATE_SUCCESS,
  WAREHOUSE_CATEGORY_UPDATE_FAILURE
} from '@routes/Settings/Warehouse/redux/modules/WarehouseCategory/WarehouseCategoryTypes';

const { CancelToken } = axios;
let source: CancelTokenSource;

export const warehouseCategoryListAction = (params = {}) => async (dispatch: Dispatch) => {
  try {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    dispatch({
      type: WAREHOUSE_CATEGORY_LIST_REQUEST
    });

    const response = await InventoryCategoryModel.listFetch({
      ...params,
      cancelToken: source.token
    });

    dispatch({
      type: WAREHOUSE_CATEGORY_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: WAREHOUSE_CATEGORY_LIST_FAILURE
      });
    }
  }
};

export const warehouseCategoryCreateAction = (data: IInventoryCategoryModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: WAREHOUSE_CATEGORY_CREATE_REQUEST
    });

    const response = await InventoryCategoryModel.createFetch(data);

    dispatch({
      type: WAREHOUSE_CATEGORY_CREATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: WAREHOUSE_CATEGORY_CREATE_FAILURE
    });

    throw err.response.data;
  }
};

export const warehouseCategoryUpdateLoadDataAction = ({ id }: { id: number }) => () =>
  InventoryCategoryModel.viewFetch({ id });

export const warehouseCategoryUpdateAction = (data: IInventoryCategoryModelDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: WAREHOUSE_CATEGORY_UPDATE_REQUEST
    });

    const response = await InventoryCategoryModel.updateFetch(data);

    dispatch({
      type: WAREHOUSE_CATEGORY_UPDATE_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: WAREHOUSE_CATEGORY_UPDATE_FAILURE
    });

    throw err.response.data;
  }
};

export const warehouseCategoryDeleteAction = ({ id }: { id: number }) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: WAREHOUSE_CATEGORY_DELETE_REQUEST
    });

    const response = await InventoryCategoryModel.deleteFetch({ id });

    dispatch({
      type: WAREHOUSE_CATEGORY_DELETE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_CATEGORY_DELETE_FAILURE
    });
  }
};
