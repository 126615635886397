import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Paths } from '@common/constants';

interface IAuthRouteProps extends RouteProps {
  component: React.ComponentType;
  isAuthenticated: boolean;
}

const AuthRoute = ({ component: Component, isAuthenticated, ...rest }: IAuthRouteProps & RouteProps) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === false ? <Component {...props} /> : <Redirect to={{ pathname: Paths.Workshop }} />
    }
  />
);

export default AuthRoute;
