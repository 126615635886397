import base64lib from 'base-64';
const _prefix = 'warigo';

export function saveLocalStorage(name: string, storeState: {}, base64?: boolean): boolean {
  if (!localStorage) {
    return false;
  }

  try {
    let serializedState = JSON.stringify(storeState);

    if (base64) {
      serializedState = base64lib.encode(serializedState);
    }

    localStorage.setItem(`${_prefix}.${name}`, serializedState);
    return true;
  } catch (error) {
    throw new Error('store serialization failed');
  }
}

export function loadLocalStorage(name: string, base64?: boolean) {
  if (!localStorage) {
    return;
  }

  try {
    let serializedState = localStorage.getItem(`${_prefix}.${name}`);
    if (serializedState == null) {
      return;
    }

    if (base64) {
      serializedState = base64lib.decode(serializedState);
    }

    return JSON.parse(serializedState);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

export function deleteLocalStorage(name: string) {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(`${_prefix}.${name}`);

    return true;
  } catch (error) {
    throw new Error('store remove failed');
  }
}
