export const WORKER_LIST_REQUEST = 'WORKER_LIST_REQUEST';
export const WORKER_LIST_SUCCESS = 'WORKER_LIST_SUCCESS';
export const WORKER_LIST_FAILURE = 'WORKER_LIST_FAILURE';
export const WORKER_CREATE_REQUEST = 'WORKER_CREATE_REQUEST';
export const WORKER_CREATE_SUCCESS = 'WORKER_CREATE_SUCCESS';
export const WORKER_CREATE_FAILURE = 'WORKER_CREATE_FAILURE';
export const WORKER_UPDATE_REQUEST = 'WORKER_UPDATE_REQUEST';
export const WORKER_UPDATE_SUCCESS = 'WORKER_UPDATE_SUCCESS';
export const WORKER_UPDATE_FAILURE = 'WORKER_UPDATE_FAILURE';
export const WORKER_DELETE_REQUEST = 'WORKER_DELETE_REQUEST';
export const WORKER_DELETE_SUCCESS = 'WORKER_DELETE_SUCCESS';
export const WORKER_DELETE_FAILURE = 'WORKER_DELETE_FAILURE';
