import { IModule } from 'redux-dynamic-modules';
import InitReducer, { IInitState } from '@common/redux/modules/Init/InitReducer';

export interface IInitModuleState {
  init: IInitState;
}

const InitModule: IModule<IInitModuleState> = {
  id: 'init',
  reducerMap: {
    init: InitReducer
  } as any
};

export { InitModule };
