import * as React from 'react';
import cn from 'classnames';
import { Cascader } from 'antd';
import { injectIntl, IntlShape } from 'react-intl';
import { FormItemProps } from 'antd/lib/form';
import { CascaderProps } from 'antd/lib/cascader';
import { CascaderOptionType } from 'antd/es/cascader';
import FormItem from '@common/components/Form/FormItem/FormItem';
import styles from './FormCascader.module.less';
import { Field, FieldRenderProps } from 'react-final-form';

export interface FormCascaderOption extends CascaderOptionType {}

interface IProps extends CascaderProps {
  name: string;
  allowPrompt?: boolean;
  fullWidth?: boolean;
  onChangeValue?: (value: string | number) => void;
  formItemProps: FormItemProps;
  intl: IntlShape;
}

const FormCascader = ({
  name,
  formItemProps,
  onChange: $onChange,
  fullWidth = true,
  intl,
  ...props
}: IProps): JSX.Element => {
  return (
    <Field name={name} subscription={{ value: true, error: true, submitFailed: true, submitError: true }}>
      {({ input, meta }: FieldRenderProps<any>) => (
        <FormItem name={name} {...formItemProps} meta={meta}>
          <Cascader
            size="large"
            {...input}
            onChange={event => {
              input.onChange(event);
              $onChange && $onChange(event);
            }}
            className={cn(styles.Cascader, { [styles.FullWidth]: fullWidth })}
            placeholder={intl.formatMessage({ id: 'common.components.formCascader.placeholder' })}
            {...props}
          />
        </FormItem>
      )}
    </Field>
  );
};

export default injectIntl(FormCascader);
