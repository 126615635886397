import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Paths } from '@common/constants';
import LoadableComponent from '@common/components/LoadableComponent';

const DocumentInvoice = LoadableComponent(() => import('@routes/Document/Invoice'));
const DocumentReceipt = LoadableComponent(() => import('@routes/Document/Receipt'));

class Document extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={Paths.DocumentInvoice} component={DocumentInvoice} />
        <Route path={Paths.DocumentReceipt} component={DocumentReceipt} />
        <Redirect from={Paths.Document} to={Paths.DocumentInvoice} />
      </Switch>
    );
  }
}

export default compose(withRouter, connect())(Document) as React.ComponentType;
