import * as Yup from 'yup';

export const WarehouseListFormSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    name: Yup.string().required(),
    code: Yup.string(),
    description: Yup.string().nullable(),
    address: Yup.string().nullable(),
    is_default: Yup.boolean()
  });
