import * as _ from 'lodash';

const roundNumber = (value: number, precision = 2): number => {
  return _.round(value, precision);
};

const parseNumberToApi = (value?: number): string | undefined => {
  return _.isNumber(value) ? `${roundNumber(value, 2)}` : value;
};

type toDecimal = (price?: number, decimalPlaces?: number) => string;
const toFixed: toDecimal = (price?: number, decimalPlaces = 2): string => {
  if (!_.isNumber(price)) {
    return '';
  }

  const priceString: string = price.toString();
  const pointIndex: number = priceString.indexOf('.');

  // Return the integer part if decimalPlaces is 0
  if (decimalPlaces === 0) {
    return priceString.substr(0, pointIndex);
  }

  // Return value with 0s appended after decimal if the price is an integer
  if (pointIndex === -1) {
    const padZeroString: string = '0'.repeat(decimalPlaces);

    return `${priceString}.${padZeroString}`;
  }

  // If numbers after decimal are less than decimalPlaces, append with 0s
  const padZeroLen: number = priceString.length - pointIndex - 1;
  if (padZeroLen > 0 && padZeroLen < decimalPlaces) {
    const padZeroString: string = '0'.repeat(padZeroLen);

    return `${priceString}${padZeroString}`;
  }

  return priceString.substr(0, pointIndex + decimalPlaces + 1);
};

const convertToAmount = (value?: number) => {
  if (value === undefined) {
    value = 0;
  }

  value = roundNumber(value, 2);

  return toFixed(value)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');
};

const convertToNumberPrice = (value: string) => {
  if (_.isNumber(value)) {
    return value;
  }

  return parseFloat(value.replace(',', '.').replace(/\s+/g, ''));
};

const calculateGrossAmount = ({ value, tax }: { value?: number | null; tax?: number | null }) => {
  if (!_.isNumber(value) || !_.isNumber(tax)) {
    return 0;
  }

  return value * (1 + tax / 100);
};

const calculateNetAmount = ({ value, tax }: { value?: number | null; tax?: number | null }) => {
  if (!_.isNumber(value) || !_.isNumber(tax)) {
    return 0;
  }

  return value / (1 + tax / 100);
};

const calculateMarginAmount = ({ value, percent = 0 }: { value?: number; percent?: number }): number => {
  if (!_.isNumber(value) || !_.isNumber(percent)) {
    return 0;
  }

  const result = value * (1 + percent / 100);

  if (!isFinite(result)) {
    return 0;
  }

  return result;
};

const calculateOverheadPercent = (buy?: number, sell?: number): number => {
  if (!_.isNumber(buy) || !_.isNumber(sell)) {
    return 0;
  }

  const result = (100 * (sell - buy)) / buy;

  if (!isFinite(result)) {
    return 0;
  }

  return result;
};

const calculateMarginPercent = (buy?: number, sell?: number): number => {
  if (!_.isNumber(buy) || !_.isNumber(sell)) {
    return 0;
  }

  const result = (100 * (sell - buy)) / sell;

  if (!isFinite(result)) {
    return 0;
  }

  return result;
};

export {
  parseNumberToApi,
  roundNumber,
  toFixed,
  convertToAmount,
  convertToNumberPrice,
  calculateGrossAmount,
  calculateNetAmount,
  calculateMarginAmount,
  calculateOverheadPercent,
  calculateMarginPercent
};
