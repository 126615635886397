import * as React from 'react';
import { Icon } from 'antd';
import Button from '@common/components/Button';
import { ReactComponent as PlusSvg } from '@assets/fonts/plus.svg';
import InjectMassage from '@common/translations';
import styles from './FormAutocompleteButton.module.less';

const plusIcon: React.FC = PlusSvg;

export interface IFormOpenButton {
  onClick: () => void;
  label?: string;
  isLoading?: boolean;
}

const FormAutocompleteButton = ({ onClick, label }: IFormOpenButton) => (
  <Button
    type="link"
    onClick={onClick}
    onMouseDown={e => {
      e.preventDefault();
    }}
    size="small"
    className={styles.Button}
  >
    <Icon component={plusIcon} className={styles.Icon} />
    {label || <InjectMassage id="add" />}
  </Button>
);

export default FormAutocompleteButton;
