import * as React from 'react';
import * as _ from 'lodash';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { IntlShape } from 'react-intl';
import Icon from '@common/components/Icon';
import NavigationMenuItems, { INavigationMenuItem } from '@common/components/Navigation/NavigationMenu/NavigationMenuItems';
import styles from './NavigationMenu.module.less';

interface IProps {
  collapsed: boolean;
  pathname: string;
  intl: IntlShape;
}

const NavigationMenu = ({ pathname, collapsed, intl }: IProps) => {
  const [activeSection, setActiveSection] = React.useState<string>('sidebar.workshop');

  function searchSelectedKeys(pathname: string): string[] {
    const result: string[] = [];
    let activeSectionTmp = '';

    for (let i = 0, len = NavigationMenuItems.length; i < len; i += 1) {
      const item = NavigationMenuItems[i];

      if (Array.isArray(item.items)) {
        for (let j = 0, jLen = item.items.length; j < jLen; j += 1) {
          const subItem = item.items[j];

          if (subItem.path && _.includes(pathname, subItem.path)) {
            activeSectionTmp = item.label;
            result.push(subItem.path);
          }
        }
      }
    }

    if (activeSectionTmp && activeSection !== activeSectionTmp) {
      setActiveSection(activeSectionTmp);
    }

    return result;
  }

  function renderMenuItem(item: INavigationMenuItem): React.ReactNode {
    return (
      <Menu.Item className={styles.MenuItem} key={item.path}>
        <Link to={item.path || ''}>
          <span>
            <span className={styles.MenuItemLabel}>{intl.formatMessage({ id: item.label })}</span>
          </span>
        </Link>
      </Menu.Item>
    );
  }

  function renderMenuItems(items: INavigationMenuItem[] = NavigationMenuItems): React.ReactNode {
    return _.map(items, (item, index) => {
      if (Array.isArray(item.items)) {
        return (
          <Menu.SubMenu
            className={styles.SubMenu}
            key={item.label}
            title={
              !collapsed ? (
                <div>
                  <Icon className={styles.SubMenuIcon} type={item.icon} />
                  {intl.formatMessage({ id: item.label })}
                </div>
              ) : (
                <div className={styles.MenuItemGroupCollapsedTitle} />
              )
            }
          >
            {renderMenuItems(item.items)}
          </Menu.SubMenu>
        );
      } else if (item.divider) {
        return <Menu.Divider key={index} className={styles.Divider} />;
      } else {
        return renderMenuItem(item);
      }
    });
  }

  return (
    <Menu
      className={styles.Menu}
      theme="dark"
      mode="inline"
      defaultOpenKeys={[activeSection]}
      defaultSelectedKeys={['/']}
      selectedKeys={searchSelectedKeys(pathname)}
    >
      {renderMenuItems()}
    </Menu>
  );
};

export default NavigationMenu;
