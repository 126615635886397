import * as _ from 'lodash';
import { AxiosPromise, AxiosRequestConfig, CancelToken } from 'axios';
import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { IContactTypeDTO } from '@common/interfaces';

const contractorUrl = '/crm/contractors';
const gusSearchUrl = '/crm/gus-search';

export interface IContractorFormValues {
  is_company: boolean;
  company_name: string | null;
  is_vat_eu: boolean;
  nip_prefix: string | null;
  nip: string | null;
  first_name: string | null;
  last_name: string | null;
  street: string | null;
  building_no: string | null;
  local_no: string | null;
  postal_code: string | null;
  city: string | null;
  country: string | null;
  bank_account: string | null;
  note: string | null;
  is_supplier?: boolean;
  is_recipient?: boolean;
  contacts: IContactTypeDTO[];
}

export interface IContractorModelDTO extends IBaseModelDTO {
  is_company: boolean;
  company_name: string;
  is_vat_eu: boolean;
  nip_prefix: string;
  nip: string;
  first_name: string;
  last_name: string;
  street: string;
  building_no: string;
  local_no: string;
  postal_code: string;
  city: string;
  country: string;
  bank_account: string;
  name: string;
  note: string | null;
  is_supplier?: boolean;
  is_recipient?: boolean;
  contacts: IContactTypeDTO[];
}

export interface IContractorModel extends IBaseModel {
  is_company: boolean;
  company_name: string;
  is_vat_eu: boolean;
  nip_prefix: string;
  nip: string;
  first_name: string;
  last_name: string;
  street: string;
  building_no: string;
  local_no: string;
  postal_code: string;
  city: string;
  country: string;
  bank_account: string;
  note: string | null;
  readonly name: string;
  is_supplier?: boolean;
  is_recipient?: boolean;
  contacts: IContactTypeDTO[];

  getNip: (withPrefix: boolean) => string;
  getPhone: () => string;
}

class ContractorModel extends BaseModel implements IContractorModel {
  is_company: boolean;
  company_name: string;
  is_vat_eu: boolean;
  nip_prefix: string;
  nip: string;
  first_name: string;
  last_name: string;
  street: string;
  building_no: string;
  local_no: string;
  postal_code: string;
  city: string;
  country: string;
  bank_account: string;
  note: string | null;
  readonly name: string;
  is_supplier?: boolean;
  is_recipient?: boolean;
  contacts: IContactTypeDTO[];

  constructor({
    id,
    is_company,
    company_name,
    is_vat_eu,
    nip_prefix,
    nip,
    first_name,
    last_name,
    street,
    building_no,
    local_no,
    postal_code,
    city,
    country,
    name,
    date_created,
    contacts,
    note,
    bank_account,
    is_recipient,
    is_supplier
  }: IContractorModelDTO) {
    super({ id, date_created });

    this.is_company = is_company;
    this.company_name = company_name;
    this.is_vat_eu = is_vat_eu;
    this.nip_prefix = nip_prefix;
    this.nip = nip;
    this.first_name = first_name;
    this.last_name = last_name;
    this.street = street;
    this.building_no = building_no;
    this.local_no = local_no;
    this.postal_code = postal_code;
    this.city = city;
    this.country = country;
    this.name = name;
    this.bank_account = bank_account;
    this.note = note;
    this.is_recipient = is_recipient;
    this.is_supplier = is_supplier;
    this.contacts = contacts || [];
  }

  getNip = (withPrefix: boolean | undefined = false): string => {
    if (this.nip) {
      if (withPrefix && this.nip_prefix) {
        return `${this.nip_prefix}${this.nip}`;
      }

      return this.nip;
    }

    return '';
  };

  getPhone = (): string => {
    const phone = _.find(this.contacts, { kind: 'phone' });

    if (phone) {
      return phone.value;
    }

    return '';
  };

  static serialize = (item: IContractorFormValues): any => {
    return {
      company_name: '',
      nip: '',
      street: '',
      building_no: '',
      local_no: '',
      postal_code: '',
      city: '',
      country: '',
      bank_account: '',
      note: '',
      ...item
    };
  };

  static listFetch = (params: AxiosRequestConfig = {}) => {
    if (params.params?.kind) {
      if (Array.isArray(params.params.kind)) {
        params.params.is_supplier = true;
        params.params.is_recipient = true;
      } else if (params.params.kind === '10') {
        params.params.is_supplier = true;
      } else {
        params.params.is_recipient = true;
      }

      delete params.params.kind;
    }

    return Resource({ url: contractorUrl, method: 'GET', ...params });
  };

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: ContractorModel.buildFetchUrl(contractorUrl, id), method: 'GET' });

  static createFetch = (data: IContractorModelDTO) => Resource({ url: contractorUrl, method: 'POST', data });

  static updateFetch = (data: IContractorModelDTO) =>
    Resource({ url: ContractorModel.buildFetchUrl(contractorUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: ContractorModel.buildFetchUrl(contractorUrl, id), method: 'DELETE' });

  static gusSearchFetch = ({ nip, cancelToken }: { nip: string; cancelToken: CancelToken }): AxiosPromise<any> =>
    Resource({ url: ContractorModel.buildFetchUrl(gusSearchUrl, nip), method: 'GET', cancelToken });
}

export default ContractorModel;
