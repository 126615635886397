import BaseModel, { IBaseModel, IBaseModelDTO } from '@common/models/BaseModel';
import { Resource } from '@common/helpers';
import { AxiosRequestConfig } from 'axios';

const workerUserUrl = '/workers/users';

export interface IWorkerUserFormValues {
  readonly id?: number;
  username: string;
  first_name: string;
  last_name: string;
  password?: string;
  email?: string;
  color: string;
  is_active: boolean;
}

export interface IWorkerUserModelDTO extends IBaseModelDTO {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  password?: string;
  email?: string;
  color: string;
  is_active: boolean;
}

export interface IWorkerUserModel extends IBaseModel {
  username: string;
  first_name: string;
  last_name: string;
  password?: string;
  email?: string;
  color: string;
  is_active: boolean;

  name: string;
}

export class WorkerUserModel extends BaseModel implements IWorkerUserModel {
  username: string;
  first_name: string;
  last_name: string;
  password?: string;
  email?: string;
  color: string;
  is_active: boolean;

  constructor({ id, username, first_name, last_name, email, color, is_active, date_created }: IWorkerUserModelDTO) {
    super({ id, date_created });

    this.username = username;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.color = color;
    this.is_active = is_active;
  }

  get name(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  static serialize(item: IWorkerUserFormValues): any {
    if (!item.password || item.password.length === 0) {
      delete item.password;
    }

    return item;
  }

  static listFetch = (params: AxiosRequestConfig = {}) => Resource({ url: workerUserUrl, method: 'GET', ...params });

  static viewFetch = ({ id }: { id: number }) =>
    Resource({ url: WorkerUserModel.buildFetchUrl(workerUserUrl, id), method: 'GET' });

  static createFetch = (data: IWorkerUserModelDTO) => Resource({ url: workerUserUrl, method: 'POST', data });

  static updateFetch = (data: IWorkerUserModelDTO) =>
    Resource({ url: WorkerUserModel.buildFetchUrl(workerUserUrl, data.id), method: 'PUT', data });

  static deleteFetch = ({ id }: { id: number }) =>
    Resource({ url: WorkerUserModel.buildFetchUrl(workerUserUrl, id), method: 'DELETE' });
}

export default WorkerUserModel;
