import * as React from 'react';
import { Button as AButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styles from './Button.module.less';

export interface IButton {
  children: React.ReactNode;
}

const Button = ({ children, ...props }: IButton & ButtonProps) => (
  <AButton size="large" type="primary" className={styles.Button} {...props}>
    {children}
  </AButton>
);

export default Button;
